import { useQuery } from '@tanstack/react-query'
import { sharedApi } from './shared-api'

export const useWorkPackages = () => {
	return useQuery({
		refetchOnWindowFocus: false,
		queryKey: ['shared', 'workPackages'],
		queryFn: async () => {
			return await sharedApi.getWorkPackages()
		},
		select: data => data.map(d => ({ ...d, name: `${d.code} ${d.name}` }))
	})
}

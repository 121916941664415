import { Col, Form, Row } from 'antd'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
	name: string
	label: string
	isLoading: boolean
	dataSourceValue?: string | null
	isLink?: boolean
	extra?: ReactNode
}

export const AbsFormView: FC<IProps> = ({
	name,
	label,
	isLoading,
	dataSourceValue,
	isLink = false,
	extra
}) => {
	return (
		<Form.Item label={label} name={name}>
			<Row style={{ width: '100%' }} align="middle" justify="space-between" wrap={false}>
				<Col flex={1}>
					<div className="app-form-text">
						{dataSourceValue ? (
							isLink ? (
								<Link target="_blank" to={dataSourceValue}>
									{dataSourceValue}
								</Link>
							) : (
								dataSourceValue
							)
						) : (
							'-'
						)}
					</div>
				</Col>
				{extra && <Col>{extra}</Col>}
			</Row>
		</Form.Item>
	)
}

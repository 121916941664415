import { useQuery } from '@tanstack/react-query'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { useChecklistTemplateCardState } from '../model/checklist-template-card-state'
import { checklistTemplateCardApi } from './checklist-template-card-api'

export const useChecklistTemplateCard = () => {
	const { currentTemplateId, currentTemplateType } = useChecklistTemplateCardState()

	return useQuery({
		queryKey: ['checklistTemplate', currentTemplateId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			if (currentTemplateId && currentTemplateType !== null) {
				if (currentTemplateType === TEMPLATE_TYPE.ndc) {
					return await checklistTemplateCardApi.getWithStages(currentTemplateId)
				} else if (currentTemplateType === TEMPLATE_TYPE.ukkc) {
					return await checklistTemplateCardApi.get(currentTemplateId)
				}
			}
			return null
		}
	})
}

import { CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Tooltip } from 'antd'
import { FC, useState } from 'react'
import { ATTACHMENT_STATUS } from 'shared/constants/attachment-types'
import { CHECKLIST_STATUS } from 'shared/constants/checklist-types'
import { useCreateAct } from '../api/checklist-card-queries'
import { TChecklistAttachment } from '../model/checklist-card-schema'
import { ChecklistSignModal } from './checklist-sign-modal'

interface ISignBlockProps {
	act: TChecklistAttachment | null
	checklistId: string
	checklistStatus: CHECKLIST_STATUS | null
	isLoading?: boolean
}

export const CheckListSignBlock: FC<ISignBlockProps> = ({
	act,
	checklistId,
	checklistStatus,
	isLoading
}) => {
	const { mutate: createAct, isLoading: isCreateActLoading } = useCreateAct()
	const [showSignModal, setShowSignModal] = useState(false)
	const canFormedAct =
		checklistStatus === CHECKLIST_STATUS.Completed || checklistStatus === CHECKLIST_STATUS.Warranty

	const signProps: ButtonProps = {
		loading: isCreateActLoading,
		type: 'default',
		style: { cursor: 'pointer', width: 120 },
		icon: <EditOutlined />,
		disabled: false,
		children: 'Подписать',
		onClick: () => setShowSignModal(true),
		ghost: false
	}

	switch (true) {
		case act === null:
			signProps.ghost = false
			signProps.style = {
				cursor: !canFormedAct ? 'default' : 'pointer',
				width: 170
			}
			signProps.icon = null
			signProps.children = 'Сформировать акт'
			signProps.disabled = !canFormedAct
			signProps.onClick = () => createAct(checklistId)
			break

		case act?.statusId === ATTACHMENT_STATUS.signatureVerification:
			signProps.ghost = true
			signProps.style = { cursor: 'default', width: 170 }
			signProps.icon = <ClockCircleOutlined style={{ color: 'brown' }} />
			signProps.children = 'Проверка подписи'
			signProps.disabled = true
			break

		case act?.statusId === ATTACHMENT_STATUS.signatureIsValid:
			signProps.style = {
				cursor: 'default',
				width: 120
			}
			signProps.icon = <CheckOutlined style={{ color: 'green' }} />
			signProps.children = 'Подписано'
			signProps.onClick = () => {}
			break
	}
	return (
		<>
			<ChecklistSignModal act={act} show={showSignModal} onClose={() => setShowSignModal(false)} />
			<Tooltip
				title={
					act === null && !canFormedAct
						? 'Для формирования акта передачи, чек-лист должен быть в статусе "Завершён" или "Принят под гарантийные обязательства"'
						: null
				}
			>
				<Button loading={isLoading} {...signProps} />
			</Tooltip>
		</>
	)
}

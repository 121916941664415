import { Col, Row } from 'antd'
import { memo } from 'react'
import { IAdjustmentPositionMaterial } from '../model/adjustment-schema'

interface IProps {
	row: IAdjustmentPositionMaterial
}

export const AdjustmentPositionMaterialTitle = memo(function (params: IProps) {
	const { row } = params
	const title = `${row.materialName}
						${row.materialComment ? `, ${row.materialComment}` : ''}`
	return (
		<Row
			wrap={false}
			align="middle"
			justify="space-between"
			style={{
				width: '100%'
			}}
		>
			<Col>
				<div
					style={{
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis'
					}}
				>
					{title}
					{row.materialIsDeleted  && (
						<span style={{ marginLeft: '8px' }}>АРХИВ</span>
					)}
				</div>
			</Col>
		</Row>
	)
})

import { CheckSquareOutlined, SolutionOutlined } from '@ant-design/icons'
import { Button, Col, Row, Select, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { FormInstance } from 'antd/lib'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { IBase, IBasePotentialRedactors } from 'interfaces/IBase'
import { IContainer } from 'interfaces/IContainer'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWbsRegistryState, useWbsStatusModalState } from '..'
import { WbsRegistryActions } from './WbsRegistryActions'

interface IProps {
	potentialRedactors: IBasePotentialRedactors[] | null | undefined
	adjustmentPotentialRedactors: IBasePotentialRedactors[] | null | undefined
	constructionObjects: IBase[] | null | undefined
	isLoading: boolean
	wbsRegistryForm: FormInstance
	onSave: (record: IContainer) => void
	data?: IContainer[] | null
}

const tooltipTitle = (order: 'ascend' | 'descend' | undefined | null) => {
	switch (order) {
		case 'ascend':
			return 'Нажмите для сортировки Я-А'
		case 'descend':
			return 'Нажмите, чтобы отменить сортировку'
		default:
			return 'Нажмите для сортировки А-Я'
	}
}

const getDocType = (rec: IContainer) => {
	if (rec.adjustmentId) return 'Было/Стало'
	if (rec.mainParent) return 'Корректировка'
	return 'ВОР'
}

export const WbsRegistryColumns = ({ props }: { props: IProps }) => {
	const {
		potentialRedactors,
		constructionObjects,
		isLoading,
		wbsRegistryForm,
		onSave,
		data,
		adjustmentPotentialRedactors
	} = props
	const navigate = useNavigate()
	const { user: currentUser, permissions } = useAppSelector(state => state.environment)
	const { setContainer } = useWbsStatusModalState()
	const { editableRow } = useWbsRegistryState()
	const isEditing = useCallback((id: string) => id === editableRow?.id, [editableRow])

	const columns: ColumnsType<IContainer> = [
		{
			key: 'status',
			fixed: 'left',
			dataIndex: 'status',
			title: 'Статус',
			width: 200,
			ellipsis: true,
			onCell: () => ({
				title: 'Статус',
				dataIndex: 'status'
			}),
			render: (_, record) => (
				<Row
					style={{ width: '100%' }}
					gutter={8}
					wrap={false}
					align="middle"
					justify="space-between"
				>
					<Col>
						<Row gutter={8} wrap={false} align="middle">
							<Col>
								<div
									style={{
										width: 6,
										height: '100%',
										maxHeight: 24,
										minHeight: 24,
										borderRadius: 2,
										background: record.status?.color! ? `#${record.status?.color}` : '#ddd'
									}}
								></div>
							</Col>
							<Col>{record.status?.description ?? 'Новый'}</Col>
						</Row>
					</Col>
					<Col>
						<Tooltip title="Статус согласования">
							<Button
								type="text"
								icon={<SolutionOutlined />}
								onClick={() => setContainer(record)}
							/>
						</Tooltip>
					</Col>
				</Row>
			)
		},
		{
			fixed: 'left',
			title: 'Тип документа',
			width: 100,
			render: (_, record) => getDocType(record)
		},
		{
			fixed: 'left',
			dataIndex: 'name',
			key: 'name',
			title: sortOrder => (
				<span className="column-title-tooltip">
					<Tooltip
						style={{ width: '100%' }}
						title={tooltipTitle(
							sortOrder?.sortColumns?.find(x => x.column.dataIndex === 'name')?.order!
						)}
					>
						Наименование
					</Tooltip>
				</span>
			),
			showSorterTooltip: false,
			width: 256,
			sorter: true,
			onCell: (record: IContainer) => ({
				record,
				dataIndex: 'name',
				title: 'Наименование',
				editing: isEditing(record.id),
				user: currentUser,
				permissions: permissions,
				currentUser: currentUser,
				wbsRegistryForm: wbsRegistryForm
			})
		},
		{
			fixed: 'left',
			dataIndex: ['parent', 'name'],
			key: 'parent',
			title: 'Основание',
			showSorterTooltip: false,
			width: 256,
			// sorter: true,

			render: (value, record) => (
				<Button
					className="app-btn-break"
					type="link"
					style={{ margin: 0, padding: 0 }}
					onClick={() => record.parent && navigate(record.parent?.id)}
				>
					{value}
				</Button>
			)
		},
		{
			dataIndex: 'typeSection',
			key: 'typeSection',
			title: 'Вид раздела',
			width: 128,
			sorter: true,
			render: (_, record) => record.typeSections?.name
		},
		{
			dataIndex: 'createdDate',
			key: 'createdDate',
			title: 'Создан',
			width: 128,
			sorter: true,
			render: (_, record) => dayjs(record.createdDate).format('HH:mm DD.MM.YYYY')
		},
		{
			dataIndex: ['author', 'name'],
			key: 'author',
			title: sortOrder => (
				<span className="column-title-tooltip">
					<Tooltip
						style={{ width: '100%' }}
						title={tooltipTitle(
							sortOrder?.sortColumns?.find(x => x.column.dataIndex === 'author')?.order!
						)}
					>
						Автор
					</Tooltip>
				</span>
			),
			width: 128,
			sorter: true,
			showSorterTooltip: false
		},
		{
			dataIndex: 'redactors',
			key: 'redactors',
			title: 'Редакторы',
			onCell: (record: IContainer) => ({
				record,
				dataIndex: 'redactors',
				title: 'Редакторы',
				editing: isEditing(record.id),
				potentialRedactors: record.adjustmentId ? adjustmentPotentialRedactors : potentialRedactors,
				user: currentUser,
				permissions: permissions,
				currentUser: currentUser
			}),
			showSorterTooltip: false,
			width: 200,
			render: (_, record) =>
				record.redactors.length > 1 ? (
					<Select
						popupClassName="app-selector-no-flow"
						showSearch={false}
						value={record.redactors
							.sort(
								(a, b) =>
									+b.isDeleted - +a.isDeleted ||
									+(b.id === currentUser?.id) - +(a.id === currentUser?.id) ||
									a?.name?.localeCompare(b?.name)
							)
							.map(x => x?.id)}
						tagRender={props => (
							<Tag
								color={
									record.redactors?.find(p => p.isDeleted) ||
									record.redactors
										.flatMap(elem => elem.id)
										.filter(
											item =>
												!(record.adjustmentId ? adjustmentPotentialRedactors : potentialRedactors)
													?.flatMap(({ id }) => id)
													.includes(item)
										).length > 0
										? 'red'
										: 'blue'
								}
								style={{ fontSize: 14 }}
								bordered={false}
							>
								{props.label}
							</Tag>
						)}
						maxTagCount={1}
						mode="multiple"
						variant="borderless"
						style={{ width: '100%' }}
						options={record.redactors.map(x => ({
							key: x.id,
							value: x.id,
							label:
								record.redactors?.find(p => p.name === x.name && p.isDeleted) ||
								record.redactors
									.flatMap(elem => elem.id)
									.filter(
										item =>
											!(record.adjustmentId ? adjustmentPotentialRedactors : potentialRedactors)
												?.flatMap(({ id }) => id)
												.includes(item)
									)
									.find(p => p === x.id)
									? 'Пользователь был удален'
									: x.name
						}))}
					/>
				) : (
					record.redactors.map(redactor => (
						<Tag
							key={redactor.id}
							style={{ fontSize: 14 }}
							bordered={false}
							color={
								(record.adjustmentId ? adjustmentPotentialRedactors : potentialRedactors)?.find(
									p => p.isDeleted
								) ||
								!(record.adjustmentId ? adjustmentPotentialRedactors : potentialRedactors)?.find(
									pr => pr.name === redactor.name
								)
									? 'red'
									: 'blue'
							}
						>
							{record.redactors?.find(p => p.isDeleted) ||
							!(record.adjustmentId ? adjustmentPotentialRedactors : potentialRedactors)?.find(
								pr => pr.name === redactor.name
							)
								? 'Пользователь был удален'
								: redactor.name}
						</Tag>
					))
				)
		},
		{
			key: 'updateDate',
			title: 'Дата изменения',
			width: 100,
			render: (_, record) =>
				record.updateDate &&
				dayjs(record.updateDate).isValid() &&
				dayjs(record.updateDate).format('HH:mm DD.MM.YYYY')
		},
		{
			ellipsis: true,
			dataIndex: 'constructionObjectId',
			key: 'constructionObject',
			title: 'Объект строительства',
			onCell: (record: IContainer) => ({
				record,
				dataIndex: 'constructionObjectId',
				title: 'Объект строительства',
				editing: isEditing(record.id),
				constructionObjects: constructionObjects,
				user: currentUser,
				permissions: permissions,
				currentUser: currentUser
			}),
			showSorterTooltip: false,
			width: 200,
			render: (_, record) => (
				<Tooltip title={record.constructionObject?.name}>
					<div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
						{record.constructionObject?.name}
					</div>
				</Tooltip>
			)
		},
		{
			dataIndex: 'ready',
			key: 'ready',
			title: 'ВОР',
			align: 'center',
			width: 64,
			sorter: true,
			render: (_, record) => record.ready && <CheckSquareOutlined style={{ color: '#52c41a' }} />
		},
		{
			fixed: 'right',
			dataIndex: 'control',
			width: 80,
			render: (_, record) => (
				<WbsRegistryActions
					record={record}
					editable={isEditing(record.id)}
					isLoading={isLoading}
					wbsRegistryForm={wbsRegistryForm}
					onSave={() => onSave(record)}
					data={data}
				/>
			)
		}
	]
	return columns
	// return useMemo<typeof columns>(() => {
	// 	return columns
	// }, [isLoading, editableRow])
}

import { API } from 'services/ApiService'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { TChecklistTemplateDto } from '../model/checklist-template-card-schema'

export interface IGetData {
	createdAt?: Date
	owner?: string
	templateType?: number
}

export interface ICheckedOrders {
	checkedOrders: number[]
}

export const checklistTemplateCardApi = {
	async get(id: string) {
		return await API<TChecklistTemplateDto>({
			url: `/v1/checklistTemplates/${id}`,
			method: 'get'
		}).then(response => response.data)
	},

	async getWithStages(id: string) {
		return await API<TChecklistTemplateDto>({
			url: `/v1/checkListTemplates/ndc/${id}`,
			method: 'get'
		}).then(response => response.data)
	},

	async saveNdc(data: TChecklistTemplateDto, type?: TEMPLATE_TYPE) {
		return await API({
			url: `/v1/checkListTemplates/ndc`,
			method: 'post',
			data
		}).then(response => response.data)
	},

	async saveUkkc(data: TChecklistTemplateDto) {
		return await API({
			url: `/v1/checkListTemplates`,
			method: 'post',
			data
		}).then(response => response.data)
	}
}

export const getTemplatesOrders = async () => {
	return await API<ICheckedOrders>({
		url: `/v1/checkListTemplatesCheckedOrders`,
		method: 'get'
	}).then(async response => response.data)
}

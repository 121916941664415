import { create } from 'zustand'
import { TChecklistTemplateForm } from './checklist-template-card-schema'

type TStateContext = {
  currentTemplateId: string | null
  currentTemplateType: string | number | null
  showCard: boolean
  initForm: TChecklistTemplateForm
}

type TStateActions = {
  setCurrentTemplateId: (id: string | null) => void
  setCurrentTemplateType: (type: string | number | null) => void
  setIsCreate: () => void
  setInitForm: (form: TChecklistTemplateForm) => void
  unset: () => void
  showToggle: (show: boolean) => void
}

type TState = TStateContext & TStateActions

const init: TStateContext = {
  currentTemplateId: null,
  currentTemplateType: null,
  showCard: false,
  initForm: {}
}

export const useChecklistTemplateCardState = create<TState>((set, get) => ({
  ...init,
  setCurrentTemplateId: id => set(() => ({ currentTemplateId: id })),
  setCurrentTemplateType: type => set(() => ({ currentTemplateType: type })),
  setIsCreate: () => set(() => ({ showCard: true })),
  setInitForm: form => set(() => ({ initForm: form })),
  showToggle: () => set(() => ({ showCard: !get().showCard })),
  unset: () => set(() => ({ ...init }))
}))
export enum ISSUE_TYPES {
	acceptance = 'e371fab6-3910-4862-92f6-e01229a27d52',
	quality = '4494898b-54e1-4a39-bf1a-2fdbc5e23b0d',
	security = '4a0eb51c-ab63-4508-bf60-51669c13a535'
}

export enum ISSUE_STATUS {
	closed = '2d9df9da-5340-4f2a-ad30-77a24bb1bfea',
	ready = '5758e9f3-b35b-4d97-8616-aa3bbb96131f',
	open = '4ae3200c-d2df-4c70-922a-780ea1575a35',
	cancel = '958da9b4-0ac3-43bc-8f0c-3b61341083b7'
}

export enum RECEPTION_TYPE {
	issueTypeId = 'e371fab6-3910-4862-92f6-e01229a27d52',
	issueSubTypeId = 'e8ba2d14-33d6-44ad-a53f-418ce28b76d2',
	violationTypeId = '3107b174-faec-451a-b99f-ece202697ac2'
}

import { FolderOutlined } from '@ant-design/icons'
import { Card, Col, Descriptions, Flex, Row } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { AbsFormView } from 'features/abs-form'
import { FC } from 'react'
import { TChecklistSection } from 'widgets/checklist-card/model/checklist-card-schema'
import { ChecklistSectionItems } from './checklist-section-items'

type TProps = {
	disabled: boolean
	section: TChecklistSection
}

export const ChecklistSection: FC<TProps> = ({ disabled, section }) => {
	const items: DescriptionsItemType[] = [
		{
			key: 'description',
			children: (
				<AbsFormView
					isLoading={disabled}
					label="Описание раздела"
					name="title"
					dataSourceValue={section.description}
				/>
			)
		}
	]
	return (
		<Card
			title={
				<Row align="middle" gutter={8}>
					<Col>
						<FolderOutlined style={{ fontSize: 20, color: '#0061bf' }} />
					</Col>
					<Col>
						<h3 style={{ margin: 0 }}>
							Раздел №{section.order + 1}: {section.title}
						</h3>
					</Col>
				</Row>
			}
			className="app-work-card"
			classNames={{
				body: 'app-work-card__body',
				header: 'app-work-card__header app-work-card__header--section'
			}}
		>
			<Flex vertical gap={16}>
				<Descriptions
					items={items}
					size="small"
					column={1}
					colon={false}
					labelStyle={{ display: 'none' }}
					className={'app-drawer-descriptions'}
					bordered
				/>

				<ChecklistSectionItems items={section.items} disabled={disabled} />
			</Flex>
		</Card>
	)
}

import { CommentOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Form, Modal, Row, Tabs, TabsProps } from 'antd'

import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CONTAINER_STATUS, PERMISSIONS, ROUND_VALUE } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { adjustmentApi } from 'widgets/adjustment'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useEstimateState } from 'widgets/estimate'
import { useEstimatePositionState } from 'widgets/estimate-position-card/model/estimate-position-state'
import {
	EstimatePositionCommentsView,
	useEstimatePositionCommentsState
} from 'widgets/estimate-position-comments'
import { EstimateApi } from 'widgets/estimate/api/estimate-api'
import { useWbsPermits } from 'widgets/wbs'
import { MaterialCommentModal } from '../material-comment/material-comment-modal'
import { EstimatePositionHistory } from './estimate-position-history'
import { EstimatePositionProperties } from './estimate-position-properties'
import { EstimatePositionTechnologies } from './estimate-position-technologies'
import { EstimatePositionWorks } from './estimate-position-works'

interface IProps {
	disabled: boolean
	type?: 'wbs' | 'local'
	isAdjustment?: boolean
}

export const EstimatePosition: FC<IProps> = ({ disabled, type, isAdjustment }) => {
	const checkPermissions = useCheckPermissions()
	const location = useLocation()

	const state = location.pathname.includes('adjustment') ? useAdjustmentState : useEstimateState

	const { container, containerType, expandedRows, setExpandedRows, updateSections } = state()

	const { user, permissions } = useAppSelector(state => state.environment)
	const wbsPermissions = useWbsPermits()

	const [isLoading, setIsLoading] = useState(false)
	const [estimatePositionForm] = Form.useForm()
	const {
		dto,
		show,
		estimatePositionId,
		section,
		workData,
		containerId,
		setToEdit,
		saveEstimatePosition,
		unsetCard
	} = useEstimatePositionState()

	const onCloseCard = async () => {
		estimatePositionForm.resetFields()
		unsetCard()
	}

	const onSave = async () => {
		if (workData && containerId && section) {
			setIsLoading(true)
			await saveEstimatePosition(
				containerId,
				section?.id!,
				location.pathname.includes('adjustment')
			)
				.then(data => {
					section.id && updateSections(section.id, data)
					location.pathname.includes('adjustment')
						? adjustmentApi
								.getSectionPositions(containerId, section?.id!, containerType)
								.then(res => {
									const newSaveSectionsEP = res
										.filter(ep => ep.id === data)
										.reduce((acc: any, elem: any) => {
											acc[elem.id] = true
											return acc
										}, expandedRows)
									setExpandedRows(newSaveSectionsEP)
								})
						: EstimateApi.getEstimatePositions(containerId, section?.id!, containerType).then(
								res => {
									const newSaveSectionsEP = res
										.filter(ep => ep.id === data)
										.reduce((acc: any, elem: any) => {
											acc[elem.id] = true
											return acc
										}, expandedRows)
									setExpandedRows(newSaveSectionsEP)
								}
						  )
					onCloseCard()
				})
				.catch((err: AxiosError<IErrorDetail>) => {
					err.response?.status === 409
						? Modal.error({
								title: 'Данные устарели',
								content: err.response?.data.title,
								okText: 'Обновить',
								onOk: () => {
									const toEdit = {
										estimatePositionId: estimatePositionId,
										section: {
											id: section?.id,
											name: section?.name
										},
										containerId: containerId
									}
									unsetCard()
									setToEdit(
										toEdit.estimatePositionId!,
										{ id: toEdit.section.id, name: toEdit.section.name },
										toEdit.containerId
									)
								}
						  })
						: Modal.error({
								title: 'Ошибка сохранения',
								content:
									err.response?.data.detail ?? 'Произошла ошибка. Обратитесь к администратору'
						  })
				})
				.finally(() => setIsLoading(false))
		}
	}

	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate =
		container?.isLocalEstimate === false && wbsPermissions.canLocalEdit

	const canSave = () => {
		switch (true) {
			case container?.ready ||
				container?.isLocalEstimate === true ||
				container?.status.name === CONTAINER_STATUS.approved:
				return false

			case workData !== undefined && workData![0]?.work.isClosed! === true:
				return false

			case isAdjustment &&
				((isAuthor &&
					(container?.status.name === CONTAINER_STATUS.new ||
						container?.status.name === CONTAINER_STATUS.preparation) &&
					checkPermissions([
						PERMISSIONS.AdjustmentCreate
						// PERMISSIONS.AdjustmentExternal,
						// PERMISSIONS.AdjustmentSdu,
						// PERMISSIONS.AdjustmentDzMtr
					])) ||
					user?.isAdmin):
				return true

			case !isAdjustment &&
				(isAuthor ||
					(checkPermissions([PERMISSIONS.EstimateDzMtr, PERMISSIONS.WbsDzMtr]) &&
						container?.status.name === CONTAINER_STATUS.structureApproval)):
				return true

			case !isAdjustment &&
				container?.isLocalEstimate === false &&
				isAuthor &&
				checkPermissions([PERMISSIONS.LocalEstimateCreate]) &&
				checkPermissions([
					PERMISSIONS.EstimateExternal,
					PERMISSIONS.LocalEstimateView,
					PERMISSIONS.EstimateDzSmr,
					PERMISSIONS.EstimateSDU
				]):
				return true

			case !isAdjustment &&
				checkPermissions([PERMISSIONS.EstimateDzMtr]) &&
				container?.status.name === CONTAINER_STATUS.structureApproval &&
				workData !== undefined &&
				workData![0]?.work.isEditableNominated! === true:
				return true

			default:
				return false
		}
	}

	return (
		<Form
			disabled={container?.isLocalEstimate === true}
			form={estimatePositionForm}
			name="EstimatePositionForm"
			onFinishFailed={() => {
				if (
					workData![0].work.typeMaterials?.find(
						w =>
							w.isOptional === false && (w.typeMaterial.name === null || w.typeMaterial.name === '')
					)
				) {
					Modal.info({
						content:
							'Позицию сметы создать нельзя. Обратитесь в НСИ для перевода Вида материала в статус Актуальный'
					})
				}
			}}
			onFinish={() => onSave()}
		>
			<Drawer
				className="estimate-position-drawer"
				open={show}
				onClose={onCloseCard}
				destroyOnClose={true}
				width={window.innerWidth <= 1600 ? '90vw' : '70vw'}
				styles={{
					body: { background: '#fafafa' },
					footer: { padding: '1rem 2rem' }
				}}
				title={'Позиция сметы - ' + section?.name}
				extra={
					<Row gutter={8}>
						{estimatePositionId && (
							<Col>
								<Button
									type={dto?.hasComments ? 'primary' : 'default'}
									icon={<CommentOutlined />}
									onClick={() => {
										useEstimatePositionCommentsState.setState({
											show: true,
											currentId: estimatePositionId
										})
									}}
								>
									Чат
								</Button>
							</Col>
						)}
						{!disabled && Object.values(wbsPermissions).some(p => p === true) && (
							<Col>
								<Button
									type="primary"
									loading={isLoading}
									htmlType="submit"
									disabled={!canSave()}
									onClick={() => estimatePositionForm.submit()}
								>
									Сохранить
								</Button>
							</Col>
						)}
					</Row>
				}
				footer={<i>* Дробные значения будут округлены до {ROUND_VALUE} знаков после запятой.</i>}
			>
				<EstimatePositionCommentsView type={type} />
				<MaterialCommentModal />
				<Tabs
					size="small"
					items={
						[
							{
								key: 'detail',
								label: 'Детали',
								children: (
									<>
										<EstimatePositionTechnologies estimatePositionForm={estimatePositionForm} />
										<EstimatePositionWorks
											estimatePositionForm={estimatePositionForm}
											canEdit={
												isAdjustment
													? (checkPermissions([
															PERMISSIONS.AdjustmentCreate
															// PERMISSIONS.AdjustmentExternal,
															// PERMISSIONS.AdjustmentSdu
													  ]) &&
															isAuthor &&
															(container?.status.name === CONTAINER_STATUS.new ||
																container?.status.name === CONTAINER_STATUS.preparation)) ||
													  user?.isAdmin!
													: (!isLocalEstimate && isAuthor && wbsPermissions.canEdit) ||
													  (canEditCorrectLocalEstimate && isAuthor)
											}
											disabled={
												container?.ready ||
												container?.isLocalEstimate === true ||
												(workData !== undefined && workData![0]?.work.isClosed! === true)
											}
											isClosed={workData !== undefined && workData![0]?.work.isClosed! === true}
										/>
										<EstimatePositionProperties
											estimatePositionForm={estimatePositionForm}
											canEdit={
												isAdjustment
													? (checkPermissions([
															PERMISSIONS.AdjustmentCreate
															// PERMISSIONS.AdjustmentExternal,
															// PERMISSIONS.AdjustmentSdu
													  ]) &&
															isAuthor &&
															(container?.status.name === CONTAINER_STATUS.new ||
																container?.status.name === CONTAINER_STATUS.preparation)) ||
													  user?.isAdmin!
													: (!isLocalEstimate && isAuthor && wbsPermissions.canEdit) ||
													  (canEditCorrectLocalEstimate && isAuthor)
											}
											disabled={
												container?.ready ||
												container?.isLocalEstimate === true ||
												(workData !== undefined && workData![0]?.work.isClosed! === true)
											}
										/>
									</>
								)
							},
							{
								key: 'history',
								label: 'История',
								children: <EstimatePositionHistory />
							}
						] as TabsProps['items']
					}
				/>
			</Drawer>
		</Form>
	)
}

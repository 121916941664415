import { notification } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { useCallback } from 'react'
import { downloadFile } from './shared-api'

export const useDownload = () => {
	return useCallback(
		(fileId: string, name: string, extension?: string) =>
			downloadFile(fileId)
				.then(response => {
					const href = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = href
					link.setAttribute('download', name + extension)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					URL.revokeObjectURL(href)
				})
				.catch((error: AxiosError<IErrorDetail>) =>
					notification.error({
						message: 'Получение файла',
						description: `Во время загрузки файла произошла ошибка. Код ошибки: ${error.response?.status}`,
						placement: 'topLeft'
					})
				),
		[]
	)
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { checklistTemplateCardApi } from '../api/checklist-template-card-api'
import { TChecklistTemplateRequestDto } from '../model/checklist-template-card-schema'

export const useSaveChecklistTemplate = (
	currentTemplateTypeCard: TEMPLATE_TYPE | undefined,
	onCardClose: () => void
) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['checklistTemplate', 'save'],
		mutationFn: async (dto: TChecklistTemplateRequestDto[]) => {
			if (currentTemplateTypeCard === TEMPLATE_TYPE.ndc) {
				return checklistTemplateCardApi.saveNdc(dto as any)
			} else if (currentTemplateTypeCard === TEMPLATE_TYPE.ukkc) {
				return checklistTemplateCardApi.saveUkkc(dto as any)
			}
		},
		onSuccess: () => {
			notification.success({
				message: 'Шаблон успешно сохранён',
				placement: 'topLeft'
			})
			queryClient.invalidateQueries({ queryKey: ['templates'] })
			onCardClose()
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка во время сохранения',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})
}

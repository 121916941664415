import { Card, DatePicker, Descriptions, Form, FormInstance } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import dayjs from 'dayjs'
import { IIssueCard } from 'entities/issues'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { FC } from 'react'
import { DATE_FORMAT } from 'shared/constants'
import { ISSUE_TYPES } from 'shared/constants/issue-types'
import {
	useIssueActPoints,
	useIssueSubTypes,
	useIssueSystems,
	useIssueTypes,
	useIssueViolations,
	useIssueViolationTypes,
	useIssueWorkPackages
} from '../model/issue-query'
import { IssueCardDetailsIsTypical } from './IssueCardDetailsIsTypical'
import { IssueFormBranchItem } from './IssueFormBranchItem'
import { IssueFormItem } from './IssueFormItem'
import { IssueFormSelectorItem } from './IssueFormSelectorItem'

interface IProps {
	canEdit: boolean
	dataSource?: IIssueCard | null
	formValues: TIssueDetailForm
	form: FormInstance<TIssueDetailForm>
}

export const IssueCardDetailsViolation: FC<IProps> = ({
	canEdit,
	dataSource,
	formValues,
	form
}) => {
	const { data: workPackages, isFetching: workPackagesFetching } = useIssueWorkPackages()
	const { data: issueTypes, isFetching: issueTypesFetching } = useIssueTypes()
	const { data: issueSubTypes, isFetching: issueSubTypesFetching } = useIssueSubTypes(
		formValues?.issueTypeId
	)
	const { data: violationTypes, isFetching: violationTypesFetching } = useIssueViolationTypes(
		formValues?.issueSubTypeId
	)
	const { data: actPoints, isFetching: actPointsFetching } = useIssueActPoints({
		violationTypeId: undefined,
		workPackageId: formValues?.workPackageId,
		violationId: formValues?.violationId! ?? undefined
	})
	const { data: systems, isFetching: systemsFetching, error } = useIssueSystems()

	const { data: violations, isFetching: violationsFetching } = useIssueViolations({
		violationTypeId: formValues?.violationTypeId,
		workPackageId: formValues?.workPackageId,
		actPointId: formValues?.actPointId! ?? undefined
	})

	const items: DescriptionsItemType[] = [
		{
			key: 'isTypical',
			children: <IssueCardDetailsIsTypical isTypical={formValues?.isTypical} form={form} canEdit={canEdit} />
		},
		{
			key: 'workPackage',
			children: (
				<IssueFormSelectorItem
					canEdit={canEdit}
					options={workPackages}
					label="Пакет СМР"
					name="workPackageId"
					rules={[{ required: formValues?.isTypical, message: 'Укажите пакет СМР' }]}
					dataSourceValue={dataSource?.workPackage?.name}
					isLoading={workPackagesFetching}
					form={form}
				/>
			)
		},
		{
			key: 'issueType',
			children: (
				<IssueFormSelectorItem
					canEdit={canEdit}
					options={
						workPackages?.find(wp => wp.id === formValues?.workPackageId)?.issueTypes ?? issueTypes
					}
					label="Тип замечания"
					name="issueTypeId"
					rules={[{ required: true, message: 'Укажите тип замечания' }]}
					dataSourceValue={dataSource?.issueType?.name}
					isLoading={issueTypesFetching}
					form={form}
				/>
			)
		},
		{
			key: 'issueSubType',
			children: (
				<IssueFormSelectorItem
					canEdit={canEdit}
					options={issueSubTypes}
					label="Подтип замечания"
					name="issueSubTypeId"
					rules={[{ required: true, message: 'Укажите подтип замечания' }]}
					disabled={typeof formValues?.issueTypeId === 'undefined'}
					dataSourceValue={dataSource?.issueSubType?.name}
					isLoading={issueSubTypesFetching}
					form={form}
				/>
			)
		},
		{
			key: 'violationType',
			children: (
				<IssueFormSelectorItem
					canEdit={canEdit}
					options={violationTypes}
					label="Тип нарушения"
					name="violationTypeId"
					rules={[{ required: true, message: 'Укажите подтип замечания' }]}
					disabled={typeof formValues?.issueSubTypeId === 'undefined'}
					dataSourceValue={dataSource?.violationType?.name}
					isLoading={violationTypesFetching}
					form={form}
				/>
			)
		},
		{
			key: 'title',
			children: (
				<IssueFormItem
					canEdit={canEdit}
					label="Нарушение"
					name="title"
					dataSourceValue={dataSource?.violation?.name}
					rules={[
						{
							required: true,
							message: 'Укажите нарушение'
						}
					]}
				/>
			)
		},
		{
			key: 'actPoint',
			children: (
				<IssueFormBranchItem
					canEdit={canEdit}
					actPoints={actPoints}
					options={actPoints}
					isLoading={actPointsFetching}
					isManualInit={dataSource?.actPoint === null}
					label={'Номер пункта нормативного акта'}
					manualField={'actPoints'}
					selectorField={'actPointId'}
					manualDataSourceValue={dataSource?.actPoints}
					selectorDataSourceValue={dataSource?.actPoint?.name}
					form={form}
					formValues={formValues}
					rules={[
						{
							required: true,
							message: 'Укажите номер пункта нормативного акта'
						}
					]}
				/>
			)
		},
		{
			key: 'description',
			children: (
				<IssueFormBranchItem
					canEdit={canEdit}
					violations={violations}
					options={violations}
					isLoading={violationsFetching}
					isManualInit={dataSource?.violation === null}
					label={'Описание'}
					manualField={'description'}
					selectorField={'violationId'}
					manualDataSourceValue={dataSource?.description}
					selectorDataSourceValue={dataSource?.violation?.name}
					form={form}
					formValues={formValues}
					rules={[
						{
							required: true,
							message: 'Описание не должно быть пустым'
						}
					]}
				/>
			)
		},
		{
			key: 'system',
			children: (
				<IssueFormSelectorItem
					canEdit={canEdit}
					options={systems}
					label="Система"
					name="systemId"
					dataSourceValue={dataSource?.system?.name}
					isLoading={systemsFetching}
					error={error}
					form={form}
				/>
			)
		},
		{
			key: 'locationDescription',
			children: (
				<IssueFormItem
					isArea={false}
					canEdit={canEdit}
					label="Описание локации"
					name="locationDescription"
					dataSourceValue={dataSource?.locationDescription}
					rules={[
						{
							required: true,
							message: 'Описание локации не должно быть пустым'
						}
					]}
				/>
			)
		},
		{
			key: 'createdAt',
			children: (
				<Form.Item label="Дата создания" name="createdAt">
					<span className="app-form-text">
						{dayjs(dataSource?.createdAt || dayjs()).format(DATE_FORMAT.view)}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'dueDate',
			children: (
				<Form.Item
					label="Дата устранения"
					name="dueDate"
					rules={[
						{
							required: !(formValues?.issueTypeId === ISSUE_TYPES.acceptance),
							message: 'Укажите дату устранения'
						}
					]}
				>
					{canEdit ? (
						<DatePicker
							allowClear
							style={{
								width: '100%'
							}}
							format={'DD.MM.YYYY'}
							disabledDate={current => {
								return current && current < dayjs().startOf('day')
							}}
							variant="borderless"
						/>
					) : (
						<span className="app-form-text">
							{dataSource?.dueDate && dayjs(dataSource.dueDate).isValid()
								? dayjs(dataSource.dueDate).format(DATE_FORMAT.view)
								: '-'}
						</span>
					)}
				</Form.Item>
			)
		}
	]
	return (
		<Card title="Нарушение" className="app-work-card" classNames={{ body: 'app-work-card__body' }}>
			<Descriptions
				items={items}
				size="small"
				column={1}
				colon={false}
				labelStyle={{ display: 'none' }}
				className={'app-drawer-descriptions'}
				bordered
			/>
		</Card>
	)
}

import { DatePicker, Form } from 'antd'
import { Rule } from 'antd/es/form'
import dayjs from 'dayjs'
import { range } from 'lodash'
import { FC } from 'react'
import { DATE_FORMAT } from 'shared/constants'
interface IProps {
	canEdit: boolean
	disabled?: boolean
	dataSourceValue?: Date | null
	name: string
	label: string
	rules?: Rule[]
	withTime?: boolean
	offsetDay?: number
}

const disabledDateTime = () => ({
	disabledHours: () => range(0, 24).filter(r => r < 8 || r > 18)
})

export const AbsFormDatePicker: FC<IProps> = props => {
	const { canEdit, disabled, name, label, dataSourceValue, rules, withTime, offsetDay = 0 } = props
	return canEdit ? (
		<Form.Item label={label} name={name} rules={rules}>
			<DatePicker
				disabled={disabled}
				allowClear
				style={{
					width: '100%'
				}}
				format={withTime ? DATE_FORMAT.viewWithHours : DATE_FORMAT.view}
				showTime={withTime}
				hideDisabledOptions
				disabledDate={current => {
					return current && current < dayjs().startOf('day').add(offsetDay, 'day')
				}}
				disabledTime={disabledDateTime}
				variant="borderless"
			/>
		</Form.Item>
	) : (
		<Form.Item label={label}>
			<span className="app-form-text">
				{dataSourceValue && dayjs(dataSourceValue).isValid()
					? dayjs(dataSourceValue).format(DATE_FORMAT.view)
					: '-'}
			</span>
		</Form.Item>
	)
}

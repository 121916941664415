import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Card, Col, message, notification, Row, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd/lib'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { ImageCarousel } from 'pages/issuesPage/issueDrawer/imageCarousel'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { FC, Key, useMemo, useState } from 'react'
import { downloadFile } from 'services/AdminService'
import { formatBytes } from 'services/helperService'
import { DATE_FORMAT } from 'shared/constants'
import { wbsTaskCardApi } from '../api/wbs-task-card-api'
import { ImageIcons } from './wbs-task-image-icons'
import { TWbsTaskAttachment } from 'widgets/wbs-task-registry/model/wbs-task-registry-interface'

interface IProps {
	loading?: boolean
	ownerId?: string
	attachments: TWbsTaskAttachment[]
	canEdit: boolean
}

export const WbsTaskAttachmentList: FC<IProps> = ({
	// loading,
	ownerId,
	attachments,
	canEdit
}) => {
	const { currentTaskId } = useWbsTaskState()
	const queryClient = useQueryClient()
	const { user: currentUser } = useAppSelector(state => state.environment)
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
	const [selectedAttachment, setSelectedAttachment] = useState<string | null>(null)

	const formatSelect = [
		'bmp',
		'gif',
		'png',
		'jpeg',
		'dib',
		'rle',
		'tif',
		'jpg',
		'ico',
		'wmf',
		'emf'
	]

	const { mutate } = useMutation({
		mutationKey: ['wbsTask', 'attachments'],
		mutationFn: async ({ currentTaskId, items }: { currentTaskId: string; items: Key[] }) =>
			await wbsTaskCardApi.deleteFiles(currentTaskId, items),
		onSuccess: () => {
			notification.success({
				message: selectedRowKeys.length > 1 ? 'Файлы успешно удалены' : 'Файл успешно удалён',
				placement: 'topLeft'
			})
			setSelectedRowKeys([])
			queryClient.invalidateQueries({ queryKey: ['wbsTaskCard', 'attachments'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка во время удаления',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const onAttachmentsDelete = async (items: Key[]) => {
		if (currentTaskId) {
			mutate({ currentTaskId, items })
		}
	}

	const onDownload = (record: any) => {
		downloadFile(record.id)
			.then(response => {
				const href = URL.createObjectURL(response.data)
				const link = document.createElement('a')
				link.href = href
				link.setAttribute('download', record.name + record.extension!)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				URL.revokeObjectURL(href)
			})
			.catch(() => message.error('Во время загрузки файла произошла ошибка'))
	}

	const attachmentColumns = useMemo<ColumnsType<any>>(
		() => [
			{
				width: '5%',
				align: 'center',
				render: (_, record) => ImageIcons(record.extension?.replace('.', '').toLowerCase()),
				onCell: record => ({
					onClick: event => {
						event.preventDefault()
						if (formatSelect.some(f => record.extension.includes(f))) {
							setSelectedAttachment(record.id)
						}
					}
				})
			},
			{
				dataIndex: 'name',
				title: 'Файл'
			},
			{
				dataIndex: 'extension',
				title: 'Формат',
				render: (_, record) => record.extension?.replace('.', '').toLowerCase()
			},
			{
				dataIndex: ['createdBy', 'name'],
				title: 'Автор',
				width: '16%'
			},
			{
				title: 'Дата загрузки',
				dataIndex: 'uploadDate',
				width: '12%',
				render: value => dayjs(value).format(DATE_FORMAT.viewWithHours)
			},
			{
				title: 'Размер файла',
				dataIndex: 'fileSize',
				width: '12%',
				render: value => formatBytes(value)
			},
			{
				title: selectedRowKeys.length > 0 && (
					<center>
						<Tooltip title="Удалить выбранное">
							<Button
								danger
								block
								type="primary"
								icon={<DeleteOutlined />}
								onClick={() => onAttachmentsDelete(selectedRowKeys)}
								disabled={!canEdit}
							/>
						</Tooltip>
					</center>
				),
				render: (_, record) => (
					<Row gutter={8} wrap={false} justify="center">
						<Col>
							<Tooltip placement="topLeft" title="Скачать">
								<Button
									shape="circle"
									size="middle"
									onClick={() => onDownload(record)}
									icon={<DownloadOutlined />}
								/>
							</Tooltip>
						</Col>
						{
							<Col>
								<Tooltip placement="topLeft" title="Удалить">
									<Button
										shape="circle"
										size="middle"
										danger
										disabled={selectedRowKeys.length > 0 || !canEdit}
										onClick={() => onAttachmentsDelete([record.id])}
										icon={<DeleteOutlined />}
									/>
								</Tooltip>
							</Col>
						}
					</Row>
				)
			}
		],
		[attachments, selectedRowKeys]
	)

	return (
		<>
			{attachments && (
				<ImageCarousel
					attachments={attachments
						.flatMap(f => f.files)
						.filter(a => formatSelect.some(f => a.extension.includes(f)))}
					initialSlide={selectedAttachment}
					show={selectedAttachment !== null}
					onClose={() => setSelectedAttachment(null)}
				/>
			)}
			{attachments
				.sort((a, b) => a.order - b.order)
				.map(a => (
					<Card
						title={a.description ?? undefined}
						styles={{ header: { background: '#e3e8e8' } }}
						size="small"
					>
						<Table
							// loading={{
							// 	indicator: <LoadingOutlined style={{ fontSize: 36 }} spin />,
							// 	spinning: loading
							// }}
							size="small"
							id="ant-table-cell-scroll"
							rowKey="id"
							pagination={false}
							dataSource={a.files}
							columns={attachmentColumns}
							rowSelection={{
								selectedRowKeys,
								onChange: setSelectedRowKeys
							}}
						/>
					</Card>
				))}
		</>
	)
}

import { List } from 'antd'
import { FC } from 'react'
import { TChecklistSectionItem } from 'widgets/checklist-card/model/checklist-card-schema'
import { ChecklistSectionItem } from './checklist-section-item'
type TProps = {
	disabled: boolean
	items: TChecklistSectionItem[]
}
export const ChecklistSectionItems: FC<TProps> = ({ disabled, items }) => {
	return (
		<List
			itemLayout={'vertical'}
			grid={{ gutter: [0, 16], column: 1 }}
			dataSource={items}
			renderItem={item => <ChecklistSectionItem item={item} disabled={disabled} />}
		/>
	)
}

import { useQuery } from '@tanstack/react-query'
import {
	getCheckListTemplates,
	getCheckListTemplatesActiveUKKS,
	getTemplates
} from 'services/TemplatesService'
import { setTotalItems } from 'store/templatesSlice'
import { useAppDispatch, useAppSelector } from './appReduxHook'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'

export const useChecklistTemplateList = (templateType?: TEMPLATE_TYPE, workPackageId?: string) => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	const totalItems = useAppSelector(state => state.templates.listState.totalItems)
	return useQuery({
		queryKey: ['checklists', projectId, totalItems, templateType, workPackageId],
		queryFn: async () => {
			return await getTemplates(templateType, workPackageId).then(data => data)
		},
		select: data => {
			return data
				.sort((a, b) =>
					a.cipher!?.localeCompare(b.cipher!, undefined, { numeric: true, sensitivity: 'base' })
				)
				.map(item => ({
					...item,
					title: (item.cipher === null ? '' : item.cipher + ' ') + item.title
				}))
		},
		refetchOnWindowFocus: false
	})
}

export const useChecklistTemplateLists = () => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	const filters = useAppSelector(state => state.templates.filters)
	const currentPage = useAppSelector(state => state.templates.listState.currentPage)
	const sort = useAppSelector(state => state.templates.sort)
	const dispatch = useAppDispatch()
	return useQuery({
		queryKey: ['templates', projectId, filters, currentPage, sort],
		queryFn: async () => {
			const checkListTemplate = await getCheckListTemplates({
				sort,
				filters: filters,
				offset: currentPage! > 1 ? currentPage! * 50 - 50 : 0
			}).then(response => response)
			dispatch(setTotalItems(checkListTemplate.totalItems))
			return checkListTemplate.data
		},
		refetchOnWindowFocus: false
	})
}

export const useCheckListTemplatesActiveUKKS = () => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	return useQuery({
		queryKey: ['checkListTemplatesActiveUKKS', projectId],
		queryFn: async () => {
			return await getCheckListTemplatesActiveUKKS().then(data => data)
		}
	})
}

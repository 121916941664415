import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { IContract } from 'interfaces/IContract'
import { IPagination } from 'interfaces/IPagination'
import { Key } from 'react'
import { API } from 'services/ApiService'
import { IWorkPackage } from 'services/workPackageService'
import { PAGE_LIMIT } from 'shared/constants'
import { TBase } from 'shared/schemas'
import { TBaseIsDeleted } from 'shared/schemas/base-schema'
import { ILocalEstimate } from 'widgets/localEstimate'
import {
	IWbsTaskCard,
	IWbsTaskCreateDocument,
	TWbsTaskAttachment
} from 'widgets/wbs-task-registry/model/wbs-task-registry-interface'

export const wbsTaskCardApi = {
	async get(id?: string) {
		return await API<IWbsTaskCard>({
			url: `/v1/wbsTask/${id}`,
			method: 'get'
		}).then(response => response.data)
	},
	async getAttachments(id: string) {
		return await API<TWbsTaskAttachment[]>({
			url: `/v1/wbsTask/${id}/attachments`,
			method: 'get'
		}).then(response => response.data)
	},
	async getWbsTaskType() {
		return await API<TBase[]>({
			url: `/v1/wbsTask/wbsTaskType`,
			method: 'get'
		}).then(response => response.data)
	},
	async getConstructionObjects() {
		return await API<TBase[]>({
			url: `/v1/constructionObjects`,
			method: 'get'
		}).then(response => response.data)
	},
	async getWorkPackages() {
		return await API<IWorkPackage[]>({
			url: `/v1/workPackage`,
			method: 'get'
		}).then(response => response.data)
	},
	async getTypeSections() {
		return await API<TBaseIsDeleted[]>({
			url: `/v1/typeSection`,
			method: 'get'
		}).then(response => response.data)
	},
	async getContracts(projectId?: string) {
		return await API<IContract[]>({
			url: `/v1/contracts`,
			method: 'get',
			params: { projectId }
		}).then(response => response.data)
	},
	async getPotentialAssignUsers(statusId: string) {
		return await API<TBaseIsDeleted[]>({
			url: `/v1/wbsTask/potentialUsersAssign`,
			method: 'get',
			params: { statusId: statusId }
		}).then(response => response.data)
	},
	async getEstimates() {
		const currentPage: number = 1
		return await API<IPagination<ILocalEstimate[]>>({
			url: '/v1/containerEstimates',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT
			}
		}).then(response => response.data)
	},
	async save(dto: TWbsTaskForm) {
		return await API({
			url: `/v1/wbsTask`,
			method: 'post',
			data: dto
		}).then(response => response.data)
	},
	async update(id: string, dto: TWbsTaskForm) {
		return await API({
			url: `/v1/wbsTask/${id}`,
			method: 'patch',
			data: dto
		}).then(response => response.data)
	},
	async delete(id: string) {
		return await API({
			url: `/v1/wbsTask/${id}`,
			method: 'delete'
		}).then(response => response.data)
	},
	async getWbsTaskContainer(
		typeSectionId: string,
		constructionObjectId: string,
		search?: string,
		currentPage: number = 1
	) {
		return await API<IPagination<TBase[]>>({
			url: `/v1/wbsTask/container`,
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage! * PAGE_LIMIT - PAGE_LIMIT,
				typeSectionId: typeSectionId,
				constructionObjectId: constructionObjectId,
				search: search
			}
		}).then(response => response.data)
	},
	async getWbsTaskEstimate(
		contractId: string,
		constructionObjectId: string,
		search?: string,
		currentPage: number = 1
	) {
		return await API<IPagination<TBase[]>>({
			url: `/v1/wbsTask/estimate`,
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT,
				contractId: contractId,
				constructionObjectId: constructionObjectId,
				search: search
			}
		}).then(response => response.data)
	},
	async addAttachments(id: string, dto: FormData, conf: any) {
		return await API({
			url: `/v1/wbsTask/${id}/attachments`,
			method: 'post',
			data: dto,
			...conf
		}).then(response => response.data)
	},
	async deleteFiles(id: string, ids: Key[]) {
		return await API({
			url: `/v1/wbsTask/${id}/attachments`,
			method: 'delete',
			data: { attachmentIds: ids }
		}).then(response => response.data)
	},
	async updateAssignDesign(id: string, dto: string[]) {
		return await API({
			url: `/v1/wbsTask/${id}/assignment/design`,
			method: 'patch',
			data: { potentialAssignedWbsTasks: dto }
		}).then(response => response.data)
	},
	async updateAssignEconomy(id: string, dto: string[]) {
		return await API({
			url: `/v1/wbsTask/${id}/assignment/economy`,
			method: 'patch',
			data: { potentialAssignedWbsTasks: dto }
		}).then(response => response.data)
	},
	async createDocument(
		dto: IWbsTaskCreateDocument
	) {
		return await API({
			url: `/v1/wbsTask/${
				dto.type === 'newWbs'
					? 'container'
					: 'adjustment'
			}`,
			method: 'post',
			data:
			dto.type === 'newWbs'
					? {
							name: dto.name,
							projectId: dto.projectId,
							typeSectionId: dto.typeSectionId,
							constructionObjectId: dto.constructionObjectId,
							wbsTaskId: dto.wbsTaskId
					  }
					: { id: dto.wbsTaskTypeId, wbsTaskId: dto.wbsTaskId, projectId: dto.projectId, isContainer: dto.type === 'container' ? true : false }
		}).then(response => response.data)
	}
}

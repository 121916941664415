import { Col, Form, Modal, Select, Typography } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { useChecklistTemplateList } from 'hooks/useChecklistTemplateList'
import { ITemplate } from 'interfaces/ITemplate'
import { FC } from 'react'
import { TEMPLATE_TYPE, TEMPLATE_TYPES } from 'shared/constants/checklist-types'
import { useChecklistCardState } from 'widgets/checklist-card'

const { Title } = Typography

interface IProps {
	modalVisible: boolean
	setDrawerVisible: (value: boolean) => void
	setModalVisible: (value: boolean) => void
	templates?: ITemplate[]
	setTemplates?: (templates: React.SetStateAction<ITemplate[]>) => void
}

type TCreateChecklistForm = {
	templateType: TEMPLATE_TYPE
	workPackage?: string
	template: string
}

const CreateChecklistModal: FC<IProps> = ({ modalVisible, setDrawerVisible, setModalVisible }) => {
	const [form] = Form.useForm<TCreateChecklistForm>()
	const formValues = Form.useWatch([], form)
	const { data: templates, isFetching } = useChecklistTemplateList(
		formValues?.templateType,
		formValues?.workPackage
	)
	const workPackages = useAppSelector(state => state.environment.workPackages)
	const setPrepare = useChecklistCardState(state => state.setPrepare)

	const onFinish = async (values: TCreateChecklistForm) => {
		if (typeof values.templateType === 'number') {
			setPrepare({
				templateType: values.templateType,
				templateId: values.template
			})
			onCancel()
		}
	}

	const onCancel = () => {
		setModalVisible(false)
		form.resetFields()
	}

	const onFormChangeHandler = (value: any) => {
		switch (Object.keys(value)[0]) {
			case 'templateType':
				form.resetFields(['workPackage', 'template'])
				break
			case 'workPackage':
				form.resetFields(['template'])
				break
		}
	}

	return (
		<Form
			form={form}
			name="CheckListTemplateForm"
			onFinish={onFinish}
			onValuesChange={onFormChangeHandler}
		>
			<Modal
				title="Создание чек-листа"
				open={modalVisible}
				destroyOnClose={true}
				onOk={() => form.submit()}
				okButtonProps={{ disabled: formValues?.template === null }}
				okText="Создать"
				onCancel={onCancel}
				cancelText="Закрыть"
			>
				<Col>
					<Title level={5}>Тип чек-листа:</Title>
					<Form.Item name="templateType">
						{
							<Select
								allowClear
								style={{ width: '100%', textOverflow: 'ellipsis' }}
								options={Object.values(TEMPLATE_TYPES).map((x, i) => ({
									label: x,
									value: i
								}))}
							/>
						}
					</Form.Item>
					{formValues?.templateType === TEMPLATE_TYPE.ndc && (
						<>
							<Title level={5}>Пакет работ:</Title>
							<Form.Item name="workPackage">
								<Select
									style={{ width: '100%', textOverflow: 'ellipsis' }}
									allowClear
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.name ?? '').toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(optionA, optionB) =>
										(optionA?.name ?? '')
											.toLowerCase()
											.localeCompare((optionB?.name ?? '').toLowerCase())
									}
									loading={isFetching}
									options={workPackages ?? []}
									fieldNames={{ label: 'name', value: 'id' }}
								/>
							</Form.Item>
						</>
					)}
					{typeof formValues?.templateType === 'number' && (
						<>
							<Title level={5}>Шаблон:</Title>
							<Form.Item name="template">
								<Select
									allowClear
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.title ?? '').toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(optionA, optionB) =>
										(optionA?.title ?? '')
											.toLowerCase()
											.localeCompare((optionB?.title ?? '').toLowerCase())
									}
									options={templates}
									disabled={!!!templates?.length}
									fieldNames={{ label: 'title', value: 'id' }}
									loading={isFetching}
									style={{ width: '100%', textOverflow: 'ellipsis' }}
								></Select>
							</Form.Item>
						</>
					)}
				</Col>
			</Modal>
		</Form>
	)
}
export default CreateChecklistModal

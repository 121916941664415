import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { FC } from 'react'
import { useMonitoringContractsWithFines } from '../api/monitoring-queries'
import { MonitoringContractsWithFines } from '../api/monitoring-schema'

export const ContractsWithFinesMonitoring: FC = () => {
	const { data, isFetching } = useMonitoringContractsWithFines()
	const columns: ColumnsType<MonitoringContractsWithFines> = [
		{
			dataIndex: 'contractName',
			title: 'Номер договора'
		},
		{
			dataIndex: 'contractId',
			title: 'Id договора'
		},
		{
			dataIndex: 'contractorName',
			title: 'Наименование подрядчика'
		},
		{
			dataIndex: 'contractorId',
			title: 'Id подрядчика'
		},
		{
			dataIndex: 'ordinanceFineFirstSum',
			title: 'Сумма штрафа'
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Table
				loading={isFetching}
				className="app-table-types"
				rowKey={record => record.contractId}
				size="small"
				pagination={false}
				bordered
				dataSource={data}
				columns={columns as any}
			/>
		</div>
	)
}

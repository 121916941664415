import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Row, Col } from 'antd';
import { FormInstance } from 'antd/es/form';
import { FC } from 'react';
import { Item } from './ChecklistTemplateItem';

interface ISectionsProps {
  form: FormInstance;
  canEdit: boolean;
  stageField?: any;
}

export const Section: FC<ISectionsProps> = ({
  form,
  canEdit,
  stageField,
}) => {
  const formName = stageField ? [...stageField, 'sections'] : ['sections']

  // console.log(stageField, 'stageField')
  return (
    <Form.List name={formName}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Card
              key={field.key}
              className="app-work-card"
              style={{ position: 'relative', marginBottom: 16 }}
            >
              {canEdit && (
                <CloseOutlined
                  onClick={() => remove(field.name)}
                  style={{
                    fontSize: '16px',
                    padding: '.5rem',
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '-18px',
                    right: '25px',
                    cursor: 'pointer',
                    zIndex: 1,
                    background: '#f2f2f2',
                    border: '1px solid #ddd',
                    borderRadius: '50px',
                  }}
                />
              )}
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="app-form-item"
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    name={[field.name, 'title']}
                    label={`Раздел № ${index + 1}`}
                    rules={[
                      { required: true, message: 'Укажите название раздела' },
                    ]}
                  >
                    <Input
                      placeholder="Название раздела"
                      disabled={!canEdit}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="app-form-item"
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    name={[field.name, 'description']}
                    label="Описание раздела"
                  >
                    <Input.TextArea
                      placeholder="Описание"
                      disabled={!canEdit}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Item
                form={form}
                canEdit={canEdit}
                sectionField={field}
              />
            </Card>
          ))}

          {canEdit && (
            <Button
              type="primary"
              onClick={() => add()}
              style={{ width: '100%', marginTop: 16 }}
              icon={<PlusOutlined />}
            >
              Добавить раздел
            </Button>
          )}
        </>
      )}
    </Form.List>
  );
};

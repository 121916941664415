import { TEMPLATE_TYPE } from "shared/constants/checklist-types";
import { TChecklistTemplateForm } from "./checklist-template-card-schema";


export function validateChecklistTemplate (values: TChecklistTemplateForm, currentTemplateTypeCard: TEMPLATE_TYPE) {
  if (currentTemplateTypeCard === TEMPLATE_TYPE.ndc) {
    const stages = values.checkListTemplateStages
    if (!stages) {
      return 'Нельзя сохранить шаблон без этапов!'
    }

    const stagesWithSections = stages.every(
      stage => stage.sections && stage.sections.length
    )
    if (!stagesWithSections) {
      return 'Нельзя сохранить шаблон без разделов!'
    }

    const sectionsWithItems = stages.every(stage =>
      stage.sections?.every(sec => sec.items && sec.items.length)
    )
    if (!sectionsWithItems) {
      return 'Нельзя сохранить шаблон с разделами без пунктов!'
    }

  } else if (currentTemplateTypeCard === TEMPLATE_TYPE.ukkc) {
    const sections = values.sections
    if (!sections) {
      return 'Нельзя сохранить шаблон без разделов!'
    }

    const sectionsWithItems = sections.every(
      sec => sec.items && sec.items.length
    )
    if (!sectionsWithItems) {
      return 'Нельзя сохранить шаблон с разделами без пунктов!'
    }
  }
}
import { useIsMutating, useQueryClient } from '@tanstack/react-query'
import { Drawer, Form, Tabs, TabsProps } from 'antd'
import dayjs from 'dayjs'
import { absRequiredMark } from 'features/abs-form'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useCallback, useLayoutEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CHECKLIST_STATUS, TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { RECEPTION_TYPE } from 'shared/constants/issue-types'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import History from 'UI/history'
import { ISSUE_CARD_SOURCE, IssueCard } from 'widgets/issue-card'
import { ZodError } from 'zod'
import {
	useChecklist,
	useChecklistCreate,
	useChecklistIssueAssignMutation,
	useChecklistMutation
} from '../api/checklist-card-queries'
import {
	checklistFormSchema,
	TChecklistForm,
	TChecklistUpdateDto
} from '../model/checklist-card-schema'
import { useChecklistCardState } from '../model/checklist-card-state'
import { ChecklistCardAddFileModal } from './checklist-card-add-file-modal'
import { ChecklistCardAttachments } from './checklist-card-attachments'
import { ChecklistCardDetails } from './checklist-card-details'
import { ChecklistCardExtra } from './checklist-card-extra'

type TProps = {
	onClose?: () => void
}

export const ChecklistCard: FC<TProps> = ({ onClose }) => {
	const currentUser = useAppSelector(state => state.environment.user)
	const checkPermissions = useCheckPermissions()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const queryClient = useQueryClient()
	const [cardForm] = Form.useForm<TChecklistForm>()
	const {
		showCard,
		initForm,
		currentId,
		templateType,
		prepare: isNew,
		setInitForm,
		unset
	} = useChecklistCardState()
	const formValues = Form.useWatch(values => values, cardForm)
	const { data, isSuccess, isFetching } = useChecklist(onClose)
	const { mutate: onSaveChecklist } = useChecklistCreate()
	const { mutate: onUpdateChecklist } = useChecklistMutation()
	const { mutate: assignIssue } = useChecklistIssueAssignMutation()
	const isFormDisabled =
		!currentUser?.isAdmin &&
		(data?.status === CHECKLIST_STATUS.Canceled || data?.status === CHECKLIST_STATUS.Completed)
	const isChecklistMutating = useIsMutating({ mutationKey: ['checklist'] }) !== 0
	const tabItems: TabsProps['items'] = [
		{
			key: 'details',
			label: 'Детали',
			children: (
				<ChecklistCardDetails
					isLoading={isFetching || isChecklistMutating}
					disabled={isFormDisabled}
					dataSource={data}
					form={cardForm}
					formValues={formValues}
				/>
			)
		},
		{
			key: 'attachments',
			label: 'Файлы',
			disabled: !!!data?.attachments.length,
			children: <ChecklistCardAttachments attachments={data?.attachments ?? []} />
		},
		{
			label: 'История',
			key: 'history',
			disabled: !!isNew,
			children: <History id={data?.id} target={'checklist'} />
		}
	]

	useLayoutEffect(() => {
		if (isSuccess && data) {
			const init: TChecklistForm = {
				assignedByElimination: data.assignedByElimination,
				assignedTo: data.potentialAssignedUsers?.flatMap(x => x.id) ?? [],
				capacity: data.capacity,
				checkListCipher: data.checkListCipher,
				commissioningOrganizationId: data.commissioningOrganization?.id,
				contractorId: data.contractor?.id,
				description: data.description,
				doorSections: data.doorSections,
				factDate: data?.factDate && dayjs(data?.factDate).isValid() ? dayjs(data.factDate) : null,
				location: data.location ?? 'Не указана',
				ownerId: !!isNew ? currentUser?.id ?? undefined : data.owner?.id,
				plannedDate:
					data?.plannedDate && dayjs(data?.plannedDate).isValid() ? dayjs(data.plannedDate) : null,
				quarantyPeriod: data.quarantyPeriod,
				status: data.status,
				subContractorId: data.subContractor?.id,
				title: data.title,
				workPackageId: data.workPackage?.id ?? data.workPackageId
			}
			setInitForm(init)
			cardForm.setFieldsValue(init)
		}
	}, [data])

	const onCloseHandler = useCallback(() => {
		// const testForm = removeEmpty(initForm) ?? {}
		// const valuesForm = removeEmpty(formValues) ?? {}
		// console.log('testForm', testForm)
		// console.log('valuesForm', valuesForm)
		// if (!isEqual(valuesForm, testForm)) {
		// 	modal.confirm({
		// 		title: 'Внимание',
		// 		content: 'Есть не сохранённые данные',
		// 		onOk: () => onCardClose()
		// 	})
		// } else onCardClose()
		if (!isChecklistMutating) onCardClose()
	}, [])

	const onCardClose = () => {
		cardForm.resetFields()
		unset()
		onClose && onClose()
	}

	const onChangeHandler = (value: object) => {
		switch (Object.keys(value)[0]) {
			case 'contractorId':
				cardForm.resetFields(['subContractorId', 'commissioningOrganizationId'])
				break
			case 'doorSections':
				cardForm.setFieldValue(
					'doorSections',
					Object.values(value)[0].sort((a: number, b: number) => a - b)
				)
				break
		}
	}

	const onSave = async () => {
		const formData: TChecklistForm = cardForm.getFieldsValue(true)
		const zodValidate = await checklistFormSchema.safeParseAsync(formData)
		if (!(zodValidate instanceof ZodError)) {
			const dto: TChecklistUpdateDto = {
				...formData,
				factDate: dayjs(formData.factDate).isValid() ? dayjs(formData.factDate).toDate() : null,
				plannedDate: dayjs(formData.plannedDate).isValid()
					? dayjs(formData.plannedDate).toDate()
					: null,
				capacity: formData?.capacity?.length === 0 ? null : formData?.capacity
			}
			if (isNew)
				onSaveChecklist({
					...dto,
					checkListTemplateId: isNew.templateId,
					potentialAssignedUsersId: dto.assignedTo
				})
			if (currentId) onUpdateChecklist({ id: currentId, dto })
		} else {
			console.log('checklistOnSave', zodValidate)
		}
	}

	const onIssueClose = (
		x?: boolean,
		mustUpdate?: boolean,
		issueId?: string,
		checklistItemId?: string
	) => {
		if (issueId && currentId && checklistItemId) {
			const issues = data?.sections
				?.flatMap(x => x.items)
				.find(i => i.id === checklistItemId)?.issues
			const isExist = issues?.some(issue => issue.id === issueId)
			if (!isExist) {
				assignIssue({ itemId: checklistItemId, issueId: [issueId] })
			} else {
				queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })
			}
		}
	}

	// useEffect(() => {
	// 	const initChecklist = async () => {
	// 		if (data && pathname.includes('checklist/')) {

	// 		}
	// 	}
	// 	initChecklist()
	// }, [data])

	return (
		<Form
			autoComplete="off"
			colon={false}
			disabled={isFetching || isChecklistMutating}
			form={cardForm}
			labelAlign="left"
			labelCol={{ span: 6 }}
			layout="inline"
			name="checklistCardForm"
			onFinish={onSave}
			onValuesChange={changedValues => onChangeHandler(changedValues)}
			requiredMark={absRequiredMark}
			scrollToFirstError
		>
			<Drawer
				// loading={isChecklistFetching !== 0}
				open={showCard}
				onClose={onCloseHandler}
				rootClassName="app-entity-card"
				classNames={{
					body: 'app-entity-card__body',
					header: `app-entity-card__header app-entity-card__header--${
						Object.entries(CHECKLIST_STATUS)
							.find((s, i) => i === formValues?.status)?.[1]
							?.toString()
							.toLowerCase() ?? 'open'
					}`
				}}
				styles={{ body: { background: '#fafafa', padding: 0 } }}
				destroyOnClose={true}
				width={window.innerWidth <= 1360 ? '75vw' : '50vw'}
				title={
					data
						? isNew
							? `Новый чек-лист №${data.number}`
							: `Чек-лист №${data.number}`
						: 'Новый чек-лист'
				}
				extra={
					<ChecklistCardExtra
						isNew={!!isNew}
						form={cardForm}
						isLoading={isFetching || isChecklistMutating}
						formValues={formValues}
						disabled={isFormDisabled}
					/>
				}
			>
				<IssueCard
					source={ISSUE_CARD_SOURCE.checklist}
					onClose={onIssueClose}
					updateList={() => queryClient.invalidateQueries({ queryKey: ['checklist', 'card'] })}
					checklistData={{
						contractorId: formValues?.contractorId,
						subContractorId: formValues?.subContractorId,
						templateTitle: formValues?.title,
						workPackageId: formValues?.workPackageId ?? undefined,
						locationDescription: formValues?.location ?? undefined,
						issueTypeId:
							templateType === TEMPLATE_TYPE.ukkc ? RECEPTION_TYPE.issueTypeId : undefined,
						issueSubTypeId:
							templateType === TEMPLATE_TYPE.ukkc ? RECEPTION_TYPE.issueSubTypeId : undefined,
						violationTypeId:
							templateType === TEMPLATE_TYPE.ukkc ? RECEPTION_TYPE.violationTypeId : undefined
					}}
				/>
				<ChecklistCardAddFileModal />
				<Tabs items={tabItems} className="app-tabs-sticky" size="large" tabBarGutter={16} />
			</Drawer>
		</Form>
	)
}

import { API } from 'services/ApiService'
import { IWorkPackage } from 'services/workPackageService'

export const downloadFile = async (fileId: string) => {
	return await API({
		url: `/v1/files/${fileId}/download`,
		method: 'get',
		responseType: 'blob'
	}).then(async response => response)
}

export const sharedApi = {
	async getWorkPackages() {
		return await API<IWorkPackage[]>({
			url: `/v1/workPackage`,
			method: 'get'
		}).then(response => response.data)
	}
}

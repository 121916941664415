import { List } from 'antd'
import { FC } from 'react'
import { TChecklistStage } from '../model/checklist-card-schema'
import { ChecklistStage } from './checklist-stage'

type TProps = {
	disabled: boolean
	stages?: TChecklistStage[]
}

export const ChecklistStages: FC<TProps> = ({ disabled, stages }) => {
	return (
		<List
			rowKey="id"
			itemLayout={'vertical'}
			grid={{ gutter: [0, 16], column: 1 }}
			locale={{ emptyText: 'В шаблоне нет этапов' }}
			dataSource={stages}
			renderItem={item => <ChecklistStage stage={item} disabled={disabled} />}
		/>
	)
}

import { useMutation, useQuery } from "@tanstack/react-query"
import { useAppSelector } from "hooks/appReduxHook"
import { useWbsTaskState } from "pages/wbs-tasks-page/model/wbs-tasks-state"
import { wbsTaskCardApi } from "widgets/wbs-task-card/api/wbs-task-card-api"
import { IWbsTaskCreateDocument } from "../model/wbs-task-registry-interface"
import { wbsTaskRegistryApi } from "./wbs-task-registry-api"

export const useWbsTaskRegistry = () => {
    const { project } = useAppSelector(state => state.environment)
    const { currentPage, wbsTaskFilters, sortState, setTotalItems } = useWbsTaskState()
	return useQuery({
		queryKey: ['wbsTasks', currentPage, wbsTaskFilters, sortState, project],
		queryFn: async () => {
			const registry =  await wbsTaskRegistryApi.get(currentPage, wbsTaskFilters, sortState).then(data => data)
            setTotalItems(registry.totalItems)
				return registry.data
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsTaskDelete = () => {
    return useMutation({
    mutationKey: ['wbsTask', 'delete'],
    mutationFn: async ({ wbsTaskId }: { wbsTaskId: string }) =>
        await wbsTaskCardApi.delete(wbsTaskId)
})}

export const useWbsTaskCreateDocument = () => {
    return useMutation({
    mutationKey: ['wbsTask', 'createDocument'],
    mutationFn: async ({ dto }: { dto: IWbsTaskCreateDocument}) =>
        await wbsTaskCardApi.createDocument(dto)
})}
import { Form, TreeSelect } from 'antd'
import { IBaseConstructionObject } from 'interfaces/IBase'
import { IContainer } from 'interfaces/IContainer'
import { FC, useEffect, useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { markDisabledNodes } from '../model/wbs-helper'

interface IProps {
	editing: boolean
	constructionObjects: IBaseConstructionObject[]
	record?: IContainer
	fieldName?: string
}

export const ConstructionObjectSelect: FC<IProps> = ({
	editing,
	constructionObjects,
	record,
	fieldName = 'constructionObjectId'
}) => {
	const [transformedData, setTransformedData] = useState<IBaseConstructionObject[]>([])
	const checkPermissions = useCheckPermissions()
	const canEdit = !record?.constructionObject || checkPermissions([PERMISSIONS.Admin])

	useEffect(() => {
		if (editing) {
			const data = markDisabledNodes(constructionObjects || []) as IBaseConstructionObject[]
			setTransformedData(data)
		} else {
			setTransformedData([])
		}
	}, [editing, constructionObjects])

	return (
		<Form.Item
			name={fieldName}
			style={{ margin: 0 }}
			validateFirst
			rules={[
				{
					required: true,
					message: 'Выберите объект строительства.'
				},
				() => ({
					validator(_, value) {
						if (value === record?.constructionObject?.id) {
							const obj: any = record?.constructionObject
							const isValid =
								obj.isActual === true &&
								obj.isSelectAvailable === true &&
								obj.isGroup === false &&
								obj.isDeleted === false
							if (!isValid) {
								return Promise.reject(
									new Error('Объект строительства недоступен для выбора. Укажите другое значение.')
								)
							}
						}

						return Promise.resolve()
					}
				})
			]}
		>
			<TreeSelect
				fieldNames={{ label: 'name', value: 'id', children: 'children' }}
				treeData={transformedData}
				dropdownStyle={{
					width: 500,
					maxHeight: '300px',
					overflow: 'auto'
				}}
				showSearch
				disabled={!canEdit}
				placeholder="Выберите объект строительства"
				allowClear
				treeDefaultExpandAll
				filterTreeNode={(input, treeNode) => {
					return treeNode.name?.toLowerCase()?.includes(input.toLowerCase())
				}}
			/>
		</Form.Item>
	)
}

import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { create } from 'zustand'
import { TChecklistForm, TChecklistPrepare } from './checklist-card-schema'

type TState = {
	addFileItemModal: string | null
	checklistTemplateId?: string
	currentId: string | null
	initForm: Partial<TChecklistForm>
	prepare?: TChecklistPrepare
	showCard: boolean
	templateType: TEMPLATE_TYPE | null
}
type TStateActions = {
	openCard: () => void
	setCurrentChecklist: (id: string, templateType: TEMPLATE_TYPE) => void
	setFileItemModal: (itemId: string | null) => void
	setInitForm: (form: Partial<TChecklistForm>) => void
	setPrepare: (prepare: TChecklistPrepare) => void
	unset: () => void
}

const initialValues = {
	addFileItemModal: null,
	checklistTemplateId: undefined,
	currentId: null,
	initForm: {},
	prepare: undefined,
	showCard: false,
	templateType: null
}

export const useChecklistCardState = create<TState & TStateActions>((set, get) => ({
	...initialValues,
	openCard: () => set(() => ({ showCard: true })),
	setCurrentChecklist: (currentId, templateType) => set(() => ({ currentId, templateType })),
	setFileItemModal: itemId => set(() => ({ addFileItemModal: itemId })),
	setInitForm: form => set(() => ({ initForm: form, currentForm: form })),
	setPrepare: prepare =>
		set(() => ({ prepare, templateType: prepare.templateType, showCard: true })),
	unset: () => set(() => ({ ...initialValues }))
}))

import { List } from 'antd'
import { FC } from 'react'
import { TChecklistSection } from '../model/checklist-card-schema'
import { ChecklistSection } from './checklist-section'

type TProps = {
	disabled: boolean
	sections?: TChecklistSection[]
}

export const ChecklistSections: FC<TProps> = ({ disabled, sections }) => {
	return (
		<List
			rowKey="id"
			itemLayout={'vertical'}
			grid={{ gutter: [0, 16], column: 1 }}
			locale={{ emptyText: 'В шаблоне нет разделов' }}
			dataSource={sections}
			renderItem={item => <ChecklistSection section={item} disabled={disabled} />}
		/>
	)
}

import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useMonitoringAutoOrdinances } from '../api/monitoring-queries'
import { MonitoringAutoOrdinances } from '../api/monitoring-schema'

export const AutoOrdinancesMonitoring: FC = () => {
	const { data, isFetching } = useMonitoringAutoOrdinances()
	const columns: ColumnsType<MonitoringAutoOrdinances> = [
		{
			dataIndex: 'issueId',
			title: 'Id замечания',
			render: value => (
				<NavLink to={`/control/issues/${value}`} target="_blank">
					{value}
				</NavLink>
			)
		},
		{
			dataIndex: 'issueTitle',
			title: 'Замечание'
		},
		{
			dataIndex: 'issueDueDate',
			title: 'Срок устранения',
			render: value => (dayjs(value).isValid() ? dayjs(value).format('DD.MM.YY HH:mm') : '')
		},
		{
			dataIndex: 'issueDateCreate',
			title: 'Дата создания',
			render: value => (dayjs(value).isValid() ? dayjs(value).format('DD.MM.YY HH:mm') : '')
		},
		{
			dataIndex: 'projectName',
			title: 'Проект'
		},
		{
			dataIndex: 'projectId',
			title: 'Id проекта'
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Table
				loading={isFetching}
				className="app-table-types"
				rowKey={record => record.issueId}
				size="small"
				pagination={false}
				bordered
				dataSource={data}
				columns={columns as any}
			/>
		</div>
	)
}

export const WBS_TASK_TYPE = {
	new: { name: 'Новый ВОР', id: '0c81dc39-3cf1-49a2-b436-bf44dfbc4e3c' },
	wbsAdjustment: { name: 'Кор Вор через БЫЛО-СТАЛО', id: '4383ff3a-ada7-4ca6-a7f2-0355262b7192' },
	estimateAdjustment: {
		name: 'Кор ЛС через БЫЛО-СТАЛО',
		id: 'de9d5968-f261-4c2e-84c4-603d6f9465bf'
	}
}

export enum WBS_TASK_TYPE_ENUM {
	new = 1,
	wbsAdjustment = 2,
	estimateAdjustment = 3
}
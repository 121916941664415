import { create } from "zustand"
import { MonitoringFilters } from "../api/monitoring-schema"

interface IMonitoringState {
    currentPage: number
    totalItems?: number
    monitoringFilters?: MonitoringFilters
    onPagination: (currentPage: number) => void
	setTotalItems: (totalItems: number) => void
    setMonitoringFilters: (filters?: MonitoringFilters) => void
}

export const useMonitoringState = create<IMonitoringState>((set, get) => ({
    currentPage: 1,
    totalItems: undefined,
    monitoringFilters: {comparisonOperator: undefined, date: null},
    onPagination: currentPage => set(() => ({ currentPage })),
	setTotalItems: totalItems => set(() => ({ totalItems })),
    setMonitoringFilters: filters => set(() => ({ monitoringFilters: {...filters} })),
}))
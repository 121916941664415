import { FileDoneOutlined } from '@ant-design/icons'
import { useIsMutating } from '@tanstack/react-query'
import { Card, Col, Descriptions, Flex, Row } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { AbsFormView } from 'features/abs-form'
import { FC } from 'react'
import { TChecklistSectionItem } from 'widgets/checklist-card/model/checklist-card-schema'
import { useShallow } from 'zustand/react/shallow'
import { useChecklistCardState } from '../model/checklist-card-state'
import { ChecklistCardDocumentationLink } from './checklist-card-documentation-link'
import { ChecklistSectionItemActions } from './checklist-section-item-actions'
import { ChecklistSectionItemAttachments } from './checklist-section-item-attachments'

type TProps = {
	disabled: boolean
	item: TChecklistSectionItem
}

export const ChecklistSectionItem: FC<TProps> = ({ item, disabled }) => {
	const isPrepare = !!useChecklistCardState(useShallow(state => state.prepare))
	const isChecklistMutating = useIsMutating({ mutationKey: ['checklist'] }) !== 0
	const items: DescriptionsItemType[] = [
		{
			key: 'description',
			children: (
				<AbsFormView
					isLoading={disabled}
					label="Описание пункта"
					name="title"
					dataSourceValue={item.description}
				/>
			)
		},
		{
			key: 'docLink',
			children: (
				<AbsFormView
					isLoading={disabled}
					label="Ссылка на документацию"
					name="docLink"
					dataSourceValue={item.documentation}
					isLink={true}
					extra={
						<ChecklistCardDocumentationLink
							itemId={item.id}
							value={item.documentation}
							disabled={disabled || isPrepare}
						/>
					}
				/>
			)
		}
	]
	return (
		<Card
			title={
				<Row align="middle" gutter={8}>
					<Col>
						<FileDoneOutlined style={{ fontSize: 16, color: '#0061bf' }} />
					</Col>
					<Col>
						<h4 style={{ margin: 0 }}>
							Пункт № {item.order + 1}: {item.name}
						</h4>
					</Col>
				</Row>
			}
			extra={
				<ChecklistSectionItemActions
					disabled={isPrepare}
					itemId={item.id}
					answer={item.answer}
					isLoading={isChecklistMutating || disabled}
				/>
			}
			className="app-work-card"
			classNames={{
				body: 'app-work-card__body',
				header: 'app-work-card__header app-work-card__header--section-item'
			}}
		>
			<Flex vertical gap={16}>
				<Descriptions
					items={items}
					size="small"
					column={1}
					colon={false}
					labelStyle={{ display: 'none' }}
					className={'app-drawer-descriptions'}
					bordered
				/>

				<ChecklistSectionItemAttachments
					disabled={disabled || isPrepare}
					item={item}
					issues={item.issues}
					files={item.attachments}
					isIssueRequired={item.checkboxIssue}
					isFileRequired={item.checkboxFile}
				/>
			</Flex>
		</Card>
	)
}

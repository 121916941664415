import { IContainer } from 'interfaces/IContainer'
import { IPagination } from 'interfaces/IPagination'
import { API } from 'services/ApiService'
import { PAGE_LIMIT } from 'shared/constants'
import { IWbsCheckArchive, IWbsCopyForm, IWbsRegistryFilters } from '..'

interface IApi {
	getRegistry: (
		currentPage: number,
		filters?: IWbsRegistryFilters,
		sortState?: number
	) => Promise<IPagination<IContainer[]>>
	checkArchiveContainer: (containerId: string) => Promise<IWbsCheckArchive[]>
	saveContainer: (container: IContainer) => void
	createCorrection: (containerId: string, name: string) => Promise<any>
	copyContainer: (containerId: string, formData: IWbsCopyForm) => Promise<any>
	removeContainer: (containerId: string, adjustment: boolean) => Promise<any>
	importData: (container: IContainer) => void
	exportData: (container: IContainer, withPrice: boolean) => void
}

export const WbsRegistryApi: IApi = {
	async getRegistry(currentPage, filters, sortState) {
		return await API<IPagination<IContainer[]>>({
			url: '/v1/containers',
			method: 'get',
			params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT,
				sortState,
				...filters
			}
		}).then(response => response.data)
	},
	async checkArchiveContainer(containerId: string) {
		return await API({
			url: `v1/containers/${containerId}/checkArchive`,
			method: 'get'
		}).then(response => response.data)
	},
	async saveContainer(container) {},
	async createCorrection(containerId, name) {
		return await API({
			url: `/v1/containers/${containerId}/correctionContainer`,
			method: 'post',
			data: { name }
		}).then(response => response.status)
	},
	async copyContainer(containerId, formData) {
		return await API({
			url: `/v1/containers/${containerId}/copy`,
			method: 'post',
			params: { ...formData }
		}).then(response => response.status)
	},
	async removeContainer(containerId, adjustment) {
		return await API({
			url: `/v1/${adjustment ? 'adjustment' : 'containers'}/${containerId}`,
			method: 'delete'
		}).then(response => response.status)
	},
	async importData(container) {},
	async exportData(container, withPrice) {}
}

export enum TEMPLATE_TYPE {
	ndc,
	ukkc
}
export const TEMPLATE_TYPES: Record<TEMPLATE_TYPE, string> = {
	[TEMPLATE_TYPE.ndc]: 'УКС НДК',
	[TEMPLATE_TYPE.ukkc]: 'УК КС'
}

export enum CHECKLIST_STATUS {
	New,
	InProgress,
	Completed,
	Canceled,
	Warranty
}

export enum CHECKLIST_STATUSES {
	New = 'Новый',
	InProgress = 'В работе',
	Completed = 'Завершен',
	Canceled = 'Аннулирован',
	Warranty = 'Принято под гарантийные обязательства'
}

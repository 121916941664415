import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { ESTIMATE_DOC_TYPE } from 'shared/constants/estimate-doc-types'
import { IEstimateCreation } from '../model/estimate-related-documents-schema'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateRelatedDocumentsState } from '../model/estimate-related-documents-state'

const getUrl = (docType: ESTIMATE_DOC_TYPE, id: string): string => {
	switch (docType) {
		case ESTIMATE_DOC_TYPE.wbs:
		case ESTIMATE_DOC_TYPE.wbsCorrection:
			return `/unmodel/${id}`

		case ESTIMATE_DOC_TYPE.wbsAdjustment:
			return `/unmodel/adjustment/${id}`

		case ESTIMATE_DOC_TYPE.local:
		case ESTIMATE_DOC_TYPE.localCorrection:
			return `/local/${id}`

		case ESTIMATE_DOC_TYPE.localAdjustment:
			return `/local/adjustment/${id}`
	}
}

export const useEstimateRelatedDocumentsColumns = () => {
	const { selectedId } = useEstimateRelatedDocumentsState(
		useShallow(state => ({
			selectedId: state.selectedId
		}))
	)
	const { id: paramId } = useParams()
	const columns = useMemo<ColumnsType<IEstimateCreation>>(
		() => [
			{
				dataIndex: 'type',
				title: 'Тип',
				width: 100,
				align: 'center',
				render: (value, record) => {
					return record.id === paramId || record.id === selectedId ? (
						<span style={{ fontWeight: 'bold' }}>{value}</span>
					) : (
						value
					)
				}
			},
			{
				dataIndex: 'name',
				title: 'Наименование',
				width: 100,
				align: 'center',
				render: (value, record) => {
					return (
						<NavLink to={getUrl(record.docType, record.id)} target="_blank">
							{record.id === paramId || record.id === selectedId ? (
								<span style={{ fontWeight: 'bold' }}>
									{value}
								</span>
							) : (
								value
							)}
						</NavLink>
					)
				}
			},
			{
				dataIndex: 'date',
				title: 'Дата',
				width: 80,
				align: 'center',
				render: (_, record) => {
					return record.id === paramId || record.id === selectedId ? (
						<span style={{ fontWeight: 'bold' }}>
							{dayjs(record?.date).isValid() &&
								dayjs(record?.date).format('DD.MM.YYYY HH:mm')}
						</span>
					) : (
							dayjs(record?.date).isValid() &&
							dayjs(record?.date).format('DD.MM.YYYY HH:mm')
					)
				}
			}
		],
		[selectedId, paramId]
	)
	return columns
}

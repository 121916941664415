import { Form, Input, Select, Tag, TreeSelect } from 'antd'
import { FormInstance } from 'antd/lib'
import { IBase, IBaseConstructionObject, IBasePotentialRedactors } from 'interfaces/IBase'
import { IContainer } from 'interfaces/IContainer'
import { IPermission } from 'interfaces/IPermissions'
import { IAppUser } from 'interfaces/IUser'
import { FC, useEffect, useState } from 'react'
import { markDisabledNodes } from '../model/wbs-helper'
import { useWatch } from 'antd/es/form/Form'
import { ConstructionObjectSelect } from './ConstructionObjectSelect'
import { useWbsRegistryState } from '../model/wbs-registry-state'

interface IProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	dataIndex: string
	title: any
	record: IContainer
	index: number
	potentialRedactors: IBasePotentialRedactors[]
	constructionObjects: IBaseConstructionObject[]
	user: IAppUser | null
	children: React.ReactNode
	permissions: IPermission[]
	currentUser: IAppUser | null
	wbsRegistryForm: FormInstance
}

export const WbsRegistryCell: FC<IProps> = ({
	editing,
	dataIndex,
	title,
	record,
	children,
	potentialRedactors,
	constructionObjects,
	user,
	index,
	permissions,
	currentUser,
	wbsRegistryForm,
	...restProps
}) => {
	const canEdit = (col: 'redactors' | 'constructionObject') => {
		if (user && record.author) {
			switch (col) {
				case 'redactors':
					return user.isAdmin || user.id === record.author.id
				case 'constructionObject':
					return (
						user.isAdmin ||
						(record.constructionObject === null &&
							(user.id === record.author.id || record.redactors?.some(r => r.id === user.id)))
					)
				default:
					return false
			}
		}
		return false
	}

	const handlerTrim = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.trim() === '') {
			wbsRegistryForm?.setFieldsValue({ name: '' })
		} else {
			wbsRegistryForm?.setFieldsValue({ name: e.target.value })
		}
	}

	const setNode = () => {
		switch (dataIndex) {
			case 'name':
				return (
					<Form.Item
						style={{ margin: 0 }}
						name={dataIndex}
						rules={[
							{
								min: 3,
								message: 'Не менее 3х символов'
							},
							{
								max: 255,
								message: 'Не более 255 символов'
							},
							{
								required: true,
								message: 'Укажите название контейнера'
							}
						]}
					>
						<Input onChange={handlerTrim} />
					</Form.Item>
				)
			case 'redactors':
				return (
					<Form.Item name={dataIndex} style={{ margin: 0 }}>
						<Select
							maxTagCount={1}
							showSearch={true}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							disabled={!canEdit('redactors')}
							mode="multiple"
							style={{ width: '100%' }}
							allowClear
							value={potentialRedactors?.sort(
								(a, b) =>
									+b.isDeleted - +a.isDeleted ||
									+(b.id === currentUser?.id) - +(a.id === currentUser?.id) ||
									a?.name?.localeCompare(b?.name)
							)}
							tagRender={props => (
								<Tag closable={false} bordered={false}>
									{potentialRedactors?.find(p => p.name === props.label && p.isDeleted) ||
									record.redactors
										.flatMap(elem => elem.id)
										.filter(item => !potentialRedactors?.flatMap(({ id }) => id).includes(item))
										.find(p => p === props.value)
										? 'Пользователь был удален'
										: props.label}
								</Tag>
							)}
							options={potentialRedactors
								?.filter(
									pr =>
										(!pr.isDeleted && !record.redactors.find(r => r.name === pr.name)) ||
										record.redactors.find(r => r.name === pr.name)
								)
								?.map(r => ({
									key: r.id,
									value: r.id,
									label: r.isDeleted ? 'Пользователь был удален' : r.name,
									disabled: r.isDeleted
								}))}
						/>
					</Form.Item>
				)
			case 'constructionObjectId':
			return (
			  <ConstructionObjectSelect
			    editing={editing}
			    record={record}
			    constructionObjects={constructionObjects}
			    fieldName={dataIndex}
			  />
			)
		}
	}

	const editableNode = setNode()

	return (
		<td {...restProps}>
			<span className={'app-registry-col-' + dataIndex}>{editing ? editableNode : children}</span>
		</td>
	)
}

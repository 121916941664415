import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, notification, Upload, UploadFile, UploadProps } from 'antd'
import { RcFile } from 'antd/es/upload'
import { useState } from 'react'
import { getBase64 } from 'services/helperService'
import { useChecklistAddFileMutation } from '../api/checklist-card-queries'
import { useChecklistCardState } from '../model/checklist-card-state'

const acceptedFiles = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'application/pdf']

export const ChecklistCardAddFileModal = () => {
	const { addFileItemModal, setFileItemModal } = useChecklistCardState()
	const { mutate, isLoading } = useChecklistAddFileMutation()
	const [fileList, setFileList] = useState<UploadFile[]>([])
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')

	const onCloseHandler = () => {
		setFileItemModal(null)
		setFileList([])
	}

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}
		setPreviewImage(file.preview as string)
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const props: UploadProps = {
		accept: acceptedFiles.join(','),
		multiple: true,
		listType: 'picture-card',
		onPreview: file => handlePreview(file),
		onRemove: file => {
			const index = fileList.indexOf(file)
			const newFileList = fileList.slice()
			newFileList.splice(index, 1)
			setFileList(newFileList)
			// setFileList(prev => prev.filter(item => !isEqual(file, item)))
		},
		beforeUpload: file => {
			const isAccepted = acceptedFiles.some(f => f === file.type)
			if (!isAccepted) {
				notification.error({
					message: 'Ошибка добавления файла',
					description: `${file.name} - не поддерживаемый формат файла`,
					placement: 'topLeft'
				})
			} else {
				setFileList(prev => [...prev!, file])
			}
			return false
		}
	}

	const onUpload = () => {
		if (addFileItemModal && fileList.length !== 0) {
			const data = new FormData()
			fileList!?.map(file => data.append('files', file as RcFile))
			mutate({ itemId: addFileItemModal, data }, { onSuccess: onCloseHandler })
		}
	}

	return (
		<Modal
			cancelButtonProps={{ disabled: isLoading }}
			cancelText="Закрыть"
			centered
			confirmLoading={isLoading}
			destroyOnClose={true}
			okButtonProps={{ disabled: fileList.length === 0 || isLoading }}
			okText="Загрузить"
			onCancel={() => (!isLoading ? onCloseHandler() : null)}
			onOk={onUpload}
			open={addFileItemModal !== null}
			title="Загрузка файла (Форматы: JPEG, JPG, PNG, GIF, PDF)"
			width="50vw"
		>
			<div style={{ maxHeight: '60vh', overflowY: 'auto', borderRadius: '8px !important' }}>
				<Upload {...props}>
					<div>
						{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
						<div style={{ marginTop: 8 }}>Добавить</div>
					</div>
				</Upload>
			</div>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewOpen(false)}
			>
				<img alt="example" style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</Modal>
	)
}

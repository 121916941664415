import { UploadFile } from 'antd'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { create } from 'zustand'

type TModalType = 'fromOpenToReady' | 'fromReadyToOpen'

interface IState {
	checklistItemId?: string
	commentModalType?: TModalType
	initForm: TIssueDetailForm
	issueId: string | null
	showCard: boolean
	uploadList: UploadFile[]
}
interface IStateActions {
	openCard: () => void
	setChecklistItemId: (id: string | undefined) => void
	setCommentModalType: (type?: TModalType) => void
	setInitForm: (form: TIssueDetailForm) => void
	setIssueId: (id: string) => void
	setUploadList: (uploadList: UploadFile[]) => void
	unsetIssue: () => void
}

const initialValues = {
	checklistItemId: undefined,
	checklistItemData: undefined,
	commentModalType: undefined,
	initForm: {},
	issueId: null,
	showCard: false,
	uploadList: []
}

export const useIssueCardState = create<IState & IStateActions>((set, get) => ({
	...initialValues,
	openCard: () => set(() => ({ showCard: true })),
	setChecklistItemId: id => set(() => ({ checklistItemId: id, showCard: true })),
	setCommentModalType: type => set(() => ({ commentModalType: type ?? undefined })),
	setInitForm: form => set(() => ({ initForm: form })),
	setIssueId: id => set(() => ({ issueId: id })),
	setUploadList: uploadList => set(() => ({ uploadList })),
	unsetIssue: () => set(() => ({ ...initialValues }))
}))

import { Card, Divider, Form, FormInstance, Input, InputNumber, message } from 'antd'
import { all, create } from 'mathjs'
import { FC, useEffect } from 'react'
import { ROUND_VALUE } from 'shared/constants'

import { EstimatePositionHelpers } from 'widgets/estimate-position-card/model/estimate-position-helpers'
import {
	IEstimatePositionPermissions,
	useEstimatePositionState
} from 'widgets/estimate-position-card/model/estimate-position-state'
import { useEstimateState } from 'widgets/estimate/model/estimate-state'
import { useShallow } from 'zustand/react/shallow'
import { EstimatePositionPropertiesGuid } from './estimate-position-properties-guid'

interface IProps {
	disabled: boolean
	estimatePositionForm: FormInstance
	canEdit: boolean
	permissions?: IEstimatePositionPermissions
}

const config = {}
const math = create(all, config)

export const EstimatePositionProperties: FC<IProps> = ({
	estimatePositionForm,
	canEdit,
	disabled
}) => {
	const {
		currentTechnology,
		workProperties,
		dto,
		workData,
		setWorkPropertyValue,
		setPropertyValue
	} = useEstimatePositionState()
	const container = useEstimateState(useShallow(state => state.container))
	useEffect(() => {
		workProperties?.map(prop =>
			estimatePositionForm.setFieldValue(['properties', prop.identityName], prop.value)
		)
	}, [workProperties])
	return workProperties &&
		(!currentTechnology?.expandable ||
			!(
				currentTechnology?.expandable &&
				workProperties!.length === 1 &&
				workProperties[0].baseQuantity === true
			)) ? (
		<>
			<Divider children="Параметры" orientation="left" />
			<Card className="at-workcard">
				{workProperties!
					.filter(p => (currentTechnology?.expandable ? p.baseQuantity !== true : p))
					.sort((a, b) => {
						if (a.order === null) return 1
						if (b.order === null) return -1
						return a.order - b.order
					})
					.sort((a, b) => +b.baseQuantity - +a.baseQuantity)
					.map(property => {
						const required = property?.required ?? false
						return (
							<Form.Item
								extra={
									property.commentAdditionalProperty !== null && (
										<div style={{ fontSize: 12, color: 'gray' }}>
											{property.commentAdditionalProperty}
										</div>
									)
								}
								required={false}
								labelCol={{ style: { width: 336 } }}
								labelAlign="left"
								key={property.property.id}
								name={['properties', property.identityName]}
								label={
									<span>
										{property.baseQuantity === true ? (
											'Количество работ'
										) : (
											<span style={{ color: property?.isDeleted ? 'red' : 'black' }}>
												{property.name}
											</span>
										)}
										{required ? <b style={{ color: 'red' }}> *</b> : ''}
									</span>
								}
								rules={[
									() => ({
										validator(_, value) {
											if (required) {
												if (value === null || value === undefined || value.length === 0) {
													return Promise.reject(new Error('Поле не должно быть пустым'))
												}
												// if (
												// 	property.baseQuantity === true &&
												// 	value < workData![0].work?.confirmedVolumeAmount!
												// ) {
												// 	return Promise.reject(new Error('Значение должно быть >= Выполнено в ПО'))
												// }
												const validate = EstimatePositionHelpers.validateProperty(
													value,
													property.identityName,
													property.valueType
												)
												if (
													// (property.baseQuantity === true &&
													property.isDeleted === false &&
													(Number(value) === 0 ||
														// )
														!validate.status)
												) {
													const zeroError =
														// property.baseQuantity === true &&
														Number(value) === 0 ? 'Не может быть равно 0' : validate.message
													message.error(zeroError)
													return Promise.reject(new Error(zeroError))
												}
												if (value < 0 || !validate.status) {
													const minusError = value < 0 ? 'Не может быть меньше 0' : validate.message
													message.error(minusError)
													return Promise.reject(new Error(minusError))
												}
												if (
													typeof value === 'string' &&
													value &&
													value.length < 3 &&
													!property.valueType.includes('System.Decimal')
												) {
													return Promise.reject(new Error('Минимум 3 символа'))
												}
											}
											if (!required && value && value.length > 0) {
												const validate = EstimatePositionHelpers.validateProperty(
													value,
													property.identityName,
													property.valueType
												)
												if (!validate.status) return Promise.reject(new Error(validate.message))
											}
											return Promise.resolve()
										}
									})
								]}
							>
								{property.valueType === 'System.Decimal' ? (
									<InputNumber
										decimalSeparator=","
										// formatter={value => {
										// 	const targetValue = value?.toString()?.replace(/\./g, ',')
										// 	if (targetValue?.includes(',')) {
										// 		const decimal = targetValue.split(',')
										// 		const parseValue =
										// 			decimal[0].length > 15
										// 				? decimal[0].substring(0, 14).concat(',', decimal[1])
										// 				: decimal[0].concat(',', decimal[1])
										// 		return parseValue
										// 	} else {
										// 		return targetValue!?.length > 15
										// 			? targetValue!?.substring(0, 14).replace(/,/, '.')
										// 			: targetValue!?.replace(/,/, '.')
										// 	}
										// }}
										// precision={ROUND_VALUE}
										disabled={
											property.isDeleted || disabled || !canEdit || property.isEditable === false
										}
										addonAfter={
											property.baseQuantity === true
												? EstimatePositionHelpers.getBaseQualityMeasureUnit()
												: property.measureUnitName
										}
										controls={false}
										keyboard={false}
										onBlur={e => {
											const roundType = currentTechnology?.properties.find(
												p => p.property.id === property.property.id
											)?.property.measureUnit?.isRounded
											const targetValue = e.currentTarget.value.replace(/,/, '.')
											setPropertyValue(targetValue)
											if (dto !== undefined && property.baseQuantity === false) {
												EstimatePositionHelpers.roundingValue(
													targetValue,
													property,
													property.property.isRounded,
													property.property.isRounded === false ? ROUND_VALUE : 0
												)
											} else if (dto !== undefined && property.baseQuantity === true) {
												EstimatePositionHelpers.roundingValue(
													targetValue,
													property,
													dto?.works?.find(elem => elem.id === workData![0].work.id)?.measureUnit
														?.isRounded!,
													dto?.works?.find(elem => elem.id === workData![0].work.id)?.measureUnit
														?.isRounded === false
														? ROUND_VALUE
														: 0
												)
											} else if (
												(currentTechnology?.properties.find(
													p => p.property.id === property.property.id
												)?.property.measureUnit
													? currentTechnology?.properties.find(
															p => p.property.id === property.property.id
													  )?.property.measureUnit?.isRounded === true
													: property.property.isRounded === true) &&
												!Number.isNaN(targetValue)
											) {
												EstimatePositionHelpers.roundingValue(targetValue, property, roundType!, 0)
											} else if (!Number.isNaN(targetValue))
												EstimatePositionHelpers.roundingValue(
													targetValue,
													property,
													roundType!,
													ROUND_VALUE
												)
										}}
										changeOnWheel={false}
										onKeyDown={event => {
											if (
												!/[0-9]|Backspace|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|\.|\,/.test(
													event.key
												)
											)
												event.preventDefault()
										}}
										style={{ width: '100%' }}
									/>
								) : property.valueType === 'System.Guid' ? (
									<EstimatePositionPropertiesGuid
										disabled={
											property.isDeleted || disabled || !canEdit || property.isEditable === false
										}
										property={property}
										callBack={value => setWorkPropertyValue(property.property.id, value)}
									/>
								) : (
									<Input
										disabled={
											property.isDeleted || disabled || !canEdit || property.isEditable === false
										}
										onBlur={e => setWorkPropertyValue(property.property.id, e.currentTarget.value)}
										addonAfter={
											property.baseQuantity === true
												? EstimatePositionHelpers.getBaseQualityMeasureUnit()
												: property.measureUnitName
										}
										min={0}
										maxLength={1024}
									/>
								)}
							</Form.Item>
						)
					})}
			</Card>
		</>
	) : null
}

import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { PAGE_LIMIT } from 'shared/constants'
import { useMonitoringCreatedFines } from '../api/monitoring-queries'
import { MonitoringCreatedFines } from '../api/monitoring-schema'
import { useMonitoringState } from '../model/monitoring-state'

export const CreatedFinesMonitoring: FC = () => {
	const { data, isFetching } = useMonitoringCreatedFines()
	const { totalItems, currentPage, onPagination } = useMonitoringState()
	const columns: ColumnsType<MonitoringCreatedFines> = [
		{
			dataIndex: 'ordinanceId',
			title: 'Id предписания',
			render: value => (
				<NavLink to={`/control/ordinance/${value}`} target="_blank">
					{value}
				</NavLink>
			)
		},
		{
			dataIndex: 'ordinanceName',
			title: 'Предписание'
		},
		{
			dataIndex: 'projectName',
			title: 'Проект'
		},
		{
			dataIndex: 'fineId',
			title: 'Id штрафа',
			render: value => (
				<NavLink to={`/control/fines/${value}`} target="_blank">
					{value}
				</NavLink>
			)
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Table
				loading={isFetching}
				className="app-table-types"
				rowKey={record => record.ordinanceId}
				size="small"
				pagination={{
					hideOnSinglePage: true,
					total: totalItems,
					pageSize: PAGE_LIMIT,
					showSizeChanger: false,
					onChange: onPagination,
					current: currentPage
				}}
				bordered
				dataSource={data}
				columns={columns as any}
			/>
		</div>
	)
}

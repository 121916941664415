import { IPagination } from 'interfaces/IPagination'
import { API } from 'services/ApiService'
import { PAGE_LIMIT } from 'shared/constants'
import { ILocalEstimate, ILocalEstimateRegistryFilters } from '..'

export const LocalEstimateRegistryApi = {
	async getRegistry(currentPage: number, filters?: ILocalEstimateRegistryFilters, sortState?: number) {
		return await API<IPagination<ILocalEstimate[]>>({
			url: '/v1/containerEstimates',
			method: 'get',
			params: { limit: PAGE_LIMIT, offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT, sortState, ...filters }
		}).then(response => response.data)
	},
	async saveContainer(container: ILocalEstimate) {},
	async createAdjustment(containerId: string, name: string) {
		return await API({
			url: `/v1/containerEstimates/${containerId}/correctionContainerEstimate`,
			method: 'post',
			data: { name }
		}).then(response => response.status)
	},
	// async copyContainer(containerId: string, formData: IWbsCopyForm) {
	// 	return await API({
	// 		url: `/v1/containers/${containerId}/copy`,
	// 		method: 'post',
	// 		params: { ...formData }
	// 	}).then(response => response.status)
	// },
	async removeContainer(containerId: string, adjustment: boolean) {
		return await API({
			url: `/v1/${adjustment ? 'adjustment' : 'containerEstimates'}/${containerId}`,
			method: 'delete'
		}).then(response => response.status)
	},
	async getContainerEstimateStatus() {
		return await API({
			url: `v1/containerEstimates/containerStatusForFilter`,
			method: 'get'
		}).then(response => response.data)
	},
	async createCorrectionSdu(containerId: string, name: string) {
		return await API({
			url: `/v1/adjustment/estimate/${containerId}/correctionSdu`,
			method: 'post',
			data: { name }
		}).then(response => response.status)
	},
	async createCorrectionContractor(containerId: string, name: string) {
		return await API({
			url: `/v1/adjustment/estimate/${containerId}/correctionContractor`,
			method: 'post',
			data: { name }
		}).then(response => response.status)
	},
	async importData(container: ILocalEstimate) {},
	async exportData(container: ILocalEstimate, withPrice: boolean) {}
}

import { Tabs, TabsProps } from 'antd'
import { FC } from 'react'
import {
	TChecklistAttachment,
	TChecklistIssues,
	TChecklistSectionItem
} from 'widgets/checklist-card/model/checklist-card-schema'
import { ChecklistSectionItemAttachmentsFiles } from './checklist-section-item-attachments-files'
import { ChecklistSectionItemAttachmentsIssues } from './checklist-section-item-attachments-issues'

type TProps = {
	item: TChecklistSectionItem
	issues: TChecklistIssues[]
	files: TChecklistAttachment[]
	isIssueRequired: boolean
	isFileRequired: boolean
	disabled: boolean
}

export const ChecklistSectionItemAttachments: FC<TProps> = ({
	disabled,
	item,
	issues,
	files,
	isIssueRequired,
	isFileRequired
}) => {
	const tabItems: TabsProps['items'] = [
		{
			key: 'files',
			label: (
				<div
					style={{ color: !files.length && isFileRequired ? '#ff4d4f' : '#52c41a' }}
				>{`Файлы: ${files.length}`}</div>
			),
			children: (
				<ChecklistSectionItemAttachmentsFiles item={item} files={files} disabled={disabled} />
			)
		},
		{
			key: 'issues',
			label: (
				<div
					style={{ color: !issues.length && isIssueRequired ? '#ff4d4f' : '#52c41a' }}
				>{`Замечания: ${issues.length}`}</div>
			),
			children: (
				<ChecklistSectionItemAttachmentsIssues
					itemId={item.id}
					issues={issues}
					disabled={disabled}
				/>
			)
		}
	]
	return <Tabs items={tabItems} tabBarGutter={16} type="line" />
}

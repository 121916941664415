import {
	ATTACHMENT_STATUS,
	ATTACHMENT_TYPE,
	SIGNATURE_TYPE
} from 'shared/constants/attachment-types'
import { CHECKLIST_STATUS, TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { baseSchema } from 'shared/schemas'
import { zodDay } from 'shared/schemas/base-schema'
import z from 'zod'

const checklistTemplateSchema = z.object({
	id: z.string().uuid(),
	title: z.string(),
	cipher: z.string().nullable()
})

const checklistProjectSchema = z.object({
	id: z.string(),
	name: z.string(),
	project1C: z.null(),
	sectionCount: z.number(),
	phase: z.string(),
	building: z.string(),
	isArchived: z.boolean(),
	printFormNameOrg: z.string(),
	responsibleManager: z.null()
})
const checklistAssignedToSchema = z.object({
	id: z.string().uuid(),
	title: z.string(),
	assignedToType: z.null(),
	isDeleted: z.boolean(),
	isCheckListsProcessing: z.boolean()
})

const checklistAttachment = z.object({
	id: z.string().uuid(),
	objectId: z.string().uuid(),
	name: z.string(),
	extension: z.string(),
	fileSize: z.number(),
	contentType: z.string(),
	description: z.string().nullable(),
	uploadDate: z.coerce.date(),
	url: z.string().url(),
	typeId: z.nativeEnum(ATTACHMENT_TYPE),
	statusId: z.nativeEnum(ATTACHMENT_STATUS),
	createdById: z.string().uuid(),
	createdBy: baseSchema,
	typeSign: z.nativeEnum(SIGNATURE_TYPE).nullable()
})

const checklistSectionItemIssuesSchema = z.object({
	id: z.string().uuid(),
	number: z.number(),
	title: z.string(),
	issueStatus: z.object({
		id: z.string(),
		identityName: z.string(),
		name: z.string()
	}),
	dueDate: z.coerce.date()
})

const checklistSectionItemSchema = z.object({
	id: z.string().uuid(),
	order: z.number(),
	name: z.string(),
	description: z.string().nullable(),
	documentation: z.string().nullish(),
	answer: z.number().nullable(),
	checkboxUk: z.boolean(),
	checkboxCritical: z.boolean(),
	checkboxFile: z.boolean(),
	checkboxIssue: z.boolean(),
	checkListSectionId: z.string().uuid(),
	checkListId: z.string().uuid().nullable(),
	issues: z.array(checklistSectionItemIssuesSchema),
	attachments: z.array(checklistAttachment)
})

const checklistSectionSchema = z.object({
	id: z.string().uuid(),
	checkListId: z.string().uuid(),
	title: z.string(),
	description: z.string(),
	order: z.number(),
	items: z.array(checklistSectionItemSchema)
})

const checklistStageSchema = z.object({
	id: z.string().uuid(),
	checkListId: z.string().uuid(),
	isDeleted: z.boolean(),
	order: z.number(),
	percent: z.number(),
	sections: z.array(checklistSectionSchema),
	title: z.string()
})

const potentialAssignedUsersSchema = z.object({
	id: z.string().uuid(),
	fullName: z.string()
})

const checklistSchema = z.object({
	id: z.string().uuid(),
	assignedByElimination: z.string().nullable(),
	assignedTo: z.array(checklistAssignedToSchema).nullable(),
	attachments: z.array(checklistAttachment),
	bySection: z.boolean(),
	capacity: z.string().nullish(),
	checkboxUk: z.boolean(),
	checkListCipher: z.string().nullish(),
	checkListTemplate: checklistTemplateSchema.nullable(),
	commissioningOrganization: baseSchema.nullable(),
	contractor: baseSchema.nullable(),
	createdAt: z.coerce.date(),
	createdBy: z.string(),
	description: z.string(),
	doorSections: z.array(z.number()),
	factDate: z.coerce.date().nullable(),
	isMobile: z.boolean(),
	issuesClosed: z.number(),
	issuesOpen: z.number(),
	level: z.number(),
	location: z.string().nullable(),
	number: z.number(),
	owner: baseSchema.nullable(),
	period: z.coerce.date(),
	plannedDate: z.coerce.date().nullable(),
	potentialAssignedUsers: z.array(potentialAssignedUsersSchema),
	project: checklistProjectSchema.nullable(),
	quarantyPeriod: z.coerce.date(),
	sectionCount: z.number(),
	sections: z.array(checklistSectionSchema).optional(),
	stages: z.array(checklistStageSchema).optional(),
	selectedDoorSections: z.array(z.number()),
	status: z.nativeEnum(CHECKLIST_STATUS),
	subContractor: baseSchema.nullable(),
	templateType: z.nativeEnum(TEMPLATE_TYPE),
	title: z.string(),
	updatedAt: z.coerce.date(),
	workPackage: baseSchema.nullable(),
	workPackageId: z.string().uuid().nullish()
})

export const checklistFormSchema = z.object({
	assignedByElimination: z.string().nullish(),
	assignedTo: z.array(z.string().uuid()),
	capacity: z.string().nullish(),
	checkListCipher: z.string().nullish(),
	commissioningOrganizationId: z.string().optional(),
	contractorId: z.string().uuid().optional(),
	description: z.string(),
	doorSections: z.array(z.number()).optional(),
	factDate: zodDay.nullable(),
	location: z.string().nullish(),
	ownerId: z.string().uuid().optional(),
	plannedDate: zodDay.nullable(),
	quarantyPeriod: z.coerce.date().optional(),
	status: z.nativeEnum(CHECKLIST_STATUS),
	subContractorId: z.string().uuid().optional(),
	title: z.string(),
	workPackageId: z.string().uuid().nullish()
})

const checklistUpdateDto = checklistFormSchema.extend({
	factDate: z.coerce.date().nullable(),
	plannedDate: z.coerce.date().nullable()
})

const checklistCreateDto = checklistUpdateDto.extend({
	checkListTemplateId: z.string().uuid(),
	potentialAssignedUsersId: z.array(z.string().uuid())
})

const checklistPrepareSchema = z.object({
	templateType: z.nativeEnum(TEMPLATE_TYPE),
	templateId: z.string(),
	checklistId: z.string().optional()
})

export type TChecklist = z.infer<typeof checklistSchema>
export type TChecklistForm = z.infer<typeof checklistFormSchema>
export type TChecklistSectionItem = z.infer<typeof checklistSectionItemSchema>
export type TChecklistStage = z.infer<typeof checklistStageSchema>
export type TChecklistSection = z.infer<typeof checklistSectionSchema>
export type TChecklistIssues = z.infer<typeof checklistSectionItemIssuesSchema>
export type TChecklistAttachment = z.infer<typeof checklistAttachment>
export type TChecklistCreateDto = z.infer<typeof checklistCreateDto>
export type TChecklistUpdateDto = z.infer<typeof checklistUpdateDto>
export type TChecklistPrepare = z.infer<typeof checklistPrepareSchema>

import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useMonitoringNonEliminatedOrdinances } from '../api/monitoring-queries'
import { MonitoringNonEliminatedOrdinances } from '../api/monitoring-schema'

export const NonEliminatedOrdinancesMonitoring: FC = () => {
	const { data, isFetching } = useMonitoringNonEliminatedOrdinances()
	const columns: ColumnsType<MonitoringNonEliminatedOrdinances> = [
		{
			dataIndex: 'ordinanceName',
			title: 'Номер предписания'
		},
		{
			dataIndex: 'ordinanceId',
			title: 'Id предписания',
			render: value => (
				<NavLink to={`/control/ordinance/${value}`} target="_blank">
					{value}
				</NavLink>
			)
		},
		{
			dataIndex: 'ordinanceStatus',
			title: 'Статус предписания'
		},
		{
			dataIndex: 'projectName',
			title: 'Проект'
		},
		{
			dataIndex: 'projectId',
			title: 'Id проекта'
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Table
				loading={isFetching}
				className="app-table-types"
				rowKey={record => record.ordinanceId}
				size="small"
				pagination={false}
				bordered
				dataSource={data}
				columns={columns as any}
			/>
		</div>
	)
}

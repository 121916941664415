import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, notification, Upload, UploadFile, UploadProps } from 'antd'
import { RcFile } from 'antd/es/upload'
import { FC, useState } from 'react'
import { useChecklistUnderWarrantyMutation } from '../api/checklist-card-queries'
import { useChecklistCardState } from '../model/checklist-card-state'

type TProps = {
	show: boolean
	onCancel: () => void
}

type TWarrantyDto = {
	description: string
	file: UploadFile
}

export const ChecklistWarrantyModal: FC<TProps> = ({ show, onCancel }) => {
	const currentChecklistId = useChecklistCardState(state => state.currentId)
	const [fileList, setFileList] = useState<UploadFile[]>([])
	const [warrantyForm] = Form.useForm<TWarrantyDto>()
	const { mutate, isLoading } = useChecklistUnderWarrantyMutation()

	const onUpload = (values: TWarrantyDto) => {
		if (currentChecklistId && !!fileList.length) {
			const fmData = new FormData()
			fmData.append('file', fileList[0] as RcFile)
			fmData.append('description', values.description)
			mutate(
				{ id: currentChecklistId, data: fmData },
				{
					onSuccess: () => {
						onClose()
					}
				}
			)
		}
	}

	const props: UploadProps = {
		accept: 'application/pdf',
		fileList,
		maxCount: 1,
		onRemove: file => {
			setFileList([])
		},
		beforeUpload: file => {
			const canUpload = file.type === 'application/pdf'
			if (!canUpload) {
				notification.error({
					message: 'Ошибка',
					description: `${file.name} - тип файла не поддерживается`,
					placement: 'topLeft'
				})
			} else {
				setFileList([file])
			}
			return canUpload ? false : Upload.LIST_IGNORE
		}
	}

	const onClose = () => {
		if (!isLoading) {
			setFileList([])
			warrantyForm.resetFields()
			onCancel()
		}
	}

	return (
		<Modal
			confirmLoading={isLoading}
			centered
			onCancel={onClose}
			cancelButtonProps={{ disabled: isLoading }}
			open={show}
			title="Загрузите копию обязательства и описание"
			cancelText="Закрыть"
			destroyOnClose={true}
			okText="Загрузить"
			onOk={() => warrantyForm.submit()}
		>
			<Form name="warrantyForm" form={warrantyForm} onFinish={onUpload}>
				<Form.Item
					name="file"
					rules={[
						{
							validator() {
								if (fileList.length === 1) {
									return Promise.resolve()
								}
								return Promise.reject(new Error('Необходимо прикрепить копию обязательства'))
							}
						}
					]}
				>
					<Upload {...props}>
						<Button disabled={fileList.length > 0} icon={<UploadOutlined />}>
							Прикрепите PDF файл
						</Button>
					</Upload>
				</Form.Item>
				<Form.Item
					name="description"
					rules={[
						{
							required: true,
							message: 'Необходимо заполнить описание'
						}
					]}
				>
					<Input.TextArea
						placeholder="Введите описание"
						allowClear
						autoSize={{
							minRows: 4
						}}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

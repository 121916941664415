import { useQueryClient } from '@tanstack/react-query'
import { message, Modal, notification, Space } from 'antd'
import { AxiosError } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAppUserPermissions } from 'services/AuthService'
import {
	getContractors,
	getContractsByProject1CId,
	getIssueSubTypes,
	getIssueTypes
} from 'services/IssuesService'
import { getBuildingPermitsList } from 'services/OrdinanceService'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { TEstimateType } from 'shared/schema'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { resetAsm } from 'store/asmSlice'
import { resetCheckListsState } from 'store/checklistsSlice'
import {
	setEnvironment,
	setEnvironmentPermissions,
	setEnvironmentProject
} from 'store/environmentSlice'
import { resetIssuesState } from 'store/issueSlice'
import { resetOrdinancesState } from 'store/ordinanceSlice'
import { resetTemplatesState } from 'store/templatesSlice'
import { resetTrafficLight } from 'store/trafficLightSlice'
import { IErrorData } from 'UI/dashboard/EnvironmentConfig'
import { adjustmentApi, useAdjustmentQuery } from 'widgets/adjustment'
import { AdjustmentComments } from 'widgets/adjustment-comments'
import { AdjustmentPositionCommentsView } from 'widgets/adjustment-position-comments'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { AdjustmentHeader } from 'widgets/adjustment/ui/AdjustmentHeader'
import { AdjustmentHistory } from 'widgets/adjustment/ui/AdjustmentHistory'
import { AdjustmentNote } from 'widgets/adjustment/ui/AdjustmentNote'
import { AdjustmentSections } from 'widgets/adjustment/ui/AdjustmentSections'
import { EstimateRelatedDocuments } from 'widgets/estimate-creation-from-vor/ui/EstimateRelatedDocuments'
import { EstimatePosition } from 'widgets/estimate-position-card'
import { EstimateRemoveModal } from 'widgets/estimate/ui/EstimateRemoveModal'
import { RegulatoryRequest } from 'widgets/regulatory-request'
import { WbsStatusModal } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'

interface IProps {
	type: TEstimateType
}

export const AdjustmentPage: FC<IProps> = ({ type }) => {
	const { data, isSuccess } = useAdjustmentQuery(type)

	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { project, user } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const { id } = useParams()
	const { container } = useAdjustmentState(
		useShallow(state => ({
			container: state.container
		}))
	)
	const checkPermissions = useCheckPermissions()
	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const changeCurrentProject = async (projectId: string) => {
		const userProject = user?.projects?.find(p => p.id === projectId)
		dispatch(resetIssuesState())
		dispatch(resetOrdinancesState())
		dispatch(resetCheckListsState())
		dispatch(resetTemplatesState())
		dispatch(resetAsm())
		dispatch(resetTrafficLight())

		await getAppUserPermissions(userProject?.id ?? '').then(data => {
			dispatch(setEnvironmentPermissions(data))
		})

		dispatch(
			setEnvironmentProject({
				option: 'project',
				data: userProject!
			})
		)
		await getContractors().then(data => {
			dispatch(setEnvironment({ state: 'contractors', data }))
		})
		await getIssueTypes()
			.then(data => {
				dispatch(setEnvironment({ state: 'issueTypes', data }))
			})
			.catch((error: AxiosError) => {
				const { detail } = error!?.response!?.data! as IErrorData
				const { url } = error!?.config!
				message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
				return Promise.reject
			})
		await getIssueSubTypes()
			.then(data => {
				dispatch(setEnvironment({ state: 'issueSubTypes', data }))
			})
			.catch((error: AxiosError) => {
				const { detail } = error!?.response!?.data! as IErrorData
				const { url } = error!?.config!
				message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
				return Promise.reject
			})
		await getContractsByProject1CId(userProject?.project1C?.id!).then(data => {
			dispatch(setEnvironment({ state: 'contracts', data }))
		})
		await getBuildingPermitsList(userProject!?.project1C?.id!).then(data => {
			dispatch(setEnvironment({ state: 'buildingPermits', data }))
		})
		queryClient.invalidateQueries({ queryKey: ['issueAnalytics'] })
		queryClient.invalidateQueries({ queryKey: ['ordinanceAnalytics'] })
		queryClient.invalidateQueries({ queryKey: ['violationTypesAnalytics'] })
		queryClient.invalidateQueries({ queryKey: ['useMyFilesAnalytics'] })
	}

	useEffect(() => {
		if (!(data instanceof AxiosError) && data && data?.projectId !== project?.id) {
			Modal.confirm({
				title: 'Внимание',
				content:
					'Проект ВОР отличается от текущего. Для продолжения работы, требуется сменить текущий проект.',
				okText: 'Сменить',
				onOk: async () => changeCurrentProject(data.projectId),
				onCancel: () => navigate('/unmodel')
			})
		}
	}, [data])

	useEffect(() => {
		if (
			(data?.status?.name === CONTAINER_STATUS.new ||
				data?.status?.name === CONTAINER_STATUS.preparation) &&
			data?.isActual === false &&
			data?.ready === false &&
			data.projectId === project.id
		) {
			if (checkPermissions([PERMISSIONS.AdjustmentCreate]) && isAuthor) {
				Modal.confirm({
					title:
						'Структура Было-Стало неактуальна. Для продолжения работы с документом необходимо актуализировать данные',
					okText: 'Обновить',
					cancelText: 'Отмена',
					onCancel: () => {
						navigate(`/${type === 'wbs' ? 'unmodel' : 'local'}`)
					},
					onOk: () => {
						adjustmentApi
							.actualize(id!, type)
							.then(() => {
								queryClient.invalidateQueries({ queryKey: ['adjustmentContainer'] })
								queryClient.invalidateQueries({ queryKey: ['adjustment'] })
							})
							.catch(error =>
								notification.error({
									message: 'Произошла ошибка',
									description: error?.data?.detail,
									placement: 'topLeft'
								})
							)
					}
				})
			} else {
				Modal.info({
					title:
						'Структура Было/стало неактуальна. Для обновления структуры обратитесь к администратору или автору/редактору Было-Стало',
					okText: 'Закрыть',
					onOk: () => {
						navigate(`/${type === 'wbs' ? 'unmodel' : 'local'}`)
					}
				})
			}
		}
	}, [data])

	return (
		<>
			<WbsStatusModal type={type} isAdjustment={true} />
			<EstimateRemoveModal type={type} isAdjustment={true} />
			<EstimatePosition disabled={false} type={type} isAdjustment={true} />
			<EstimateRelatedDocuments type={type} />
			<AdjustmentHistory type={type} />
			<AdjustmentPositionCommentsView type={type} />
			<AdjustmentComments type={type} />
			<AdjustmentNote />
			<RegulatoryRequest isAdjustment={true} />
			<Space direction="vertical">
				<AdjustmentHeader type={type} />
				<AdjustmentSections type={type} />
			</Space>
		</>
	)
}

import { useQuery } from "@tanstack/react-query"
import dayjs from 'dayjs'
import { useAppSelector } from "hooks/appReduxHook"
import { DATE_FORMAT } from "shared/constants"
import { useMonitoringState } from "../model/monitoring-state"
import { MonitoringApi } from "./monitoring-api"

export const useMonitoringContractsWithFines = () => {
	return useQuery({
		queryKey: ['contractsWithFines'],
		queryFn: async () => {
				return await MonitoringApi.getContractsWithFines().then(data => data)
		},
		select: data => data,
		refetchOnWindowFocus: false
	})
}

export const useMonitoringUndeliveredNonEliminationActs = () => {
    const { project } = useAppSelector(state => state.environment)
    const { currentPage, setTotalItems } = useMonitoringState()
	return useQuery({
		queryKey: ['undeliveredNonEliminationActs', currentPage, project],
		queryFn: async () => {
			const data =  await MonitoringApi.getUndeliveredNonEliminationActs(currentPage).then(data => data)
            setTotalItems(data.totalItems)
				return data.data
		},
		refetchOnWindowFocus: false
	})
}

export const useMonitoringUndeliveredOrdinances = () => {
    const { project } = useAppSelector(state => state.environment)
    const { currentPage, setTotalItems } = useMonitoringState()
	return useQuery({
		queryKey: ['undeliveredOrdinances', currentPage, project],
		queryFn: async () => {
			const data =  await MonitoringApi.getUndeliveredOrdinances(currentPage).then(data => data)
            setTotalItems(data.totalItems)
				return data.data
		},
		refetchOnWindowFocus: false
	})
}

export const useMonitoringFilesStatuses = () => {
    const { project } = useAppSelector(state => state.environment)
    const { currentPage, setTotalItems } = useMonitoringState()
	return useQuery({
		queryKey: ['filesStatuses', currentPage, project],
		queryFn: async () => {
			const data =  await MonitoringApi.getFilesStatuses(currentPage).then(data => data)
            setTotalItems(data.totalItems)
				return data.data
		},
		refetchOnWindowFocus: false
	})
}

export const useMonitoringUndeliveredEliminationActs = () => {
    const { project } = useAppSelector(state => state.environment)
    const { currentPage, setTotalItems } = useMonitoringState()
	return useQuery({
		queryKey: ['undeliveredEliminationActs', currentPage, project],
		queryFn: async () => {
			const data =  await MonitoringApi.getUndeliveredEliminationActs(currentPage).then(data => data)
            setTotalItems(data.totalItems)
				return data.data
		},
		refetchOnWindowFocus: false
	})
}

export const useMonitoringNonEliminatedOrdinances = () => {
	return useQuery({
		queryKey: ['nonEliminatedOrdinances'],
		queryFn: async () => {
				return await MonitoringApi.getNonEliminatedOrdinances().then(data => data)
		},
		select: data => data,
		refetchOnWindowFocus: false
	})
}

export const useMonitoringAutoOrdinances = () => {
    const { monitoringFilters } = useMonitoringState()
	return useQuery({
		queryKey: ['autoOrdinances', monitoringFilters],
		queryFn: async () => {
				return await MonitoringApi.getAutoOrdinances(dayjs(monitoringFilters?.date!).isValid() ? dayjs(monitoringFilters?.date!).format(DATE_FORMAT.dto) : null).then(data => data)
		},
		select: data => data,
		refetchOnWindowFocus: false
	})
}

export const useMonitoringAutoOrdinancesNotApproved = () => {
	return useQuery({
		queryKey: ['autoOrdinancesNotApproved'],
		queryFn: async () => {
				return await MonitoringApi.getAutoOrdinancesNotApproved().then(data => data)
		},
		select: data => data,
		refetchOnWindowFocus: false
	})
}

export const useMonitoringCheckListDueDateNotifications = () => {
    const { project } = useAppSelector(state => state.environment)
    const { currentPage, setTotalItems } = useMonitoringState()
	return useQuery({
		queryKey: ['checkListDueDateNotifications', currentPage, project],
		queryFn: async () => {
			const data =  await MonitoringApi.getCheckListDueDateNotifications(currentPage).then(data => data)
            setTotalItems(data.totalItems)
				return data.data
		},
		refetchOnWindowFocus: false
	})
}

export const useMonitoringCreatedFines = () => {
    const { project } = useAppSelector(state => state.environment)
    const { currentPage, monitoringFilters, setTotalItems } = useMonitoringState()
	return useQuery({
		queryKey: ['createdFines', currentPage, project, monitoringFilters],
		queryFn: async () => {
			const data =  await MonitoringApi.getCreatedFines(currentPage, {...monitoringFilters, date: dayjs(monitoringFilters?.date!).isValid() ? dayjs(monitoringFilters?.date!).format(DATE_FORMAT.dto) : null}!).then(data => data)
            setTotalItems(data.totalItems)
				return data.data
		},
		refetchOnWindowFocus: false
	})
}
import {
	Flex,
	Form,
	GetProp,
	Image,
	message,
	notification,
	Typography,
	Upload,
	UploadFile,
	UploadProps
} from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { FormInstance } from 'antd/lib'
import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { FC, useState } from 'react'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import { TWbsTaskAttachment } from 'widgets/wbs-task-registry/model/wbs-task-registry-interface'

const { Text } = Typography
interface IProps {
	form: FormInstance<TWbsTaskForm>
	attachments: TWbsTaskAttachment[]
	filesToUpload: UploadFile[]
	setFilesToUpload: (x: UploadFile[]) => void
	canEdit: boolean
	status: string
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})

export const format = [
	'doc',
	'docx',
	'xls',
	'xlsx',
	'ppt',
	'pptx',
	'odt',
	'odf',
	'ods',
	'odp',
	'txt',
	'pdf',
	// 'htm',
	// 'html',
	'bmp',
	'gif',
	'png',
	'jpeg',
	'dib',
	'rle',
	'tif',
	'jpg',
	'ico',
	'wmf',
	'emf'
]

export const WbsTaskAttachmentsUploader: FC<IProps> = ({
	form,
	attachments,
	filesToUpload,
	canEdit,
	status,
	setFilesToUpload
}) => {
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [filesLimit, setFilesLimit] = useState(0)
	const attachmentFiles = attachments?.flatMap(a => a.files)

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		const totalAttachments = attachmentFiles.length + newFileList.length
		const newFileListSize = newFileList.reduce((sum, a) => sum + a.size!, 0)
		const attachmentsSize = attachmentFiles.reduce((sum, a) => sum + a.fileSize!, 0)
		const size = (newFileListSize + attachmentsSize) * 0.000001
		setFilesLimit(totalAttachments)
		if (size > 40) {
			notification.error({
				message: 'Общий размер файлов превышает 40 Мб',
				placement: 'topLeft'
			})
			form.validateFields(['attachments']).catch(() => setFilesLimit(0))
		} else if (totalAttachments > 20) {
			form.validateFields(['attachments']).catch(() => setFilesLimit(0))
		} else {
			// setUploadList(newFileList)
			setFilesToUpload(newFileList)
			form.resetFields(['attachments'])
		}
	}

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as FileType)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
	}

	return (
		<Form.Item
			name="attachments"
			rules={[
				() => ({
					validator(_) {
						if (filesLimit > 20) {
							return Promise.reject(
								new Error(
									'Превышен лимит загрузки файлов. Необходимо изменить количество загружаемых файлов или удалить неактуальные файлы'
								)
							)
						}
						return Promise.resolve()
					}
				})
			]}
		>
			<Dragger
				openFileDialogOnClick={20 - (attachmentFiles.length + filesToUpload.length) > 0}
				disabled={!canEdit || status === WBS_TASK_STATUS.done}
				accept="image/*,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.odf,.ods,.odp,.txt,.pdf,.bmp,.gif,.png,.jpeg,.dib,.rle,.tif,.jpg,.ico,.wmf,.emf" /* .htm,.html, */
				multiple={true}
				listType="picture-card"
				fileList={filesToUpload}
				onChange={handleChange}
				onPreview={handlePreview}
				beforeUpload={file => {
					const canUpload =
						format.some(f => file.name.includes(f)) ||
						file.type === 'image/jpeg' ||
						file.type === 'image/jpg' ||
						file.type === 'image/png' ||
						file.type === 'application/pdf'
					if (!canUpload) {
						message.error(`${file.name} - тип файла не поддерживается`)
					}
					return canUpload ? false : Upload.LIST_IGNORE
				}}
				style={{
					marginBottom: '1rem',
					cursor: !(attachmentFiles.length + filesToUpload.length >= 20) ? 'pointer' : 'not-allowed'
				}}
			>
				<Flex vertical>
					<Text>Добавить файлы для загрузки</Text>
					<Text type="secondary">
						<span>Доступно:</span>
						<span
							style={{
								color: 20 - (attachmentFiles.length + filesToUpload.length) < 0 ? 'red' : 'inherit',
								marginLeft: 5
							}}
						>
							{20 - (attachmentFiles.length + filesToUpload.length)}/20
						</span>
					</Text>
				</Flex>
			</Dragger>
			{previewImage && (
				<Image
					wrapperStyle={{ display: 'none' }}
					preview={{
						visible: previewOpen,
						onVisibleChange: visible => setPreviewOpen(visible),
						afterOpenChange: visible => !visible && setPreviewImage('')
					}}
					src={previewImage}
				/>
			)}
		</Form.Item>
	)
}

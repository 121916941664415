import { Form, Input, Modal } from 'antd'
import { FC, useEffect } from 'react'
import { useChecklistDocLinkMutation } from '../api/checklist-card-queries'

interface IProps {
	openModal: boolean
	itemId: string
	value?: string | null
	onClose: () => void
}

export const ChecklistCardDocumentationLinkModal: FC<IProps> = props => {
	const { openModal, itemId, value, onClose } = props
	const [documentationForm] = Form.useForm()
	const { mutate, isLoading } = useChecklistDocLinkMutation()

	useEffect(() => {
		documentationForm.setFieldValue('documentation', value ? value : 'https://')
	}, [openModal])

	const addLink = async () => {
		const documentation = documentationForm.getFieldValue('documentation').trim()
		mutate({ itemId, documentation }, { onSuccess: () => onClose() })
	}

	return (
		<Modal
			confirmLoading={isLoading}
			title="Ссылка на документацию"
			open={openModal}
			destroyOnClose={true}
			onOk={async () => documentationForm.submit()}
			okText={value ? 'Обновить' : 'Добавить'}
			onCancel={() => onClose()}
			cancelText="Закрыть"
		>
			<p>
				Введите ссылку на документацию (должна содержать workspace.google.com, sarex.io или
				docs.google.com)
			</p>
			<Form name="documentationForm" form={documentationForm} onFinish={addLink}>
				<Form.Item
					name="documentation"
					rules={[
						() => ({
							validator(_, value) {
								if (value?.length > 200) {
									return Promise.reject(new Error('Максимальная длина 200 символов'))
								}
								if (
									!value.trim()?.includes('workspace.google.com') &&
									!value.trim()?.includes('sarex.io') &&
									!value.trim()?.includes('docs.google.com') &&
									value.length > 0
								) {
									return Promise.reject(new Error('Некорректная ссылка'))
								}
								return Promise.resolve()
							}
						})
					]}
				>
					<Input.TextArea
						placeholder={
							'Введите ссылку на документацию (должна содержать workspace.google.com, sarex.io или docs.google.com)'
						}
						showCount
						maxLength={200}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

import { CheckSquareFilled, CloseSquareFilled } from '@ant-design/icons'
import { Button, Modal, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IOrdinanceAttachment } from 'interfaces/IOrdinance'
import { FC } from 'react'

interface IProps {
	setOpenModalDocument: (openModalDocument: boolean) => void
	openModalDocument: boolean
	attachments?: IOrdinanceAttachment[]
}

export const ModalDocument: FC<IProps> = props => {
	const { openModalDocument, setOpenModalDocument, attachments } = props
	const ordinanceAttachments = attachments?.filter(a => a.typeId === 1)
	const nonEliminationActAttachments = attachments?.filter(a => a.typeId === 3)
	const eliminationActAttachments = attachments?.filter(a => a.typeId === 2)
	const documentName = [
		{
			key: '1',
			icon:
				!ordinanceAttachments?.every(a => a.statusId === 4) ||
				ordinanceAttachments?.length === 0 ? (
					<CloseSquareFilled style={{ color: 'red' }} />
				) : (
					<CheckSquareFilled style={{ color: 'green' }} />
				),
			name: 'Предписание',
			description:
				ordinanceAttachments?.length === 0
					? 'Файл не загружен'
					: ordinanceAttachments?.every(a => a.statusId === 4)
					? 'Подписан'
					: 'Не подписан'
		},
		{
			key: '2',
			icon:
				!nonEliminationActAttachments?.every(a => a.statusId === 4) ||
				nonEliminationActAttachments?.length === 0 ? (
					<CloseSquareFilled style={{ color: 'red' }} />
				) : (
					<CheckSquareFilled style={{ color: 'green' }} />
				),
			name: 'Акт о неустранении',
			description:
				nonEliminationActAttachments?.length === 0
					? 'Файл не загружен'
					: nonEliminationActAttachments?.every(a => a.statusId === 4)
					? 'Подписан'
					: 'Не подписан'
		},
		{
			key: '3',
			icon:
				!eliminationActAttachments?.every(a => a.statusId === 4) ||
				eliminationActAttachments?.length === 0 ? (
					<CloseSquareFilled style={{ color: 'red' }} />
				) : (
					<CheckSquareFilled style={{ color: 'green' }} />
				),
			name: 'Акт об устранении',
			description:
				eliminationActAttachments?.length === 0
					? 'Файл не загружен'
					: eliminationActAttachments?.every(a => a.statusId === 4)
					? 'Подписан'
					: 'Не подписан'
		}
	]
	const columns: ColumnsType<any> = [
		{
			dataIndex: 'icon',
			key: 'icon',
			width: 20
		},
		{
			dataIndex: 'name',
			key: 'name',
			width: 80
		},
		{
			dataIndex: 'description',
			key: 'description',
			width: 120
		}
	]

	return (
		<Modal
			destroyOnClose
			centered
			onCancel={() => setOpenModalDocument(false)}
			open={openModalDocument}
			title="Документы"
			footer={
				<Button type="primary" onClick={() => setOpenModalDocument(false)}>
					ОК
				</Button>
			}
		>
			<Table
				className="ordinanceModalDocument"
				rowKey={(record, index) => index!}
				pagination={false}
				columns={columns}
				dataSource={documentName ?? []}
			/>
		</Modal>
	)
}

import { Form, Select, Skeleton, Tag } from 'antd'
import { FormInstance, Rule } from 'antd/es/form'
import { DefaultOptionType } from 'antd/es/select'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'

interface IProps {
	canEdit: boolean
	disabled?: boolean
	options?: DefaultOptionType[]
	dataSourceValue?: string | string[] | number[] | null
	name: string
	label: string
	rules?: Rule[]
	isLoading: boolean
	error?: any
	form: FormInstance
	fieldNames?: {
		label: string
		value: string
	}
	absenceLabel?: string
	isMultiply?: boolean
	isNumber?: boolean
}
export const AbsFormSelect: FC<IProps> = props => {
	const {
		canEdit,
		disabled,
		options,
		name,
		label,
		dataSourceValue,
		rules,
		isLoading,
		error,
		form,
		fieldNames = { label: 'name', value: 'id' },
		absenceLabel = 'Нет соответствия',
		isMultiply = false,
		isNumber = false
	} = props

	const [hasAbsence, setHasAbsence] = useState<string[]>([])
	const watchField = Form.useWatch(name, form)

	useEffect(() => {
		if (canEdit) {
			if (options?.length === 1 && !isNumber) form.setFieldValue(name, options[0].id)
			if (options && Array.isArray(watchField)) {
				setHasAbsence(
					watchField.filter(
						item => typeof item !== 'number' && !options?.flatMap(({ id }) => id).includes(item)
					)
				)
			}
			if (
				options &&
				!Array.isArray(watchField) &&
				typeof watchField !== 'undefined' &&
				!options.flatMap(({ id }) => id).includes(watchField)
			) {
				form.resetFields([name])
				form.validateFields([name])
			}
		}
	}, [options, watchField])

	const clearAbsence = () => {
		if (Array.isArray(watchField)) {
			const newArray = (watchField as string[]).filter(x => !hasAbsence.includes(x))
			form.setFieldValue(name, newArray)
		}
	}

	const absenceRule: Rule = ({ getFieldValue }) => ({
		validator() {
			if (hasAbsence.length) {
				return Promise.reject(new Error('Недопустимое значение'))
			}
			return Promise.resolve()
		}
	})

	useEffect(() => {
		form.validateFields([name])
	}, [hasAbsence])

	return canEdit ? (
		<Form.Item label={label} name={name} rules={rules ? [...rules, absenceRule] : [absenceRule]}>
			{isLoading ? (
				<Skeleton.Button block active />
			) : error instanceof AxiosError ? (
				<span className="app-form-text danger">
					{error.response?.status === 403
						? 'У вас недостаточно прав для редактирования этого поля'
						: 'Ошибка получения данных'}
				</span>
			) : (
				<Select
					status={hasAbsence.length ? 'error' : undefined}
					mode={isMultiply ? 'multiple' : undefined}
					showSearch={true}
					optionFilterProp={'children'}
					filterOption={(input, option) =>
						(option?.[fieldNames.label] ?? '').toLowerCase().includes(input.toLowerCase())
					}
					filterSort={(optionA, optionB) =>
						name !== 'workPackageId' &&
						(optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
					}
					loading={isLoading}
					disabled={disabled || options?.length === 0}
					options={options}
					fieldNames={fieldNames}
					allowClear
					style={{ borderRadius: 0, paddingLeft: Array.isArray(dataSourceValue) ? 8 : 0 }}
					variant={hasAbsence.length ? 'outlined' : 'borderless'}
					tagRender={props => (
						<Tag
							onClose={clearAbsence}
							closable={!!hasAbsence.length}
							color={hasAbsence.length ? 'red' : 'blue'}
							style={{ fontSize: 14, padding: '4px 8px' }}
						>
							{hasAbsence.length ? absenceLabel : props.label}
						</Tag>
					)}
					maxTagCount={hasAbsence.length ? 0 : 'responsive'}
				/>
			)}
		</Form.Item>
	) : (
		<Form.Item label={label}>
			{dataSourceValue ? (
				Array.isArray(dataSourceValue) ? (
					<Select
						mode={'multiple'}
						showSearch={false}
						optionFilterProp={'children'}
						loading={isLoading}
						value={dataSourceValue}
						style={{ borderRadius: 0, paddingLeft: 8 }}
						fieldNames={fieldNames}
						open={false}
						options={options}
						variant={hasAbsence.length ? 'outlined' : 'borderless'}
						tagRender={props => (
							<Tag
								color={isNumber || options?.some(x => x.fullName === props.value) ? 'blue' : 'red'}
								style={{ fontSize: 14, padding: '4px 8px' }}
							>
								{isNumber || options?.some(x => x.fullName === props.value)
									? props.label
									: absenceLabel}
							</Tag>
						)}
					/>
				) : (
					<span className="app-form-text">{dataSourceValue}</span>
				)
			) : (
				<span className="app-form-text">-</span>
			)}
		</Form.Item>
	)
}

import { Card, Descriptions, FormInstance } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { DefaultOptionType } from 'antd/es/select'
import dayjs from 'dayjs'
import { AbsFormDatePicker, AbsFormInput, AbsFormSelect, AbsFormView } from 'features/abs-form'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { DATE_FORMAT } from 'shared/constants'
import { CHECKLIST_STATUS, TEMPLATE_TYPE, TEMPLATE_TYPES } from 'shared/constants/checklist-types'
import { YandexTrackerButton } from 'widgets/yandex-tracker/ui/yandex-tracker-button'
import {
	useChecklistCommissioning,
	useChecklistContractors,
	useChecklistResponsibleUsers,
	useChecklistSubContractors
} from '../api/checklist-card-queries'
import { useCanChecklistEdit } from '../model/checklist-card-helper'
import { TChecklist, TChecklistForm } from '../model/checklist-card-schema'
import { useChecklistCardState } from '../model/checklist-card-state'
import { TransferAct } from './transfer-act'
import { useWorkPackages } from 'shared/api/shared-queries'

type TProps = {
	isLoading: boolean
	disabled: boolean
	dataSource?: TChecklist | null
	form: FormInstance<TChecklistForm>
	formValues: TChecklistForm
}

export const ChecklistCardDetailsMain: FC<TProps> = ({
	isLoading,
	disabled,
	dataSource,
	form,
	formValues
}) => {
	const canChecklistEdit = useCanChecklistEdit()
	const currentUser = useAppSelector(state => state.environment.user)
	const isNew = !!useChecklistCardState(state => state.prepare)

	const { data: workPackages, isFetching: workPackagesFetching } = useWorkPackages()

	const {
		data: responsibleUsers,
		isFetching: responsibleUsersFetching,
		error: responsibleUsersError
	} = useChecklistResponsibleUsers(dataSource?.templateType)

	const {
		data: contractors,
		isFetching: contractorsFetching,
		error: contractorsError
	} = useChecklistContractors(formValues?.contractorId)

	const {
		data: subContractors,
		isFetching: subContractorsFetching,
		error: subContractorsError
	} = useChecklistSubContractors(formValues?.contractorId)

	const {
		data: commissioning,
		isFetching: commissioningFetching,
		error: commissioningError
	} = useChecklistCommissioning(
		canChecklistEdit('commissionOrganization'),
		formValues?.contractorId
	)

	const [doorSections, setDoorSections] = useState<DefaultOptionType[]>([])

	useEffect(() => {
		const data = Array.from(Array(dataSource?.sectionCount!).keys()).map(item => ({
			id: item + 1,
			name: (item + 1).toString(),
			disabled: checkLocalDoorSections(item)
		}))

		setDoorSections(data)
	}, [dataSource])

	const checkLocalDoorSections = (item: number) => {
		const check = !!dataSource?.doorSections?.length
			? dataSource?.selectedDoorSections
					.filter(s => !dataSource?.doorSections?.some(c => c === s))
					.some(i => i === item + 1)
			: dataSource?.selectedDoorSections?.some(i => i === item + 1)
		return check
	}

	const commonItems: DescriptionsItemType[] = [
		{
			key: 'templateType',
			children: (
				<AbsFormView
					isLoading={isLoading}
					label="Тип чек-листа"
					name="templateType"
					dataSourceValue={
						typeof dataSource?.templateType === 'number'
							? TEMPLATE_TYPES[dataSource?.templateType].toString()
							: ''
					}
				/>
			)
		},
		{
			key: 'title',
			children: (
				<AbsFormInput
					disabled={disabled}
					canEdit={canChecklistEdit('title')}
					label="Название"
					name="title"
					dataSourceValue={dataSource?.title}
					rules={[
						{
							required: true,
							message: 'Укажите название чек-листа'
						}
					]}
				/>
			)
		}
	]
	const items: DescriptionsItemType[] = [
		{
			key: 'cipher',
			children: (
				<AbsFormInput
					disabled={disabled}
					label="Шифр"
					name="checkListCipher"
					canEdit={canChecklistEdit('cipher')}
					dataSourceValue={dataSource?.checkListCipher}
				/>
			)
		},
		{
			key: 'workPackage',
			children: (
				<AbsFormSelect
					disabled={disabled}
					label="Пакет СМР"
					name="workPackageId"
					canEdit={canChecklistEdit('workPackage')}
					dataSourceValue={dataSource?.workPackage?.name}
					options={workPackages}
					isLoading={workPackagesFetching || isLoading}
					form={form}
				/>
			)
		},
		{
			key: 'createdAt',
			children: (
				<AbsFormView
					isLoading={isLoading}
					label="Дата создания"
					name="createdAt"
					dataSourceValue={
						dayjs(dataSource?.createdAt).isValid()
							? dayjs(dataSource?.createdAt).format(DATE_FORMAT.view)
							: ''
					}
				/>
			)
		},
		{
			key: 'owner',
			children: (
				<AbsFormView
					isLoading={isLoading}
					label="Владелец"
					name="owner"
					dataSourceValue={
						isNew ? `${currentUser?.firstName} ${currentUser?.lastName}` : dataSource?.owner?.name
					}
				/>
			)
		},
		{
			key: 'description',
			children: (
				<AbsFormInput
					disabled={disabled}
					isArea={true}
					label="Описание"
					name="description"
					dataSourceValue={dataSource?.description}
					canEdit={canChecklistEdit('description')}
					rules={[
						{
							required: true,
							message: 'Укажите описание'
						}
					]}
				/>
			)
		},
		{
			key: 'capacity',
			children: (
				<AbsFormInput
					disabled={disabled}
					label="Объём"
					name="capacity"
					dataSourceValue={dataSource?.capacity}
					canEdit={canChecklistEdit('capacity')}
				/>
			)
		},
		{
			key: 'assignedTo',
			children: (
				<AbsFormSelect
					disabled={disabled}
					label={
						dataSource?.templateType === TEMPLATE_TYPE.ukkc
							? 'Ответственный представитель УК'
							: 'Ответственный'
					}
					name="assignedTo"
					dataSourceValue={dataSource?.potentialAssignedUsers?.flatMap(x => x.fullName)}
					canEdit={canChecklistEdit('assignedTo')}
					rules={[
						{
							required: true,
							message: 'Укажите хотя бы одного ответственного'
						}
					]}
					options={responsibleUsers}
					error={responsibleUsersError}
					isLoading={responsibleUsersFetching || isLoading}
					fieldNames={{ label: 'fullName', value: 'id' }}
					form={form}
					absenceLabel="Пользователь был удалён"
					isMultiply={true}
				/>
			)
		},
		{
			key: 'contractor',
			children: (
				<AbsFormSelect
					disabled={disabled}
					label="Генподрядчик "
					name="contractorId"
					dataSourceValue={dataSource?.contractor?.name}
					canEdit={canChecklistEdit('contractor')}
					rules={[
						{
							required: true,
							message: 'Укажите генподрядчика'
						}
					]}
					options={contractors}
					error={contractorsError}
					isLoading={contractorsFetching || isLoading}
					form={form}
				/>
			)
		},
		{
			key: 'subContractor',
			children: (
				<AbsFormSelect
					disabled={disabled}
					label="Субподрядчик"
					name="subContractorId"
					dataSourceValue={dataSource?.subContractor?.name}
					canEdit={canChecklistEdit('subContractor')}
					rules={[
						{
							required: true,
							message: 'Укажите субподрядчика'
						}
					]}
					options={subContractors}
					error={subContractorsError}
					isLoading={subContractorsFetching || isLoading}
					form={form}
				/>
			)
		},
		{
			key: 'doorSections',
			children: (
				<AbsFormSelect
					disabled={disabled}
					label="Секции"
					name="doorSections"
					dataSourceValue={dataSource?.doorSections}
					canEdit={canChecklistEdit('doorSections')}
					rules={[
						{
							required: true,
							message: 'Укажите секции'
						}
					]}
					options={doorSections}
					isLoading={isLoading}
					form={form}
					isMultiply={true}
					isNumber={true}
				/>
			)
		},
		{
			key: 'location',
			children: (
				<AbsFormInput
					disabled={disabled}
					label="Локация"
					name="location"
					dataSourceValue={dataSource?.location}
					canEdit={canChecklistEdit('location')}
					rules={[
						{
							required: true,
							message: 'Укажите локацию'
						}
					]}
				/>
			)
		},
		{
			key: 'plannedDate',
			children: (
				<AbsFormDatePicker
					disabled={disabled}
					withTime={true}
					offsetDay={1}
					label="Плановая дата проверки"
					name="plannedDate"
					dataSourceValue={dataSource?.plannedDate}
					canEdit={canChecklistEdit('plannedDate')}
					rules={[
						{
							required: true,
							message: 'Укажите плановую дату проверки'
						}
					]}
				/>
			)
		},
		{
			key: 'factDate',
			children: (
				<AbsFormDatePicker
					disabled={disabled}
					label="Фактическая дата проверки"
					name="factDate"
					dataSourceValue={dataSource?.factDate}
					canEdit={canChecklistEdit('factDate')}
				/>
			)
		},
		{
			key: 'transferAct',
			children: (
				<TransferAct
					checklistId={dataSource?.id}
					checklistStatus={dataSource?.status}
					attachments={dataSource?.attachments}
					canEdit={
						(dataSource?.status === CHECKLIST_STATUS.Warranty ||
							dataSource?.status === CHECKLIST_STATUS.Completed) &&
						canChecklistEdit('transferAct')
					}
				/>
			)
		},
		{
			key: 'commissionOrganization',
			children: (
				<AbsFormSelect
					disabled={disabled}
					label="Наладочная организация"
					name="commissioningOrganizationId"
					dataSourceValue={dataSource?.commissioningOrganization?.name}
					canEdit={canChecklistEdit('commissionOrganization')}
					options={commissioning}
					error={commissioningError}
					isLoading={commissioningFetching || isLoading}
					form={form}
				/>
			)
		}
	]

	const templateFields: Record<TEMPLATE_TYPE, string[]> = {
		[TEMPLATE_TYPE.ndc]: [
			'owner',
			'cipher',
			'workPackage',
			'createdAt',
			'description',
			'capacity',
			'assignedTo',
			'contractor',
			'subContractor',
			'location',
			'plannedDate',
			'factDate'
		],

		[TEMPLATE_TYPE.ukkc]: [
			'createdAt',
			'owner',
			'description',
			'assignedTo',
			'contractor',
			'subContractor',
			'commissionOrganization',
			'doorSections',
			'plannedDate',
			'factDate',
			'transferAct'
		]
	}

	const mergedItems = () => {
		const templateItems =
			typeof dataSource?.templateType === 'number'
				? templateFields[dataSource?.templateType]
						.filter(z => (!dataSource.bySection ? z !== 'doorSections' : z))
						.map(x => items.find(i => i.key === x)!)
				: []
		return commonItems.concat(templateItems)
	}

	return (
		<Card
			title="Основные параметры"
			className="app-work-card"
			classNames={{ body: 'app-work-card__body' }}
			extra={<YandexTrackerButton text={'Сообщить об ошибке'} />}
		>
			<Descriptions
				items={mergedItems()}
				size="small"
				column={1}
				colon={false}
				labelStyle={{ display: 'none' }}
				className={'app-drawer-descriptions'}
				bordered
			/>
		</Card>
	)
}

import { Col, Row, Select } from 'antd'
import { useAppDispatch } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { setPageTitle } from 'store/rootSlice'
import { useMonitoringState } from '../model/monitoring-state'
import { AutoOrdinancesMonitoring } from './auto-ordinances-monitoring'
import { AutoOrdinancesNotApprovedMonitoring } from './auto-ordinances-not-approved-monitoring'
import { CheckListDueDateNotificationsMonitoring } from './check-list-due-date-notifications-monitoring'
import { ContractsWithFinesMonitoring } from './contracts-with-fines-monitoring'
import { CreatedFinesMonitoring } from './created-fines-monitoring'
import { FilesStatusesMonitoring } from './files-statuses-monitoring'
import { FiltersMonitoring } from './filters-monitoring'
import { NonEliminatedOrdinancesMonitoring } from './non-eliminated-ordinances-monitoring'
import { UndeliveredEliminationActsMonitoring } from './undelivered-elimination-acts-monitoring'
import { UndeliveredNonEliminationActsMonitoring } from './undelivered-non-elimination-acts-monitoring'
import { UndeliveredOrdinancesMonitoring } from './undelivered-ordinances-monitoring'

const monitoringItems = [
	{
		label: 'Суммы штрафов',
		key: 'contractsWithFines',
		children: <ContractsWithFinesMonitoring />
	},
	{
		label: 'Файлы актов неустранения',
		key: 'undeliveredNonEliminationActs',
		children: <UndeliveredNonEliminationActsMonitoring />
	},
	{
		label: 'Файлы предписаний',
		key: 'undeliveredOrdinances',
		children: <UndeliveredOrdinancesMonitoring />
	},
	{
		label: 'Файлы не выгруженные в ЕСЭД',
		key: 'filesStatuses',
		children: <FilesStatusesMonitoring />
	},
	{
		label: 'Файлы актов устранения',
		key: 'undeliveredEliminationActs',
		children: <UndeliveredEliminationActsMonitoring />
	},
	{
		label: 'Некорректный статус предписания',
		key: 'nonEliminatedOrdinances',
		children: <NonEliminatedOrdinancesMonitoring />
	},
	{
		label: 'Не сформировавшиеся предписания',
		key: 'autoOrdinances',
		children: <AutoOrdinancesMonitoring />
	},
	{
		label: 'Не согласованные предписания',
		key: 'autoOrdinancesNotApproved',
		children: <AutoOrdinancesNotApprovedMonitoring />
	},
	{
		label: 'Не отправленные уведомления по чек-листам',
		key: 'checkListDueDateNotifications',
		children: <CheckListDueDateNotificationsMonitoring />
	},
	{
		label: 'Поиск прогнозируемых/не сформированных штрафов',
		key: 'createdFines',
		children: <CreatedFinesMonitoring />
	}
]
const descriptions = [
	{
		description: `Ищет договора по которым были указаны штрафы, но по каким то причинам, сумма штрафа не указана.\nВозвращает массив с договорами, в которых должна быть указана сумма штрафа, но не указана.\n- Если такой выявлен, то создаем задачу на БАГ (бек) с описанием примера`,
		key: 'contractsWithFines'
	},
	{
		description: `Ищет документы предписаний и конкретные замечания по которым не были сформированы акты неустранения.\nВозвращает данные в таком формате. Мониторинг возвращает данные предписания и замечания, по которому не сформировался акт неустранения.\n1. Проверяются предписания, у которых статус "Не устранено"\n2. Для каждого такого предписания проверяется, что его дата устранения меньше текущей даты \n3. Далее проверяет связанные с предписанием замечания, и отбирает те которые имеют статус открыто.\n4. Проверяет что у замечания есть файл Акта неустранения (заполнено поле public."Issues"."NonEliminationActId" или есть запись таблице public."IssuesNonEliminationActId" с ID файла) `,
		key: 'undeliveredNonEliminationActs'
	},
	{
		description: `Поиск актуальных предписаний, (которые не удалены, и согласованы) у которых отсутствует файл предписания.\n1. Ищет актуальные предписания ( согласованны и нет пометок удаления )\n2. Проверяет есть ли файл с TypeId = 1 (предписание) в таблице Attachments\n3. Если нет, выводит запись с документом, не прошедшим мониторинг.`,
		key: 'undeliveredOrdinances'
	},
	{
		description: `Поиск предписаний, по которым данные не попали в ЕСЭД.\n1. Метод выполняет выборку файлов из таблицы Attachments, которые:\n- Имеют TypeId = 1, 3 ( файлы предписаний, акты неустранения)\n- Имеют StatusId = 2 ( сейчас присваивается во время формирования печатных форм для этих типов файлов)\n2. Если такие файлы найдены, метод возвращает записи с указанием, Предписания, к которому относится файл, Проекта, Типа файла, Статуса файла `,
		key: 'filesStatuses'
	},
	{
		description: `Поиск предписаний и замечаний по которым формировались акты устранения, но файлов нет.\n1. Метод выполняет выборку актуальных (не удаленных) замечаний, которые находятся в статусе "Закрыто" и по которым сформированы Акты об устранении.\n2. Проверяет что в таблице LogOrdinances, есть запись с логом о "Формировании акта устранения" и указан номер замечания\n3. Если такая запись есть, а у замечания в поле Issues.EliminationActId пустое, в таком случае выводит данные по замечанию и предписанию. `,
		key: 'undeliveredEliminationActs'
	},
	{
		description: `Предписания, по которым все документы подписаны, но статус "Не устранено".\n1. Метод делает выборку по предписаниям, которые:\n- Находятся в статусе "Не устранено",\n- Актуальные (нет пометок на удаление, и согласованы),\n- Замечания связанные с предписанием, имеют статусы "Закрыто" или "Отменено"\n2. Проверяет что у предписания есть связанный файл предписания в таблице Attachments.  TypeId = 1, StatusId = 4\n3. Если были сформированы файлы по предписаниям, то они подписаны, имеют StatusId = 4\n4. Если все условия выполнены, но у предписания статус "Не устранено", выводятся данные по проекту, предписанию.`,
		key: 'nonEliminatedOrdinances'
	},
	{
		description: `Замечания, по которым не сформировались предписания.\n1. Метод выполняет выборку из таблицы public.Issues замечаний, у которых\n*Срок устранения (DueDate) равна переданному параметру или предыдущему дню\n*По этим замечаниям отсутствует сформированное предписание\n2. Если такие замечания обнаружены, то возвращаются данные по таким замечаниям.\nИщет замечания по которым подошел срок и не был сформировано предписание\nПринимает необязательный query параметр dueDate в формате yyyy-mm-dd\nЕсли query параметр не был передан берется предыдущий день`,
		key: 'autoOrdinances'
	},
	{
		description: `Автоматически не согласованные предписания.\n1. Метод выбирает данные из таблицы Ordinances, отбирая предписания:\n*Были сформированы автоматически\n*Актуальные ( не удалены )\n*Имеют статус "Не согласовано"\n2. Если такие записи найдены, выводит данные по предписаниям`,
		key: 'autoOrdinancesNotApproved'
	},
	{
		description: `Список чек-листов по которым не были отправлены уведомления.\n1. Метод выбирает чек-листы, у которых:\n*Планируемая дата (PlannedDate) меньше теукщей даты.\n*Находятся в статусах "Новый", "В работе".\n*Актуальные (не удалены)\n2. Проверяет существуют ли записи об отправленных уведомлениях в таблице Notifications.\n3. Если записи нет, выводит данные о чек-листе.`,
		key: 'checkListDueDateNotifications'
	},
	{
		description: `Поиск прогнозируемых штрафов, или не сформированных по каким-то причинам штрафов.\n1. Метод выбирает предписания у которых:\n*Срок устранения соответствует дате переданному в запросе\n2. Проверяет что связанные с ним замечания:\n*Имеют статус "Открыто"\n*Сформирован акт неустранения\n3. Проверяет договор связанный с предписаниям, по нему должна быть указана сумма штрафа\n4. По предписанию ранее не должен быть сформирован штраф.\n5. Если все условия выполнены, возвращает данные по предписанию.`,
		key: 'createdFines'
	}
]

const MonitoringPage: FC = () => {
	const dispatch = useAppDispatch()
	const { onPagination } = useMonitoringState()
	const [selectedPage, setSelectedPage] = useState<string>('contractsWithFines')
	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Мониторинг' }))
	}, [])
	useEffect(() => {
		onPagination(1)
	}, [selectedPage])

	const selectedChange = (value: string) => {
		setSelectedPage(value)
	}
	return (
		<>
			<div className="dashboard__block">
				{/* <Row justify="space-between"> */}
				<Row gutter={8} align="middle">
					<Col style={{ marginLeft: 18, fontSize: 16 }}>Мониторинг:</Col>
					<Col style={{ fontSize: 16, marginRight: 10 }}>
						<Select
							showSearch
							placeholder="Мониторинг"
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							style={{ width: 500 }}
							value={selectedPage}
							onChange={selectedChange}
							options={monitoringItems.map((item, index) => ({
								key: index,
								value: item.key,
								label: item.label
							}))}
						/>
					</Col>
					{(selectedPage === 'createdFines' || selectedPage === 'autoOrdinances') && (
						<Col>{<FiltersMonitoring selectedPage={selectedPage} />}</Col>
					)}
				</Row>
				{/* </Row> */}
				<Row gutter={8}>
					<Col
						style={{
							alignContent: 'center',
							marginLeft: 18,
							fontSize: 14,
							marginTop: 35,
							color: 'red'
						}}
					>
						{descriptions?.find(d => d.key === selectedPage)?.description}
					</Col>
				</Row>
				{monitoringItems?.map(g => g.key === selectedPage && <div key={g.key}>{g.children}</div>)}
			</div>
		</>
	)
}
export default MonitoringPage

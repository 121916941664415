export enum ATTACHMENT_TYPE {
	ordinance = 1,
	eliminationAct = 2,
	nonEliminationAct = 3,
	fine = 4,
	checklistAct = 5
}

export enum ATTACHMENT_STATUS {
	beingFormed = 1,
	formed = 2,
	signatureVerification = 3,
	signatureIsValid = 4,
	signatureIsNotValid = 5,
	signatureIsNotVerified = 6
}

export enum SIGNATURE_TYPE {
	digital = 1,
	manual = 2
}

import { CloseOutlined } from '@ant-design/icons'
import { Button, Card, Descriptions, Form, Input, Switch } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { FormInstance } from 'antd/es/form'
import { FC } from 'react'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'

interface IItemProps {
	form: FormInstance
	canEdit: boolean
	sectionField: any
}

export const Item: FC<IItemProps> = ({ form, canEdit, sectionField }) => {
	const currentTemplateType = Form.useWatch('templateType', form)
	return (
		<Form.List name={[sectionField.name, 'items']}>
			{(fields, { add, remove }) => (
				<>
					{fields.map(field => {
						const descriptionsItems: DescriptionsItemType[] = [
							{
								key: 'name',
								label: `Пункт № ${field.name + 1}`,
								children: (
									<Form.Item
										className="app-form-item"
										name={[field.name, 'name']}
										rules={[{ required: true, message: 'Укажите название пункта' }]}
										style={{ margin: 0 }}
									>
										<Input
											placeholder="Название пункта"
											disabled={!canEdit}
											style={{ width: '100%', wordWrap: 'break-word' }}
										/>
									</Form.Item>
								),
								span: 24
							},
							{
								key: 'description',
								label: 'Описание',
								children: (
									<Form.Item
										className="app-form-item"
										name={[field.name, 'description']}
										style={{ margin: 0 }}
									>
										<Input.TextArea
											placeholder="Описание"
											disabled={!canEdit}
											style={{ width: '100%', wordWrap: 'break-word' }}
										/>
									</Form.Item>
								),
								span: 24
							}
						]

						const switchItems: DescriptionsItemType[] = [
							{
								key: 'checkboxIssue',
								label: 'Создать замечание',
								children: (
									<Form.Item
										name={[field.name, 'checkboxIssue']}
										valuePropName="checked"
										style={{ margin: 0 }}
									>
										<Switch size="small" disabled={!canEdit} />
									</Form.Item>
								),
								span: 12
							},
							{
								key: 'checkboxFile',
								label: 'Прикрепить файл',
								children: (
									<Form.Item
										name={[field.name, 'checkboxFile']}
										valuePropName="checked"
										style={{ margin: 0 }}
									>
										<Switch size="small" disabled={!canEdit} />
									</Form.Item>
								),
								span: 12
							}
						]

						if (currentTemplateType === TEMPLATE_TYPE.ukkc) {
							switchItems.push(
								{
									key: 'checkboxUk',
									label: 'Критичен для управляющей компании',
									children: (
										<Form.Item
											name={[field.name, 'checkboxUk']}
											valuePropName="checked"
											style={{ margin: 0 }}
										>
											<Switch size="small" disabled={!canEdit} />
										</Form.Item>
									),
									span: 12
								},
								{
									key: 'checkboxCritical',
									label: 'Критичен для передачи собственнику',
									children: (
										<Form.Item
											name={[field.name, 'checkboxCritical']}
											valuePropName="checked"
											style={{ margin: 0 }}
										>
											<Switch size="small" disabled={!canEdit} />
										</Form.Item>
									),
									span: 12
								}
							)
						}

						return (
							<Card key={field.key} style={{ position: 'relative', marginBottom: 16 }}>
								{canEdit && (
									<CloseOutlined
										onClick={() => remove(field.name)}
										style={{
											fontSize: '16px',
											padding: '.5rem',
											fontWeight: 'bold',
											position: 'absolute',
											top: '-18px',
											right: '18px',
											cursor: 'pointer',
											zIndex: 1,
											background: '#f2f2f2',
											border: '1px solid #ddd',
											borderRadius: '50px'
										}}
									/>
								)}

								<Descriptions
									size="small"
									column={1}
									colon={false}
									items={descriptionsItems}
									bordered
								/>

								<Descriptions
									size="small"
									column={2}
									colon={false}
									className="at-checklist-descriptions"
									items={switchItems}
									bordered
								/>
							</Card>
						)
					})}

					{canEdit && (
						<Button type="dashed" onClick={() => add()} style={{ width: '100%', marginTop: 16 }}>
							Добавить пункт
						</Button>
					)}
				</>
			)}
		</Form.List>
	)
}

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Modal, notification, Row, Upload, UploadProps } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import { ISignDto } from 'interfaces/IOrdinance'
import { FC, useState } from 'react'
import { useActSignMutate } from '../api/checklist-card-queries'
import { TChecklistAttachment } from '../model/checklist-card-schema'

type TProps = {
	act: TChecklistAttachment | null
	show: boolean
	onClose: () => void
}

export const ChecklistSignModal: FC<TProps> = ({ act, show, onClose }) => {
	const [signFile, setSignFile] = useState<RcFile | null>(null)
	const { mutate: signing, isLoading } = useActSignMutate()

	const props: UploadProps = {
		accept: '.pdf',
		showUploadList: false,
		maxCount: 1,
		onRemove: () => {
			setSignFile(null)
		},
		beforeUpload: file => {
			const isSign = file.type === 'application/pdf'
			if (isSign) {
				setSignFile(file)
			} else {
				notification.error({
					message: `Ошибка`,
					description: `${file.name} не является подписью`,
					placement: 'topLeft'
				})
			}
			return isSign || Upload.LIST_IGNORE
		}
	}

	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = error => reject(error)
		})

	const onSignConfirm = async () => {
		if (act && signFile) {
			const file: string = await getBase64(signFile!).then(data => data.split(',')[1])
			const signDto: ISignDto['base64File'] = {
				base64File: file,
				extension: act.extension.slice(-4),
				mimoType: signFile.type.length === 0 ? '' : signFile.type
			}
			signing(
				{
					checklistId: act?.objectId,
					fileId: act?.id,
					signDto
				},
				{ onSuccess: onClose }
			)
		}
	}

	return (
		<Modal
			destroyOnClose
			open={show}
			centered
			onCancel={onClose}
			okText="Подписать"
			okButtonProps={{ disabled: signFile === null || isLoading }}
			cancelButtonProps={{ disabled: isLoading }}
			title="Подписание акта передачи"
			onOk={onSignConfirm}
		>
			<Flex vertical gap={8}>
				<i>*Ручное подписание</i>
				{!signFile ? (
					<Dragger {...props}>
						<Flex vertical justify="center" align="center">
							<UploadOutlined />
							<p style={{ margin: '.5rem' }}>Добавить файл подписи</p>
						</Flex>
					</Dragger>
				) : (
					<Row gutter={8} style={{ alignItems: 'center' }}>
						<Col flex={1}>Подпись загружена</Col>
						<Col>
							<Button danger icon={<DeleteOutlined />} onClick={() => setSignFile(null)} />
						</Col>
					</Row>
				)}
				<i style={{ textAlign: 'right', width: '100%', marginTop: '.5rem' }}>
					*Допустимые форматы файлов: <b>{'.pdf'}</b>
				</i>
			</Flex>
		</Modal>
	)
}

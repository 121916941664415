import { PlusOutlined } from '@ant-design/icons'
import { Button, Flex, Table, Tooltip } from 'antd'
import modal from 'antd/es/modal'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useMemo } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { TChecklistIssues } from 'widgets/checklist-card/model/checklist-card-schema'
import { useIssueCardState } from 'widgets/issue-card'
import { useShallow } from 'zustand/react/shallow'
import { useChecklistIssueAssignMutation } from '../api/checklist-card-queries'
import { useChecklistCardState } from '../model/checklist-card-state'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'

type TProps = {
	itemId: string
	issues: TChecklistIssues[]
	disabled: boolean
}

export const ChecklistSectionItemAttachmentsIssues: FC<TProps> = ({ itemId, issues, disabled }) => {
	const checkPermissions = useCheckPermissions()
	const { user: currentUser } = useAppSelector(state => state.environment)
	const { initForm, templateType } = useChecklistCardState(
		useShallow(state => ({
			initForm: state.initForm,
			templateType: state.templateType
		}))
	)
	const { setChecklistItemId, setIssueId } = useIssueCardState()
	const { isLoading } = useChecklistIssueAssignMutation()
	const canAssignIssues =
		!disabled &&
		(currentUser?.isAdmin || initForm.assignedTo?.some(x => x === currentUser?.id)) &&
		((templateType === TEMPLATE_TYPE.ndc &&
			checkPermissions([PERMISSIONS.ChecklistsProcessingUksNdc, PERMISSIONS.IssueCreate], true)) ||
			(templateType === TEMPLATE_TYPE.ukkc &&
				checkPermissions([PERMISSIONS.ChecklistsProcessingUkKc, PERMISSIONS.IssueCreate], true)))

	const columns = useMemo<ColumnsType<TChecklistIssues>>(
		() => [
			{
				key: 'number',
				dataIndex: 'number',
				title: '№',
				width: 70
			},
			{
				key: 'title',
				dataIndex: 'title',
				title: 'Название',
				width: 200,
				ellipsis: true,
				render: (value, record) =>
					record?.title ? (
						<Button
							style={{ padding: 0 }}
							type="link"
							data-attr="issueLink"
							onClick={() => {
								if (checkPermissions([PERMISSIONS.IssueView])) {
									setIssueId(record.id)
								} else {
									modal.error({ content: `У вас нет прав на просмотр замечания` })
								}
							}}
						>
							{value}
						</Button>
					) : (
						'Нет данных'
					)
			},
			{
				key: 'status',
				dataIndex: ['issueStatus', 'name'],
				title: 'Статус',
				width: 100
			},
			{
				key: 'dueDate',
				dataIndex: 'dueDate',
				title: 'Срок',
				width: 100,
				render: value => (dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : 'Нет данных')
			}
		],
		[issues]
	)

	return (
		<Flex vertical gap={16}>
			<Table
				columns={columns}
				dataSource={issues}
				pagination={false}
				bordered
				className="section-issues-table"
			/>
			<Tooltip
				title={
					disabled
						? 'При создании чек-листа, нельзя добавлять замечания'
						: !canAssignIssues
						? 'Что-бы добавить замечание, нужно быть ответственным за чек-лист'
						: undefined
				}
			>
				<Button
					loading={isLoading}
					type="primary"
					onClick={() => setChecklistItemId(itemId)}
					style={{ width: '100%', marginTop: 16 }}
					icon={<PlusOutlined />}
					disabled={!canAssignIssues}
				>
					Добавить замечание
				</Button>
			</Tooltip>
		</Flex>
	)
}

import { Button, Col, Flex, Form, notification, Row } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, useCallback, useEffect, useState } from 'react'
import { downloadFile } from 'services/AdminService'
import { ATTACHMENT_STATUS, ATTACHMENT_TYPE } from 'shared/constants/attachment-types'
import { CHECKLIST_STATUS } from 'shared/constants/checklist-types'
import { useRecreateAct } from '../api/checklist-card-queries'
import { TChecklistAttachment } from '../model/checklist-card-schema'
import { CheckListSignBlock } from './checklist-sign-block'

interface IProps {
	checklistStatus?: CHECKLIST_STATUS | null
	checklistId?: string
	attachments?: TChecklistAttachment[]
	canEdit: boolean
}

export const TransferAct: FC<IProps> = ({ checklistId, checklistStatus, attachments, canEdit }) => {
	const [act, setAct] = useState<TChecklistAttachment | null>(null)
	const { mutate: recreateAct, isLoading: isRecreateActLoading } = useRecreateAct()

	useEffect(() => {
		const checkListFile = attachments?.find(file => file.typeId === ATTACHMENT_TYPE.checklistAct)
		if (checkListFile) setAct(checkListFile)
	}, [attachments])

	const getAct = useCallback(
		(act: TChecklistAttachment) => {
			downloadFile(act.id)
				.then(response => {
					const href = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = href
					link.setAttribute('download', act.name + act.extension)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					URL.revokeObjectURL(href)
				})
				.catch((error: AxiosError<IErrorDetail>) =>
					notification.error({
						message: 'Получение файла',
						description: `Во время загрузки файла произошла ошибка. Код ошибки: ${error.response?.status}`,
						placement: 'topLeft'
					})
				)
		},
		[act]
	)

	console.log('TransferAct', canEdit)

	return (
		<Form.Item label="Акт передачи">
			<Row
				style={{ width: '100%', paddingLeft: 11 }}
				align="middle"
				gutter={16}
				justify="space-between"
				wrap={false}
			>
				<Col flex={1}>
					{act !== null && (
						<Button
							disabled={isRecreateActLoading}
							onClick={() => getAct(act)}
							type="link"
							data-attr="link"
						>
							{act.name}
						</Button>
					)}
				</Col>
				{checklistId && typeof checklistStatus === 'number' && (
					<Col>
						<Flex gap={8} wrap="nowrap">
							{act !== null && act?.statusId !== ATTACHMENT_STATUS.signatureIsValid && (
								<Button
									loading={isRecreateActLoading}
									disabled={!canEdit}
									onClick={() => recreateAct(checklistId)}
								>
									Переформировать
								</Button>
							)}
							{canEdit && (
								<CheckListSignBlock
									act={act}
									checklistId={checklistId}
									isLoading={isRecreateActLoading}
									checklistStatus={checklistStatus}
								/>
							)}
						</Flex>
					</Col>
				)}
			</Row>
		</Form.Item>
	)
}

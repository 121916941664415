import { LinkOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useState } from 'react'
import { useChecklistCardState } from '../model/checklist-card-state'
import { ChecklistCardDocumentationLinkModal } from './checklist-card-documentation-link-modal'

type TProps = {
	itemId: string
	disabled: boolean
	value?: string | null
}

export const ChecklistCardDocumentationLink: FC<TProps> = ({ itemId, value, disabled }) => {
	const initForm = useChecklistCardState(state => state.initForm)
	const currentUser = useAppSelector(state => state.environment.user)
	const [showModal, setShowModal] = useState(false)
	const canEdit = currentUser?.isAdmin || currentUser?.id === initForm.ownerId

	return canEdit ? (
		<>
			<ChecklistCardDocumentationLinkModal
				itemId={itemId}
				onClose={() => setShowModal(false)}
				openModal={showModal}
				value={value}
			/>
			<Button
				type="text"
				icon={<LinkOutlined />}
				disabled={disabled}
				onClick={() => setShowModal(true)}
			/>
		</>
	) : null
}

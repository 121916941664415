import { Flex, FormInstance } from 'antd'
import { FC } from 'react'
import { TEMPLATE_TYPE } from 'shared/constants/checklist-types'
import { ChecklistSections } from 'widgets/checklist-card/ui/checklist-sections'
import { TChecklist, TChecklistForm } from '../model/checklist-card-schema'
import { ChecklistCardDetailsMain } from './checklist-card-details-main'
import { ChecklistStages } from './checklist-stages'

type TProps = {
	isLoading: boolean
	disabled: boolean
	dataSource?: TChecklist | null
	form: FormInstance<TChecklistForm>
	formValues: TChecklistForm
}

export const ChecklistCardDetails: FC<TProps> = ({
	isLoading,
	disabled,
	dataSource,
	form,
	formValues
}) => {
	return (
		<Flex vertical gap={24}>
			<ChecklistCardDetailsMain
				isLoading={isLoading}
				dataSource={dataSource}
				form={form}
				formValues={formValues}
				disabled={disabled}
			/>
			{dataSource?.templateType === TEMPLATE_TYPE.ndc && (
				<ChecklistStages stages={dataSource.stages} disabled={disabled || isLoading} />
			)}
			{dataSource?.templateType === TEMPLATE_TYPE.ukkc && (
				<ChecklistSections sections={dataSource.sections} disabled={disabled || isLoading} />
			)}
		</Flex>
	)
}

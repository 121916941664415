import {
	DeleteOutlined,
	EyeOutlined,
	FileGifOutlined,
	FileImageOutlined,
	FileJpgOutlined,
	FilePdfOutlined,
	PlusOutlined
} from '@ant-design/icons'
import { Button, Flex, Image, Popconfirm } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { formatBytes } from 'services/helperService'
import { useDownload } from 'shared/api/useDownload'
import { DATE_FORMAT, PERMISSIONS } from 'shared/constants'
import { imagePlaceholder } from 'shared/constants/placeholder'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import {
	TChecklistAttachment,
	TChecklistSectionItem
} from 'widgets/checklist-card/model/checklist-card-schema'
import { checklistCardApi } from '../api/checklist-card-api'
import { useChecklistRemoveFileMutation } from '../api/checklist-card-queries'
import { useChecklistCanAddFiles } from '../model/checklist-card-helper'
import { useChecklistCardState } from '../model/checklist-card-state'

type TProps = {
	item: TChecklistSectionItem
	files: TChecklistAttachment[]
	disabled: boolean
}

type TFileItem = TChecklistAttachment & { src?: string }

const ImageIcons: Record<string, ReactNode> = {
	pdf: <FilePdfOutlined style={{ color: 'darkOrange', marginRight: 5 }} />,
	jpg: <FileJpgOutlined style={{ color: 'red', marginRight: 5 }} />,
	jpeg: <FileJpgOutlined style={{ color: 'red', marginRight: 5 }} />,
	png: <FileImageOutlined style={{ color: '#4173e6', marginRight: 5 }} />,
	gif: <FileGifOutlined style={{ color: 'grey', marginRight: 5 }} />
}
export const ChecklistSectionItemAttachmentsFiles: FC<TProps> = ({ item, files, disabled }) => {
	const download = useDownload()
	const [isBlobbing, setIsBlobbing] = useState(false)
	const [fileList, setFilesList] = useState<TFileItem[]>(files)
	const canDelete = useCheckPermissions()([PERMISSIONS.Admin])
	const { setFileItemModal } = useChecklistCardState()
	const { mutate: deleteFile } = useChecklistRemoveFileMutation()
	const canAddFiles = useChecklistCanAddFiles()()
	const blobToBase64 = async (id: string) => {
		const blob: Blob = await checklistCardApi.getFile(id).then(data => data)
		if (blob) {
			return new Promise<string>((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onload = () => resolve(reader.result!.toString())
				reader.onerror = error => reject(error)
			})
		}
	}
	useEffect(() => {
		const getBlobs = async () => {
			setIsBlobbing(true)
			const blobList: TFileItem[] = await Promise.all(
				files.map(async (file): Promise<TFileItem> => {
					let blob: string | undefined = ''
					await blobToBase64(file.id).then(data => {
						blob = data
					})
					return { ...file, src: blob }
				})
			)
			setFilesList(blobList)
		}
		getBlobs().finally(() => setIsBlobbing(false))
	}, [files])

	const columns = useMemo<ColumnsType<TFileItem>>(
		() => [
			{
				align: 'center',
				width: 48,
				render: (_, record) => ImageIcons[record.extension.replace('.', '').toLowerCase()]
			},
			{
				align: 'center',
				width: 64,
				render: (_, record) =>
					record.contentType !== 'application/pdf' && (
						<Image
							loading="lazy"
							width={50}
							src={record.src}
							alt={record.name}
							fallback={imagePlaceholder}
							preview={{
								mask: <EyeOutlined />
							}}
						/>
					)
			},
			{
				dataIndex: 'name',
				render: (value, record) => (
					<Button
						block
						onClick={() => download(record.id, value, record.extension)}
						type="link"
						data-attr="link"
						style={{ padding: 0, margin: 0, maxWidth: 480 }}
					>
						{value}
					</Button>
				)
			},

			{
				align: 'center',
				dataIndex: 'uploadDate',
				width: 120,
				render: value => dayjs(value).format(DATE_FORMAT.view)
			},
			{ align: 'center', dataIndex: 'fileSize', width: 120, render: value => formatBytes(value) },
			{
				align: 'center',
				dataIndex: 'fileSize',
				width: 64,
				hidden: !canDelete,
				render: (_, record) => (
					<Popconfirm
						placement="topRight"
						title={'Вы точно хотите удалить файл?'}
						onConfirm={() => deleteFile(record.id)}
						okText="Удалить"
						okButtonProps={{ danger: true }}
					>
						<Button icon={<DeleteOutlined />} danger />
					</Popconfirm>
				)
			}
		],
		[files]
	)
	return (
		<Flex vertical gap={16}>
			{!!files.length && (
				<Table
					loading={isBlobbing}
					className="checklist-files-table"
					size="small"
					pagination={false}
					showHeader={false}
					dataSource={fileList}
					columns={columns}
					bordered
				/>
			)}
			<Button
				// loading={isLoading}
				type="primary"
				onClick={() => setFileItemModal(item.id)}
				style={{ width: '100%', marginTop: 16 }}
				icon={<PlusOutlined />}
				disabled={disabled || !canAddFiles}
			>
				Добавить файл
			</Button>
		</Flex>
	)
}

import { CellContext, Row } from '@tanstack/react-table'
import { InputNumber, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useRef, useState } from 'react'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateState } from '..'
import { RoundedNumber } from '../model/estimate-helper'
import {
	IEstimatePosition,
	IEstimatePositionMaterial,
	IEstimateSection
} from '../model/estimate-schema'

interface IProps {
	record:
		| CellContext<IEstimatePosition | IEstimatePositionMaterial, any>
		| CellContext<IEstimatePosition, any>
		| CellContext<IEstimatePositionMaterial, any>
	cellType: 'amount' | 'priceMaterial' | 'priceService'
	parents: Row<IEstimateSection | IEstimatePosition | IEstimatePositionMaterial>[]
}

export const EstimateVirtualizeCell: FC<IProps> = ({ record, cellType, parents }) => {
	const inputRef = useRef<HTMLInputElement>(null)
	const { user: currentUser } = useAppSelector(state => state.environment)

	const { container, onSavePosition } = useEstimateState(
		useShallow(state => ({
			container: state.container,
			onSavePosition: state.onSavePosition
		}))
	)
	const initialValue = RoundedNumber(record.cell.getValue()) as string
	const [cellValue, setCellValue] = useState<number | null>(
		+initialValue.replace(/,/, '.').replace(/\s+/g, '')
	)
	const [hasError, setHasError] = useState<string | undefined>()
	const wbsPermissions = useWbsPermits()

	const isAuthor =
		(currentUser?.isAdmin ||
			container?.author.id === currentUser?.id ||
			container?.redactors.some(r => r.id === currentUser?.id)) ??
		false

	const isRedactor =
		(currentUser?.isAdmin || container?.redactors.some(r => r.id === currentUser?.id)) ?? false

	const isValidateClosed =
		typeof container?.isLocalEstimate === 'undefined' ||
		(container.isLocalEstimate === false && record.row.original.isClosed === false)

	const validateCell = (value: any) => {
		if (
			isValidateClosed &&
			cellType === 'amount' &&
			(value === null || value === 0 || value === undefined)
		) {
			setHasError('Кол-во не может быть пустым или равным 0')
			// } else if (
			// 	cellType === 'amount' &&
			// 	value < record.row.original.confirmedVolumeAmount &&
			// 	container?.isLocalEstimate === false
			// ) {
			// 	setHasError('Значение должно быть >= Выполнено в ПО')
		} else if (value === null && isValidateClosed) setHasError('Кол-во не может быть пустым')
		else if (Number.isNaN(value) && isValidateClosed) {
			setHasError('Может содержать только цифры')
		} else {
			setHasError(undefined)
		}
	}
	useEffect(() => {
		validateCell(cellValue)
	}, [cellValue])

	const onSave = (
		cellType: 'amount' | 'priceMaterial' | 'priceService',
		record:
			| CellContext<IEstimatePosition | IEstimatePositionMaterial, any>
			| CellContext<IEstimatePosition, any>
			| CellContext<IEstimatePositionMaterial, any>,
		parents: Row<IEstimateSection | IEstimatePosition | IEstimatePositionMaterial>[]
	) => {
		if (!hasError && cellValue !== null) {
			onSavePosition(cellValue, cellType, record, parents)
		} else {
			setCellValue(+initialValue.replace(/,/, '.').replace(/\s+/g, ''))
			setTimeout(() => {
				setHasError(undefined)
			}, 2000)
		}
	}

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate =
		container?.isLocalEstimate === false && wbsPermissions.canLocalEdit

	const checkEditable = (
		cellType: 'amount' | 'priceMaterial' | 'priceService',
		record:
			| CellContext<IEstimatePosition | IEstimatePositionMaterial, any>
			| CellContext<IEstimatePosition, any>
			| CellContext<IEstimatePositionMaterial, any>
	) => {
		switch (cellType) {
			case 'amount':
				return (
					(!isLocalEstimate && isAuthor && wbsPermissions.canEdit) ||
					(canEditCorrectLocalEstimate &&
						isAuthor &&
						record.row.original.isClosed === false &&
						record.row.original.isEditable === true)
					//  &&
					// ((record.row.original.isExpandable === true && 'materialName' in record.row.original) ||
					// 	(record.row.original.isExpandable === true && 'workName' in record.row.original))
				)
			case 'priceMaterial':
				if ('workName' in record.row.original) {
					if (isLocalEstimate && container?.isLocalEstimate === true) return false
					if (
						isLocalEstimate &&
						container?.isLocalEstimate === false &&
						record.row.original.confirmedVolumeAmount !== 0
					)
						return false
					return (
						(!isLocalEstimate &&
							record.row.original.isFixedPriceMaterial === false &&
							wbsPermissions.canSetSDU) ||
						(record.row.original.isFixedPriceMaterial === false && currentUser?.isAdmin)
					)
				}
				if ('materialName' in record.row.original) {
					if (isLocalEstimate && container?.isLocalEstimate === true) return false
					if (
						isLocalEstimate &&
						container?.isLocalEstimate === false &&
						record.row.original.confirmedVolumeAmount !== 0
					)
						return false
					return !isLocalEstimate && record.row.original.isNominated
						? wbsPermissions.canSetDZ
						: record.row.original.isNominated && isLocalEstimate
						? wbsPermissions.canLocalDzMTR || (wbsPermissions.canLocalExternal && isRedactor)
						: (wbsPermissions.canSetSDU && !isLocalEstimate) ||
						  (record.row.original.isNominated === false &&
								wbsPermissions.canLocalSdu &&
								isLocalEstimate)
				}
				return false
			case 'priceService':
				if (isLocalEstimate && container?.isLocalEstimate === true) return false
				if (
					isLocalEstimate &&
					container?.isLocalEstimate === false &&
					record.row.original.confirmedVolumeAmount !== 0
				)
					return false
				return (
					('workName' in record.row.original && !isLocalEstimate && wbsPermissions.canSetSDU) ||
					('workName' in record.row.original &&
						container?.isLocalEstimate === false &&
						wbsPermissions.canLocalSdu) ||
					currentUser?.isAdmin
				)
		}
	}

	return container?.ready ||
		record.row.original.isClosed === true ||
		record.row.original.isEditable === false ||
		container?.isLocalEstimate ||
		!checkEditable(cellType, record) ||
		// (!(
		// 	checkEditable(cellType, record)
		// &&
		// cellType === 'amount' &&
		// record.row.original.isClosed === false &&
		// record.row.original.isEditable === true &&
		// record.row.original.isExpandable === true
		// ) &&
		// 	'materialId' in record.row.original) ||
		((cellType === 'priceMaterial' || cellType === 'priceService') &&
			record.row.original.confirmedVolumeAmount > 0) ? (
		<>{initialValue}</>
	) : (
		<Tooltip color="volcano" placement="left" title={hasError} open={!!hasError}>
			<InputNumber
				ref={inputRef}
				value={cellValue}
				keyboard={false}
				onKeyDown={event => {
					if (
						!/[0-9]|Backspace|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|\.|\,/.test(event.key)
					)
						event.preventDefault()
				}}
				controls={false}
				// formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(/\./, ',')}
				// parser={value => value!?.replace(/,/, '.')}
				changeOnWheel={false}
				decimalSeparator=","
				className="estimate-position-input"
				onChange={setCellValue}
				onFocus={() => {
					if (Number(initialValue) === 0) inputRef.current?.select()
				}}
				onBlur={e => {
					if (initialValue! !== e.currentTarget.value) onSave(cellType, record, parents)
				}}
				onPressEnter={() => inputRef.current?.blur()}
			/>
		</Tooltip>
	)
}

import { Radio, RadioChangeEvent } from 'antd'
import { FC } from 'react'
import { useSetAnswerMutate } from '../api/checklist-card-queries'
import { useChecklistCanProcessing } from '../model/checklist-card-helper'

type TProps = {
	itemId: string
	answer: number | null
	isLoading: boolean
	disabled: boolean
}

export const ChecklistSectionItemActions: FC<TProps> = ({
	itemId,
	answer,
	isLoading,
	disabled
}) => {
	const canProcessing = useChecklistCanProcessing()()
	const { mutate } = useSetAnswerMutate()

	const onChangeAnswer = (e: RadioChangeEvent) => {
		onSetAnswer(+e.target.value !== answer ? +e.target.value : null)
	}

	const options = [
		{ label: 'Принято', testValue: 'YES', value: 1 },
		{ label: 'Не принято', testValue: 'NO', value: 2 },
		{ label: 'Не требуется', testValue: 'NA', value: 0 }
	]

	const onSetAnswer = (answer: number | null) => {
		mutate({ itemId, answer })
	}

	return (
		<Radio.Group
			disabled={disabled || isLoading || !canProcessing}
			buttonStyle="solid"
			value={answer}
			optionType="button"
		>
			{options.map(item => (
				<Radio
					disabled={disabled || isLoading || !canProcessing}
					data-answer={item.testValue}
					value={item.value}
					onClick={(e: any) => onChangeAnswer(e)}
				>
					{item.label}
				</Radio>
			))}
		</Radio.Group>
	)
}

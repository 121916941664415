import { useQuery } from "@tanstack/react-query"
import { useShallow } from "zustand/react/shallow"
import { EstimateCreationFromVorApi } from "../api/estimate-related-documents-api"
import { useEstimateRelatedDocumentsState } from "./estimate-related-documents-state"

export const useEstimateRelatedDocuments = (id: string, type: 'wbs' | 'local') => {
	const { showModal } = useEstimateRelatedDocumentsState(
		useShallow(state => ({
			showModal: state.showModal, 
			selectedId: state.selectedId
		}))
	)
	return useQuery({
		queryKey: ['estimate-relatedDocuments', showModal, id!],
		queryFn: async () => {
			if(id){
				return await EstimateCreationFromVorApi.get(id!, type)
				.then(res => res)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
import { Button, DatePicker, Form, Row, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { FC, useEffect, useState } from 'react'
import { useMonitoringState } from '../model/monitoring-state'

interface IFilterItem {
	title: string | React.ReactNode
	formItemName: string
	list: null | any[]
	date: boolean
	disabled: boolean
}

interface IProps {
	selectedPage?: 'createdFines' | 'autoOrdinances'
}
const { Option } = Select
export const FiltersMonitoring: FC<IProps> = ({ selectedPage }) => {
	const [monitoringFiltersForm] = Form.useForm()
	const comparisonOperatorField = Form.useWatch('comparisonOperator', monitoringFiltersForm)
	const { onPagination, setMonitoringFilters } = useMonitoringState()
	const comparisonOperator = [
		{
			id: 0,
			name: '='
		},
		{
			id: 1,
			name: '<'
		},
		{
			id: 2,
			name: '>'
		},
		{
			id: 3,
			name: '≠'
		},
		{
			id: 4,
			name: '<='
		},
		{
			id: 5,
			name: '>='
		}
	]
	const filterListCreatedFines: IFilterItem[] = [
		{
			title: null,
			formItemName: 'comparisonOperator',
			list: comparisonOperator,
			date: false,
			disabled: false
		},
		{ title: 'Дата', formItemName: 'date', list: null, date: true, disabled: false }
	]

	const filterListAutoOrdinances: IFilterItem[] = [
		{
			title: 'Дата устранения замечания',
			formItemName: 'dueDate',
			list: null,
			date: true,
			disabled: false
		}
	]

	const [filterItems, setFilterItems] = useState<IFilterItem[]>(
		selectedPage === 'createdFines' ? filterListCreatedFines : filterListAutoOrdinances
	)

	useEffect(() => {
		setFilterItems(
			selectedPage === 'createdFines' ? filterListCreatedFines : filterListAutoOrdinances
		)
		monitoringFiltersForm.resetFields()
		monitoringFiltersForm.submit()
	}, [selectedPage])

	const onConfirmFilters = () => {
		setMonitoringFilters({
			...monitoringFiltersForm.getFieldsValue(),
			date:
				selectedPage === 'createdFines'
					? monitoringFiltersForm.getFieldValue('date') === undefined
						? null
						: monitoringFiltersForm.getFieldValue('date')
					: monitoringFiltersForm.getFieldValue('dueDate') === undefined
					? null
					: monitoringFiltersForm.getFieldValue('dueDate')
		})
		onPagination(1)
	}

	const onResetFilters = () => {
		monitoringFiltersForm.resetFields()
		monitoringFiltersForm.submit()
	}
	return (
		<Form form={monitoringFiltersForm} name="monitoringFiltersForm" onFinish={onConfirmFilters}>
			<Row align="middle" className="monitoringFilters">
				{filterItems?.map((item, index) => (
					<>
						<span style={{ marginLeft: 10, marginRight: 5 }}>
							{item.title}
							{`${item.title === null ? '' : ': '}`}
						</span>
						<Form.Item key={index} name={item.formItemName}>
							{!item.date ? (
								<Select
									showSearch={true}
									allowClear
									optionFilterProp="children"
									disabled={item.disabled || item.list?.length === 0}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toString()
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toString().toLowerCase())
									}
									style={{ width: 100 }}
									placeholder="Не выбран"
								>
									{item.list! &&
										item.list!?.map((option, index) => (
											<Option
												key={option.id ? option.id : index}
												value={option.id ? option.id : index}
											>
												{option.name ? option.name : option}
											</Option>
										))}
								</Select>
							) : (
								<DatePicker
									locale={locale}
									style={{ width: '100%' }}
									format={'DD.MM.YYYY'}
									disabled={
										comparisonOperatorField === undefined && selectedPage === 'createdFines'
									}
								/>
							)}
						</Form.Item>
					</>
				))}
				<Button
					type="primary"
					onClick={() => monitoringFiltersForm.submit()}
					style={{ marginLeft: 10 }}
				>
					Применить
				</Button>
				<Button type="primary" onClick={onResetFilters} style={{ marginLeft: 10 }}>
					Сбросить
				</Button>
			</Row>
		</Form>
	)
}

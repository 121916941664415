import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { IOrdinance, IOrdinanceDTO, IOrdinanceFilters, IOrdinanceUpd } from 'interfaces/IOrdinance'
interface IDTOAction {
	prop: string
	value: string | Date | number | any[] | null
}

interface IDTOFilters {
	filters: IOrdinanceFilters
}

const ordinanceInitDTO: IOrdinanceDTO = {
	id: null,
	dateTo: '',
	actPoints: '',
	ordinanceStatusId: '',
	project1CId: '',
	projectId: '',
	contractId: null,
	orgId: null,
	constructionObjectId: null,
	contactPersonId: null,
	contractorId: null,
	subContractorId: null,
	violationTypeId: null,
	buildingPermitId: null,
	issues: [],
	createdBy: null
}

const ordinanceUpd: IOrdinanceUpd = {}

const ordinanceFilters: IOrdinanceFilters = {}

const ordinanceListState = {
	currentPage: 1,
	totalItems: 0
}

const ordinanceDtoKeys = Object.keys(ordinanceInitDTO).map(key => key)

const ordinanceSlice = createSlice({
	name: 'ordinance',
	initialState: {
		init: ordinanceInitDTO,
		dto: ordinanceInitDTO,
		upd: ordinanceUpd,
		filters: ordinanceFilters,
		listState: ordinanceListState
	},
	reducers: {
		setFilterOrdinance(state, action: PayloadAction<IDTOFilters>) {
			state.filters = action.payload.filters
		},
		resetOrdinancesState(state) {
			state.filters = ordinanceFilters
			state.listState = ordinanceListState
		},
		setTotalItemsOrdinance(state, action: PayloadAction<number>) {
			state.listState.totalItems = action.payload
		},
		setTotalPagesOrdinance(state, action: PayloadAction<number>) {
			state.listState.currentPage = action.payload
		},
		setDtoValue(state, action: PayloadAction<IDTOAction>) {
			if (ordinanceDtoKeys.some(key => key === action.payload.prop)) {
				state.dto = {
					...state.dto,
					[action.payload.prop]: action.payload.value
				}
			}
		},
		setDtoUpd(state, action: PayloadAction<IOrdinance>) {
			state.upd = {
				contractId: action.payload.contract!?.id!,
				buildingPermitId: action.payload.buildingPermit!?.id!,
				contactPersonId: action.payload.contactPerson!?.id!,
				dateTo: dayjs(action.payload.dateTo!).format(),
				actPoints: action.payload.actPoints!
			}
		},
		setDtoUpdValue(state, action: PayloadAction<IDTOAction>) {
			state.upd = {
				...state.upd,
				[action.payload.prop]: action.payload.value
			}
		},
		resetDto(state) {
			state.dto = ordinanceInitDTO
		}
	}
})

export const {
	resetOrdinancesState,
	setFilterOrdinance,
	setTotalItemsOrdinance,
	setTotalPagesOrdinance,
	setDtoValue,
	setDtoUpd,
	setDtoUpdValue,
	resetDto
} = ordinanceSlice.actions

export default ordinanceSlice.reducer

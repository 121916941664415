import { Col, Row } from 'antd'
import { memo } from 'react'
import { IEstimatePositionMaterial } from '..'

interface IProps {
	row: IEstimatePositionMaterial
}

export const EstimatePositionMaterialTitle = memo(function (params: IProps) {
	const { row } = params
	const title = `${row.materialName}
						${row.materialComment ? `, ${row.materialComment}` : ''}`
	return (
		<Row
			wrap={false}
			align="middle"
			justify="space-between"
			style={{
				width: '100%',
				height: '100%'
			}}
		>
			<Col>
				<div>
					{title}
					{row.materialIsDeleted && (
						<span style={{ marginLeft: '8px' }}>АРХИВ</span>
					)}
				</div>
			</Col>
		</Row>
	)
})
